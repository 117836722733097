import { SnapshotIn, types } from 'mobx-state-tree';

export const ChargingSchedulePeriodItem = types.model({
  start_period: types.number, // Required. Start of the period, in seconds from the start of schedule.
  limit: types.number, // Required. Charging rate limit during the schedule period, in the applicable chargingRateUnit, for example in Amperes or Watts. Accepts at most one digit fraction (e.g. 8.1).
  number_phases: types.maybeNull(types.number), // Optional. The number of phases that can be used for charging. If a number of phases is needed, numberPhases=3 will be assumed unless another number is given.
});

export const ChargingScheduleItem = types.model({
  start_period: types.number, // Required. Start of the period, in seconds from the start of schedule.
  stop_period: types.number, //types.maybe(types.number), // Required. End of the period, in seconds from the end of schedule.
  limit: types.number, // Required. Charging rate limit during the schedule period, in the applicable chargingRateUnit, for example in Amperes or Watts. Accepts at most one digit fraction (e.g. 8.1).
  active: true,
  type: types.maybeNull(types.string),
});

export interface ChargingSchedulePeriodItem
  extends SnapshotIn<typeof ChargingSchedulePeriodItem> {}

export interface ChargingScheduleItem
  extends SnapshotIn<typeof ChargingScheduleItem> {}

export enum ChargingRateUnitEnum {
  W = 'W',
  A = 'A',
}

export const ChargingSchedule = types.model({
  duration: types.maybeNull(types.number), // Optional. Duration of the charging schedule in seconds. If the duration is left empty, the last period will continue indefinitely or until end of the transaction in case startSchedule is absent.
  start_schedule: types.maybeNull(types.string), // Optional. Starting point of an absolute schedule. If absent the schedule will be relative to start of charging.
  charging_rate_unit: types.enumeration([
    // Required. The unit of measure Limit is expressed in.
    ChargingRateUnitEnum.A, // Amperes (current).
    // The amount of Ampere per phase, not the sum of all phases.
    // It is usually more convenient to use this for AC charging.

    ChargingRateUnitEnum.W, // Watts (power).
    // This is the TOTAL allowed charging power.
    // If used for AC Charging, the phase current should be calculated via: Current per phase = Power / (Line Voltage * Number of Phases). The "Line Voltage" used in the calculation is not the measured voltage, but the set voltage for the area (hence, 230 of 110 volt). The "Number of Phases" is the numberPhases from the ChargingSchedulePeriod.
    // It is usually more convenient to use this for DC charging.
    // Note that if numberPhases in a ChargingSchedulePeriod is absent, 3 SHALL be assumed.
  ]),
  charging_schedule_period: types.array(ChargingSchedulePeriodItem), // Required. List of ChargingSchedulePeriod elements defining maximum power or current usage over time. The startSchedule of the first ChargingSchedulePeriod SHALL always be 0.
  charging_schedule_item: types.array(ChargingScheduleItem), // Required. List of ChargingSchedulePeriod elements defining maximum power or current usage over time. The startSchedule of the first ChargingSchedulePeriod SHALL always be 0.
  min_charging_rate: types.maybeNull(types.number), // Optional. Minimum charging rate supported by the electric vehicle. The unit of measure is defined by the chargingRateUnit. This parameter is intended to be used by a local smart charging algorithm to optimize the power allocation for in the case a charging process is inefficient at lower charging rates. Accepts at most one digit fraction (e.g. 8.1)
});

export interface ChargingSchedule extends SnapshotIn<typeof ChargingSchedule> {}

export enum ChargingProfilePurposeEnum {
  ChargePointMaxProfile = 'ChargePointMaxProfile',
  TxDefaultProfile = 'TxDefaultProfile',
  TxProfile = 'TxProfile',
}
export enum ChargingProfileKindEnum {
  Absolute = 'Absolute',
  Relative = 'Relative',
  Recurring = 'Recurring',
}

export enum RecurrencyKindEnum {
  Daily = 'Daily',
  Weekly = 'Weekly',
}

export const OCPPChargingProfile = types.model({
  charging_profile_id: types.identifierNumber, // Required. Unique identifier for this profile.
  transaction_id: types.maybeNull(types.string), // Optional. Only valid if ChargingProfilePurpose is set to TxProfile, the transactionId MAY be used to match the profile to a specific transaction.
  stack_level: types.maybeNull(types.number), // Required. Value determining level in hierarchy stack of profiles. Higher values have precedence over lower values. Lowest level is 0.
  charging_profile_purpose: types.enumeration([
    // Required. Defines the purpose of the schedule transferred by this message.
    ChargingProfilePurposeEnum.ChargePointMaxProfile, // Configuration for the maximum power or current available for an entire Charge Point.
    ChargingProfilePurposeEnum.TxDefaultProfile, // Default profile *that can be configured in the Charge Point. When a new transaction is started, this profile SHALL be used, unless it was a transaction that was started by a RemoteStartTransaction.req with a ChargeProfile that is accepted by the Charge Point.
    ChargingProfilePurposeEnum.TxProfile, // Profile with constraints to be imposed by the Charge Point on the current transaction, or on a new transaction when this is started via a RemoteStartTransaction.req with a ChargeProfile. A profile with this purpose SHALL cease to be valid when the transaction terminates.
  ]),
  charging_profile_kind: types.enumeration([
    // Required. Indicates the kind of schedule.
    ChargingProfileKindEnum.Absolute, // Schedule periods are relative to a fixed point in time defined in the schedule.
    ChargingProfileKindEnum.Relative, // Schedule periods are relative to a situation-specific start point (such as the start of a Transaction) that is determined by the charge point.
    ChargingProfileKindEnum.Recurring, // The schedule restarts periodically at the first schedule period.
  ]),
  recurrency_kind: types.maybeNull(
    // Optional. Indicates the start point of a recurrence.
    types.enumeration([
      RecurrencyKindEnum.Daily, // The schedule restarts every 24 hours, at the same time as in the startSchedule.
      RecurrencyKindEnum.Weekly, // The schedule restarts every 7 days, at the same time and day-of-the-week as in the startSchedule.
    ]),
  ),
  valid_from: types.maybeNull(types.string), // Optional. Point in time at which the profile starts to be valid. If absent, the profile is valid as soon as it is received by the Charge Point.
  valid_to: types.maybeNull(types.string), // Optional. Point in time at which the profile stops to be valid. If absent, the profile is valid until it is replaced by another profile.
  charging_schedule: ChargingSchedule, // Required. Contains limits for the available power or current over time.
});

export const ChargingProfile = types.compose(
  OCPPChargingProfile,
  types.model({
    charging_station_id: types.string,
    active: types.boolean,
    connector_id: types.integer,
  }),
);

export interface OCPPChargingProfile
  extends SnapshotIn<typeof OCPPChargingProfile> {}

export interface ChargingProfile extends SnapshotIn<typeof ChargingProfile> {}

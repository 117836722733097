import React, { useState } from 'react';
import { Box, Card, CardContent, Tab, Tabs } from "@mui/material";
import globalStyles from '../../../../global-styles.module.scss';
import { ChargingStationStatusHeader } from './charging-station-status-header';
import { TabPanel } from 'src/components';
import { DataTransfer } from 'src/components/data-transfer/data-transfer';
import { useStores } from 'src/store';
import { Configurations } from 'src/components/configurations/configurations';
import { Reset } from 'src/components/reset/reset';

export const ChargingStationCommands = () => {
  const { chargingStationStore } = useStores();

  const handleTabChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`
    };
  };

  const [value, setValue] = useState(0);

  return (
    <Card className={globalStyles.container}>
      <ChargingStationStatusHeader />
      <CardContent style={{ minHeight: 650 }}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex'
          }}
        >
          <Tabs
            orientation="vertical"
            style={{ width: '15rem' }}
            value={value}
            onChange={handleTabChange}
          >
            <Tab label="Data transfer" {...a11yProps(0)} />
            <Tab label="Configurations" {...a11yProps(1)} />
            <Tab label="Reset" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <DataTransfer chargingStations={[
              {"charging_station": chargingStationStore.currentChargingStation}
            ]} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Configurations chargingStation={chargingStationStore.currentChargingStation || {}}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Reset chargingStation={chargingStationStore.currentChargingStation} />
          </TabPanel>
          </Box>
      </CardContent>
    </Card>
  );
}
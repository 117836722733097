import { SnapshotIn, types } from 'mobx-state-tree';

export const User = types.model({
  cognito_id: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  pendingInvitation: types.maybeNull(types.boolean),
  virtual_id_token: types.maybeNull(types.string),
});

export interface User extends SnapshotIn<typeof User> {}

import { SnapshotIn, types } from 'mobx-state-tree';

export const ChargingStationGroup = types.model({
  id: types.maybeNull(types.string),
  default: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  location_id: types.maybeNull(types.string),
});

export const ChargingStationGroupExtended = types.compose(
  ChargingStationGroup,
  types.model({
    chargingStations: types.maybeNull(types.array(types.string)),
  }),
);

export interface ChargingStationGroup
  extends SnapshotIn<typeof ChargingStationGroup> {}

export interface ChargingStationGroupExtended
  extends SnapshotIn<typeof ChargingStationGroupExtended> {}

import { SnapshotIn, types } from 'mobx-state-tree';

import { SearchResult } from './SearchResult';
import { User } from './User';


export const UserSearchResult = types.compose(
  SearchResult,
  types.model({
    items: types.array(User),
  }),
);

export type UserSearchResult = SnapshotIn<
  typeof UserSearchResult
>;

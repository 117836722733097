import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          border: 'none'
        },
        spacer: {
          display: 'none'
        },
        root: {
          display: 'flex',
          justifyContent: 'center'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Futura'
  },
  palette: {
    primary: {
      main: '#BD8D2B',
      light: '#f3bd5b',
      dark: '#896000'
    },
    secondary: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#F7F6FA'
    },
    text: {
      primary: '#333333',
      secondary: '#333333'
    }
  }
});

import {
  Alert,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';
import { useStores } from 'src/store';
import { t } from 'i18next';
import styles from './reset.module.scss';
import { Send } from '@mui/icons-material';
import { Loader } from '../loader/loader';

const SOFT = 'Restart software';
const HARD = 'Reboot device';

type Props = {
  chargingStation: any;
};

export const Reset = ({ chargingStation }: Props) => {
  const { actionStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [actionRequestSuccess, showActionRequestSuccess] = useState(false);
  const [actionRequestFail, showActionRequestFail] = useState(false);
  const [actionRequestType, setActionRequestType] = useState(SOFT);

  const handleChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);

    setActionRequestType(event.target.value);
  };

  const executeCommand = () => {
    setLoading(true);
    switch (actionRequestType) {
      case SOFT:
        resetChargingStation();
        break;
      case HARD:
        rebootChargingStation();
        break;
    }
  };

  const resetChargingStation = () => {
    const id = chargingStation?.id || '';
    actionStore.resetDevice(id).then((res) => {
      handleActionRequestAlert(res);
    });
  };

  const rebootChargingStation = () => {
    const id = chargingStation?.id || '';
    actionStore.rebootDevice(id).then((res) => {
      handleActionRequestAlert(res);
    });
  };

  const handleActionRequestAlert = (state: string) => {
    console.log('state', state);
    console.log('action', actionRequestType);
    setLoading(false);
    if (state === 'success') {
      showActionRequestSuccess(true);
    } else {
      showActionRequestFail(true);
    }
  };

  return (
    <div className={styles.chargingStationGeneralDetails}>
      <Card className={styles.container}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            margin: 'auto',
            width: '1000px',
            marginBottom: 5,
            marginTop: 4,
            paddingBottom: 0,
          }}>
          <Grid xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="reset-label">Reset</InputLabel>
              <Select
                labelId="reset-label"
                id="reset"
                value={actionRequestType}
                onChange={handleChange}>
                <MenuItem value={SOFT}>Soft reset</MenuItem>
                <MenuItem value={HARD}>Hard reset</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={7}>
            <Button
              style={{ marginTop: 10, marginLeft: 15, minWidth: 200 }}
              disabled={loading}
              variant="outlined"
              onClick={executeCommand}
              endIcon={!loading ? <Send /> : null}>
              {loading ? <Loader /> : t('Execute Command')}
            </Button>
          </Grid>
        </Grid>
      </Card>
      {actionRequestSuccess && (
        <Alert variant="filled" severity="info">
          {t('Request recieved.')} <em>({actionRequestType})</em>
        </Alert>
      )}
      {actionRequestFail && (
        <Alert variant="filled" severity="error">
          {t('Request error.')} <em>({actionRequestType})</em>
        </Alert>
      )}
    </div>
  );
};

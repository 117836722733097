import { ApiResponse } from 'src/core';
import {
  ChargingStationConfigurations,
  ChargingStationModelType,
  OCPPVersion,
} from 'src/store/model/ChargingStationConfigurations';
import { CS_RESOURCE_PATH } from './charging-stations.api';

import { CloudApi } from './cloud-api';

export const CONFIGURATIONS_RESOURCE_PATH = 'charging-station-configurations';

export class ChargingStationConfigurationsApi extends CloudApi {
  async getConfiguration(chargingStationId: string): Promise<ApiResponse> {
    return await this.GET(
      `${CS_RESOURCE_PATH}/${chargingStationId}/configuration`,
    );
  }

  async updateConfiguration(
    chargingStationId: string,
    configuration: ChargingStationConfigurations,
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${CS_RESOURCE_PATH}/${chargingStationId}/configuration`,
      configuration,
    );
  }

  async createConfigurationVariable(
    chargingStationId: string,
    key: string,
    value: any,
  ): Promise<ApiResponse> {
    return await this.POST(
      `${CS_RESOURCE_PATH}/${chargingStationId}/configuration`,
      { key, value },
    );
  }

  async updateConfigurationVariable(
    chargingStationId: string,
    key: string,
    value: any,
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${CS_RESOURCE_PATH}/${chargingStationId}/configuration/${key}`,
      { key, value },
    );
  }

  async removeConfigurationVariable(
    chargingStationId: string,
    key: string,
  ): Promise<ApiResponse> {
    return await this.DELETE(
      `${CS_RESOURCE_PATH}/${chargingStationId}/configuration/${key}`,
    );
  }

  async removeConfiguration(chargingStationId: string): Promise<ApiResponse> {
    return await this.DELETE(
      `${CS_RESOURCE_PATH}/${chargingStationId}/configuration`,
    );
  }

  async getSchema(
    charger: ChargingStationModelType,
    ocpp: OCPPVersion,
  ): Promise<ApiResponse> {
    let schema = '';

    const [chargerSchema]: any = Object.entries(ChargingStationModelType).find(
      // @note: check this
      /* eslint-disable @typescript-eslint/no-unused-vars */
      ([_, value]) => value === charger,
    );

    if (charger === ChargingStationModelType.entity) {
      schema = `${chargerSchema}.ocpp_${
        ocpp === OCPPVersion.v1_6 ? 'v16' : 'v20'
      }`;
    } else {
      schema = `${chargerSchema}`;
    }

    return await this.GET(
      `static/schemas/charging-station-configurations/${schema}.schema.json`,
    );
  }

  async getSupportedCsmsDict(): Promise<ApiResponse> {
    return await this.GET(
      `${process.env.REACT_APP_SUPPORTED_DESTINATION_CSMS_BASE_URL}/supported_destination_csms_public.json`,
          {/* @note: not needed? Does not seem to be passed to Axios
        headers: {
          'Content-Type': 'application/json',
        },
        */
      },
    );
  }
}
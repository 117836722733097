import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { ChargingProfile, ChargingScheduleItem } from 'src/store/model';
import { addSeconds, differenceInSeconds, format, startOfWeek } from 'date-fns';
import * as fnsLocale from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useStores } from 'src/store';

export type EVENT_TYPE =
  | 'FreeChargeByApp'
  | 'FreeCharge'
  | 'ByUserAccess'
  | 'NonScheduledByApp';

export type ScheduleGraphEvent = {
  start_schedule: string;
  start_period: number;
  stop_period: number;
  color: string;
};

type scheduleEvents = {
  'startSchedule': string,
  'nonScheduledByApp': ScheduleGraphEvent[],
  'weekly': ScheduleGraphEvent[],
  'oneTime': ScheduleGraphEvent[],
  'freeChargeByApp': ScheduleGraphEvent[],
};

type Props = {
    profiles: ChargingProfile[];
    isViewer: boolean;
  };
  
export const CharginStationSchedule = ({ profiles, isViewer }: Props) => {
  const { chargingStationStore } = useStores();
  const isEditor = !isViewer;
  const schedule = {
    WIDTH: 650,
    HEIGHT: 200,
  }
  const colors = {
    FREE_CHARGE_COLOR: '#46AA73',
    BY_USER_ACCESS_COLOR: '#F2C94C',
    NON_ACTIVE_COLOR: '#9AAEBB',
    LIGHT_STROKE_COLOR: '#5C565C',
    STROKE_COLOR: '#2A272A',
    DISABLED_COLOR: '#FA6A59',
    ACCESS_COLOR: '#F8E382',
  };

  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedProfileToBeRemoved, setSelectedProfileToBeRemoved] = React.useState<ChargingProfile | null>(null);
  const [selectedIndexToBeRemoved, setSelectedIndexToBeRemoved] = React.useState<number | null>(null);
  
  const isScheduleActive = useMemo(() => {
    const weeklyProfile = profiles.find((cp) => cp.stack_level === 1);
    if (weeklyProfile) {
      return weeklyProfile.active;
    }
  }, [profiles]);
  
  const determineFillColor = useCallback((item: ChargingScheduleItem) => {
    switch (item.type) {
      case 'FreeChargeByApp':
        return item.active ? colors.FREE_CHARGE_COLOR : colors.DISABLED_COLOR;
      case 'FreeCharge':
        return item.active ? colors.FREE_CHARGE_COLOR : colors.NON_ACTIVE_COLOR;
      case 'ByUserAccess':
        return item.active ? colors.BY_USER_ACCESS_COLOR : colors.NON_ACTIVE_COLOR;
      case 'NonScheduledByApp':
        if (isViewer) {
          return isScheduleActive && !item.active ? colors.DISABLED_COLOR : colors.ACCESS_COLOR;
        } else {
          return item.active ? colors.ACCESS_COLOR : colors.DISABLED_COLOR;
        }
      default:
        return colors.FREE_CHARGE_COLOR;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewer, isScheduleActive]);

  const scheduleEvents = useMemo(() => {
    const isProfileActive = profiles?.find((cp) => cp.stack_level === 1)?.active ?? false;
    // const events1: ScheduleGraphEvent[] = [];
    const events: scheduleEvents = {
      'startSchedule': '',
      'nonScheduledByApp': [],
      'weekly': [],
      'oneTime': [],
      'freeChargeByApp': [],
    };

    if (profiles.length > 0) {
      profiles.forEach((profile: ChargingProfile) => {
        const isOneTime = profile.stack_level ? profile?.stack_level > 2 : false;
        let oneTimeOffset = 0;
        if (isOneTime) {
          const startDate = new Date(profile.charging_schedule.start_schedule ?? '');
          const startWeek = startOfWeek(startDate, { weekStartsOn: 1 });
          oneTimeOffset = differenceInSeconds(startDate, startWeek);
        }
        events.startSchedule = profile.charging_schedule.start_schedule ?? '';
        const items = profile.charging_schedule.charging_schedule_item;
        items?.forEach((item) => {
          const isActive = item.active ?? true;
          const isFreeOfChargeEvent = item.type === 'FreeChargeByApp';
          const isNonScheduledEvent = item.type === 'NonScheduledByApp';
          const isEvent = !isFreeOfChargeEvent && !isNonScheduledEvent;

          if (isViewer && isFreeOfChargeEvent && !isActive) {
            return;
          }
          if (isViewer && isEvent && !isActive) {
            return;
          }
          if (isViewer && isEvent && !isProfileActive) {
            return;
          }
          if (isEditor && isFreeOfChargeEvent && isActive) {
            return;
          }
          if (isEditor && isFreeOfChargeEvent && !isActive) {
            return;
          }

          const startTime = isOneTime ? oneTimeOffset : item.start_period;
          const endTime = isOneTime ? oneTimeOffset + (profile.charging_schedule.duration ?? 0) : item.stop_period;
          // const active = isEditor || isFreeOfChargeEvent || isNonScheduledEvent ? item.active : (isProfileActive && item.active) ?? false;
          // const height = maxInstalledCurrent;
          if (startTime !== undefined && endTime !== undefined) {
            if (startTime < endTime) {
              const event = {
                start_schedule: profile.charging_schedule.start_schedule ?? '',
                start_period: startTime,
                stop_period: endTime,
                color: determineFillColor(item),
              };
              if (isFreeOfChargeEvent) {
                events.freeChargeByApp.push(event);
              } else if(isNonScheduledEvent) {
                events.nonScheduledByApp.push(event);
              } else if(isOneTime) {
                events.oneTime.push(event);
              } else {
                events.weekly.push(event);
              }
            } else {
              // The period crosses midnight at the end of the week so we need two events.
              const event1 = {
                start_schedule: profile.charging_schedule.start_schedule ?? '',
                start_period: 0,
                stop_period: endTime,
                color: determineFillColor(item),
              };
              isOneTime ? events.oneTime.push(event1) : events.weekly.push(event1);
              const event2 = {
                start_schedule: profile.charging_schedule.start_schedule ?? '',
                start_period: startTime,
                stop_period: 604800,
                color: determineFillColor(item),
              };
              isOneTime ? events.oneTime.push(event2) : events.weekly.push(event2);
            }
          }
        });
      });
    } else {
      // Add a free of charge event for the whole week.
      if (isViewer){
        events.freeChargeByApp.push({
          start_schedule: '',
          start_period: 0,
          stop_period: 604800,
          color: colors.FREE_CHARGE_COLOR,
        });
      }
    }
    return events;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles, isViewer, isEditor, determineFillColor]);

  const tooltip = useMemo<any>(() =>
    d3.select('body')
    .append("div")
    .style("position", "absolute")
    .style("z-index", "100")
    .style("visibility", "hidden")
    .style("background", "#fff")
    .text("a simple tooltip"), []);

  const ref = useRef<any>();
  const refSvg = useRef<any>();
  useEffect(() => {
    const margin = { top: 30, right: 30, bottom: 30, left: 30 }
    const width = schedule.WIDTH - margin.left - margin.right;
    const height = schedule.HEIGHT - margin.top - margin.bottom;
    try {
      ref.current.removeChild(ref.current.children[0]);
    } catch (e) { /* empty */ }
    // append the svg object to the body of the page
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const event = d3.scaleLinear().domain([0, 604800]).range([0, width]);
    
    // X axis
    const days = [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday')];
    const x = d3.scaleBand(days, [0, width]);
    svg.append("g").attr("transform", `translate(0, ${height})`).call(d3.axisBottom(x))

    // Add Y axis
    // const y = d3.scaleLinear().domain([0, 32]).range([height, 0]);
    // svg.append("g").call(d3.axisLeft(y));

    // Events nonScheduledByApp
    svg
      .selectAll("events")
      .data(scheduleEvents.nonScheduledByApp)
      .enter()
      .append("rect")
      .attr("x", (d) => event(d?.start_period ?? 0))
      .attr("y", 0)
      .attr("width", (d) => event(d?.stop_period ?? 0) - event(d?.start_period ?? 0))
      .attr("height", height)
      .attr("stroke", 'black')
      .attr("fill", (d) => d.color)

    // Events weekly
    svg
      .selectAll("events")
      .data(scheduleEvents.weekly)
      .enter()
      .append("rect")
      .attr("x", (d) => event(d?.start_period ?? 0))
      .attr("y", 0)
      .attr("width", (d) => event(d?.stop_period ?? 0) - event(d?.start_period ?? 0))
      .attr("height", height)
      .attr("stroke", 'black')
      .attr("fill", (d) => d.color)
      .on("mouseover", () => {
        return tooltip.style("visibility", "visible");
      })
      .on("mousemove", (event, d) => { 
        tooltip.text(formatTime(d.start_schedule, d.start_period) + ' - ' + formatTime(d.start_schedule, d.stop_period));
        return tooltip.style("top", (event.pageY-10)+"px").style("left",(event.pageX+10)+"px");
      })
      .on("mouseout", () => {
        return tooltip.style("visibility", "hidden");
      });

    // Events oneTime
    svg
      .selectAll("events")
      .data(scheduleEvents.oneTime)
      .enter()
      .append("rect")
      .attr("x", (d) => event(d?.start_period ?? 0))
      .attr("y", 0)
      .attr("width", (d) => event(d?.stop_period ?? 0) - event(d?.start_period ?? 0))
      .attr("height", height)
      .attr("stroke", 'black')
      .attr("fill", (d) => d.color);

    // Events oneTime hash marks
    svg
      .selectAll("events")
      .data(scheduleEvents.oneTime)
      .enter()
      .append("rect")
      .attr("x", (d) => event(d?.start_period))
      .attr("y", 0)
      .attr("width", (d) => event(d?.stop_period ?? 0) - event(d?.start_period))
      .attr("height", height)
      .attr("stroke", 'black')
      .attr("fill", 'url(#diagonalHatch)')
      .on("mouseover", () => {
        return tooltip.style("visibility", "visible");
      })
      .on("mousemove", (event, d) => { 
        tooltip.text(formatTime(d.start_schedule, d.start_period) + ' - ' + formatTime(d.start_schedule, d.stop_period));
        return tooltip.style("top", (event.pageY-10)+"px").style("left",(event.pageX+10)+"px");
      })
      .on("mouseout", () => {
        return tooltip.style("visibility", "hidden");
      });

    // Events freeChargeByApp
    svg
      .selectAll("events")
      .data(scheduleEvents.freeChargeByApp)
      .enter()
      .append("rect")
      .attr("x", (d) => event(d?.start_period))
      .attr("y", 0)
      .attr("width", (d) => event(d?.stop_period ?? 0) - event(d?.start_period))
      .attr("height", height)
      .attr("stroke", 'black')
      .attr("fill", (d) => d.color);

    // Separation lines for days
    svg
      .selectAll(".divide")
      .data(days)
      .enter().append("line")
      .attr("y1", 0)
      .attr("y2", height)
      .attr("x1", (d) => x(d) || 0)
      .attr("x2", (d) => x(d) || 0)
      .attr("stroke", 'gray')
      .attr("stroke-dasharray", 4)
      .attr("stroke-width", 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleEvents]);

  const formatTime = (scheduleStart: string, periodTime: number) => {
    const d = addSeconds(new Date(scheduleStart), periodTime);
    const time = format(d, 'p',{ locale: fnsLocale.sv });
    const start = format(d, 'EEEE',{ locale: fnsLocale.sv });
    return `${time} ${start}`;
  }

  const deleteEvent = (profile: ChargingProfile, index: number) => {
    setSelectedProfileToBeRemoved(profile);
    setSelectedIndexToBeRemoved(index);
    setDialogOpen(true);
  }

  const handleClose = async (remove: boolean) => {
    if (remove) {
      if (selectedProfileToBeRemoved?.stack_level === 1) {
        await chargingStationStore.updateChargingProfile({
          ...selectedProfileToBeRemoved,
          charging_schedule: {
            ...selectedProfileToBeRemoved.charging_schedule,
            charging_schedule_item: (
              selectedProfileToBeRemoved.charging_schedule.charging_schedule_item?.filter(
                (item, i) => i !== selectedIndexToBeRemoved,
              ) ?? []
            ),
          },
        });
      } else {
        await chargingStationStore.removeChargingProfile(
          selectedProfileToBeRemoved?.charging_station_id ?? '',
          selectedProfileToBeRemoved?.charging_profile_id ?? -1,
        );
      }
    }
    setDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ fontWeight: 'bold' }}>
        {isViewer ? <div>{t('Current schedule')}</div> : <div>{t('Edited schedule')}</div>}
      </Box>
      <svg style={{ border: "1px solid lightgray" }} width={schedule.WIDTH} height={schedule.HEIGHT} ref={refSvg}>
        <pattern id="diagonalHatch" width="10" height="10" patternTransform="rotate(135 0 0)" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="10" stroke="black" strokeWidth="3" />
        </pattern>
        <svg id="barchart" ref={ref}></svg>
      </svg>
      { !isViewer && <Box>
        <Typography
          style={{ marginBottom: '1rem' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {/* <TableCell align='center'>Tabort</TableCell> */}
                <TableCell>{t('Start time')}</TableCell>
                <TableCell>{t('Stop time')}</TableCell>
                <TableCell>{t('type')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profiles.map((profile, profileIndex1) => {
                return profile.charging_schedule.charging_schedule_item?.map((row, itemIndex) => {
                  if (row.type === 'FreeCharge' || row.type === 'ByUserAccess') {
                    const startSchedule = profile.charging_schedule.start_schedule ?? '';
                    const type = profile.stack_level === 1 ? t('Weekly') : t('One time');
                    return (
                      <TableRow hover key={profileIndex1 + '' + itemIndex}>
                        {/* <TableCell align='center'>
                          <div>
                            <IconButton onClick={() => {deleteEvent(profile, itemIndex)}} aria-label="settings">
                              <DeleteForeverIcon color="error" />
                            </IconButton>
                          </div>
                        </TableCell> */}
                        <TableCell>{formatTime(startSchedule, row.start_period)}</TableCell>
                        <TableCell>{formatTime(startSchedule, row.stop_period)}</TableCell>
                        <TableCell>{type}</TableCell>
                      </TableRow>
                    )
                  }
                })
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      }
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Delete event')}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('Delete description')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{t('No')}</Button>
          <Button onClick={() => handleClose(true)} autoFocus>{t('Yes')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
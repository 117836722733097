
import { ApiResponse } from 'src/core';

import { CloudApi } from './cloud-api';

const CS_FW_RESOURCE_PATH = 'charging-station-firmware';

export class ChargingStationFirmwareApi extends CloudApi {
  async getChargingStationFirmwareSettings(
    chargingStationId: string,
  ): Promise<ApiResponse> {
    return await this.GET(`${CS_FW_RESOURCE_PATH}/${chargingStationId}`);
  }

  async updateChargingStationFirmwareSettings(
    chargingStationId: string,
    updateAutomatically: boolean,
  ): Promise<ApiResponse> {
    return await this.PUT(`${CS_FW_RESOURCE_PATH}/${chargingStationId}`, {
      charging_station_id: chargingStationId,
      update_automatically: updateAutomatically,
    });
  }
}

import { SnapshotIn, types } from 'mobx-state-tree';

import { ChargingStationInstallationStatus } from './ChargingStationInstallation';

export enum ChargingStationConfigurationStatusEnum {
  accepted = 'Accepted',
  rejected = 'Rejected',
  reboot_required = 'RebootRequired',
  not_supported = 'NotSupported',
  failed = 'Failed',
}

export enum ChargingStationFirmwareStatusEnum {
  Downloaded = 'Downloaded',
  DownloadFailed = 'DownloadFailed',
  Downloading = 'Downloading',
  Idle = 'Idle',
  InstallationFailed = 'InstallationFailed',
  Installing = 'Installing',
  Installed = 'Installed',
  InstallVerificationFailed = 'InstallVerificationFailed',
  InvalidSignature = 'InvalidSignature',
  SignatureVerified = 'SignatureVerified',
}

export enum ChargingStationConnectionStatus {
  connected = 'Connected',
  disconnected = 'Disconnected',
}

export enum ChargingStationRegistrationStatus {
  accepted = 'Accepted',
  pending = 'Pending',
  rejected = 'Rejected',
}

export const ChargingStationStatus = types.model({
  charging_station_id: types.identifier,
  heartbeat_timestamp: types.maybeNull(types.string),
  connection: types.maybeNull(
    types.enumeration([
      ChargingStationConnectionStatus.connected,
      ChargingStationConnectionStatus.disconnected,
    ]),
  ),
  registration: types.maybeNull(
    types.enumeration([
      ChargingStationRegistrationStatus.accepted,
      ChargingStationRegistrationStatus.pending,
      ChargingStationRegistrationStatus.rejected,
    ]),
  ),
  installation: types.maybeNull(ChargingStationInstallationStatus),
  configuration: types.maybeNull(
    types.enumeration([
      ChargingStationConfigurationStatusEnum.accepted,
      ChargingStationConfigurationStatusEnum.rejected,
      ChargingStationConfigurationStatusEnum.reboot_required,
      ChargingStationConfigurationStatusEnum.not_supported,
      ChargingStationConfigurationStatusEnum.failed,
    ]),
  ),
  firmware_update: types.maybeNull(
    types.enumeration([
      ChargingStationFirmwareStatusEnum.Downloaded,
      ChargingStationFirmwareStatusEnum.DownloadFailed,
      ChargingStationFirmwareStatusEnum.Downloading,
      ChargingStationFirmwareStatusEnum.Idle,
      ChargingStationFirmwareStatusEnum.InstallationFailed,
      ChargingStationFirmwareStatusEnum.Installing,
      ChargingStationFirmwareStatusEnum.Installed,
      ChargingStationFirmwareStatusEnum.InstallVerificationFailed,
      ChargingStationFirmwareStatusEnum.InvalidSignature,
      ChargingStationFirmwareStatusEnum.SignatureVerified,
    ]),
  ),
  using_proxy: types.maybeNull(types.boolean),
});

export type ChargingStationStatus = SnapshotIn<typeof ChargingStationStatus>

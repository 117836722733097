import React from 'react';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { styled } from '@mui/system';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';

import { theme } from 'src/theme';

const Tab = styled(TabUnstyled)`
  color: ${theme.palette.text.primary};
  background-color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  width: 100%;
  padding: 12px 16px;
  height: 100%;
  border: none;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

  &:hover {
    background-color: ${theme.palette.primary.light};
  }

  &:focus {
    color: #fff;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: transparent;
    color: white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 1;
    cursor: not-allowed;
    color: lightGray;
    background-color: white;
  }
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: ${theme.palette.primary.main};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  max-width: 1000px;
  width: 100%;
  margin-left: 0!important;
  margin-right: 0!important;
  border: none;
`;
type Props = {
  setValue: React.Dispatch<React.SetStateAction<number>>;
};

export const DetailsTabs = ({ setValue }: Props) => {
  return (
    <TabsUnstyled
      style={{display: 'flex', justifyContent: 'center', width: '100%' }}
      onChange={(_, value) => setValue(Number(value))}
      defaultValue={0}
    >
      <TabsList
        style={{
          marginLeft: 24,
          marginRight: 24,
          marginTop: 20
        }}
      >
        <Tab>Details</Tab>
        <Tab>Groups</Tab>
        <Tab>Configuration</Tab>
        <Tab>Access</Tab>
        <Tab>Actions</Tab>
        <Tab>Commands</Tab>
      </TabsList>
    </TabsUnstyled>
  );
};

import {Amplify, Auth } from 'aws-amplify';

import { AwsConfig } from '../aws-config/AwsConfig';

// Amplify.configure(AwsConfig);

export const requestAuth = async (email: string, password: string) => {
  Amplify.configure(AwsConfig);
  return await Auth.signIn(email, password);
};
export const confirmSignIn = async (cognitoUser: any, authCode: string) => {
  return await Auth.confirmSignIn(cognitoUser, authCode, cognitoUser.challengeName);
};


export const getToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

export const currentAuthenticatedUser = async () => {
  return await Auth.currentAuthenticatedUser();
};

export const getTOTPQRCode = async (username: string, password: string) => {
  const cognitoUser = await Auth.signIn(username, password);
  const code = await Auth.setupTOTP(cognitoUser);
  return {cognitoUser, qrCode: `otpauth://totp/AWSCognito:${cognitoUser?.username}?secret=${code}&issuer=AWSCognito`};
};

export const turnOnTOTP = async (user: any,  authCode: string) => {
  await Auth.verifyTotpToken(user, authCode);
  return await Auth.setPreferredMFA(user, 'TOTP');
};

export const turnOffTOTP = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const message = await Auth.setPreferredMFA(user, 'NOMFA');
  await Auth.signOut();
  return message;
}


export const signOut = async () => {
  Amplify.configure(AwsConfig);
  return await Auth.signOut();
};

export const forgotPassword = async (username: string) => {
  return await Auth.forgotPassword(username);
};

export const forgotPasswordSubmit = async (
  username: string,
  password: string,
  code: string,
) => {
  return await Auth.forgotPasswordSubmit(username, code, password);
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(user, oldPassword, newPassword);
};

export const signUp = async (
  username: string,
  password: string,
  name: string,
  family_name: string,
) => {
  return await Auth.signUp({
    username,
    password,
    attributes: {
      name,
      family_name,
    },
  });
};

import { MeterValue, MeterValueType } from 'src/store/model';
import { useEffect, useState } from 'react';

export const useLatestMeterValue = (
  meterValues: MeterValue[],
  type: MeterValueType,
  location?: 'Inlet' | 'Outlet' | 'Body',
) => {
  const [latestMeterValue, setLatestMeterValue] = useState<number>(0);

  useEffect(() => {
    if (meterValues?.length) {
      const latestValue = meterValues?.find(
        (item) =>
          item.measure_name === type &&
          (location ? item.location === location : true),
      );
      setLatestMeterValue(parseFloat(latestValue?.measure_value || '0'));
    } else {
      setLatestMeterValue(0);
    }
  }, [location, meterValues, type]);
  return latestMeterValue;
};

import { ApiResponse } from 'src/core';
import {
  ChargingStation,
  ChargingStationSettings,
  MeterValueFunction,
  MeterValueTimeWindow,
  MeterValueType,
  AuditLogType,
  AuditLogOperation,
  BlockStatus,
  ChargingProfile,
  FreeOfChargeSchedule,
} from 'src/store/model';

import { CloudApi } from './cloud-api';
import { LOCATION_RESOURCE_PATH } from './locations.api';

export const CS_RESOURCE_PATH = 'charging-stations';

export class ChargingStationsApi extends CloudApi {
  async getChargingStations(
    filter_type: string | null | undefined,
    filter_value: string | null | undefined,
    limit = 25,
    next: string | null | undefined
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      filter_type,
      filter_value,
      next,
      limit
    });
    return await this.GET(`${CS_RESOURCE_PATH}${queryString}`);
  }

  async getChargingStation(id: string): Promise<ApiResponse> {
    return await this.GET(`${CS_RESOURCE_PATH}/${id}`);
  }

  async getChargingStationSettings(id: string): Promise<ApiResponse> {
    return await this.GET(`${CS_RESOURCE_PATH}/${id}/settings`);
  }

  async updateChargingStationSettings(
    id: string,
    settings: ChargingStationSettings
  ): Promise<ApiResponse> {
    return await this.PUT(`${CS_RESOURCE_PATH}/${id}/settings`, settings);
  }

  async addChargingStation(
    cs: ChargingStation,
    locationId: string
  ): Promise<ApiResponse> {
    return await this.POST(
      `${LOCATION_RESOURCE_PATH}/${locationId}/${CS_RESOURCE_PATH}`,
      cs
    );
  }

  async updateChargingStation(
    cs: ChargingStation,
    newSerial?: string
  ): Promise<ApiResponse> {
    return await this.PUT(`${CS_RESOURCE_PATH}/${cs.id}`, {
      ...cs,
      id: newSerial ? newSerial : cs.id
    });
  }

  async removeChargingStation(id: string): Promise<ApiResponse> {
    return await this.DELETE(`${CS_RESOURCE_PATH}/${id}`);
  }

  async getChargingStationInstallation(
    chargingStationId?: string
  ): Promise<ApiResponse> {
    return await this.GET(
      `${CS_RESOURCE_PATH}/${chargingStationId}/installation`
    );
  }

  async getChargingStationStatus(
    chargingStationId?: string
  ): Promise<ApiResponse> {
    return await this.GET(`${CS_RESOURCE_PATH}/${chargingStationId}/status`);
  }

  async getChargingUnit(chargingStationId: string): Promise<ApiResponse> {
    return await this.GET(
      `${CS_RESOURCE_PATH}/${chargingStationId}/charging-unit`
    );
  }

  async getChargingStationConnectorStatus(
    chargingStationId: string
  ): Promise<ApiResponse> {
    return await this.GET(
      `${CS_RESOURCE_PATH}/${chargingStationId}/connector-status`
    );
  }

  async getOcppTransactions(
    charging_station_id: string,
    context: string,
    id_token: string | null | undefined,
    next: string | null | undefined,
    limit = 20
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      charging_station_id,
      context,
      id_token,
      next,
      limit
    });
    return await this.GET(`transactions${queryString}`);
  }

  async getOcppEvents(
    charging_station_id: string,
    next: string | null | undefined,
    type = 'StatusNotification',
    limit = 20
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      charging_station_id,
      type,
      next,
      limit
    });

    return await this.GET(`ocpp-events${queryString}`);
  }

  async getOcppEvent(id: string): Promise<ApiResponse> {
    return await this.GET(`ocpp-events/${id}`);
  }

  async getChargingStationLatestMeterValues(
    charging_station_id: string,
    connector_id: number | undefined,
    transaction_id: string | null | undefined
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      charging_station_id,
      connector_id,
      transaction_id
    });

    return await this.GET(`meter-values/latest${queryString}`);
  }

  async getChargingStationHistoricalMeterValues(
    charging_station_id: string,
    context: string,
    time_window?: MeterValueTimeWindow | null | undefined,
    type?: MeterValueType | null | undefined,
    functionType?: MeterValueFunction | null | undefined,
    connector_id?: number | undefined,
    transaction_id?: string | null | undefined
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      charging_station_id,
      context,
      time_window,
      type,
      function: functionType,
      connector_id,
      transaction_id
    });

    return await this.GET(`meter-values/historical${queryString}`);
  }

  async getChargingStationMeterValues(
    charging_station_id: string,
    context: string,
    connector_id: number | undefined,
    type: MeterValueType | null | undefined,
    transaction_ids: string[] | null | undefined,
    start_time?: string | undefined,
    end_time?: string | undefined,
    next?: string | null | undefined,
    limit?: number | undefined
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      charging_station_id,
      context,
      connector_id,
      type,
      transaction_ids,
      start_time,
      end_time,
      next,
      limit
    });

    return await this.GET(`meter-values${queryString}`);
  }

  async getAutidlog(
    charging_station_id: string,
    type: AuditLogType | null | undefined,
    operation: AuditLogOperation | null | undefined,
    limit = 25,
    next: string | null | undefined,
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      charging_station_id,
      type,
      operation,
      next,
      limit
    });

    return await this.GET(`audit-logs${queryString}`);
  }

  async getChargingStationLogs(
    chargingStationId: string
  ): Promise<ApiResponse> {
    return await this.GET(`charging-station-logs/${chargingStationId}`);
  }

  async getChargingStationBlocklist(
    chargingStationId: string
  ): Promise<ApiResponse> {
    return await this.GET(`charging-station-blocklist/${chargingStationId}`);
  }

  async updateChargingStationBlocklist(
    chargingStationId: string,
    status: BlockStatus,
    reason?: string
  ): Promise<ApiResponse> {
    return await this.PUT(`charging-station-blocklist`, {
      id: chargingStationId,
      type: 'ChargingUnit',
      status,
      reason
    });
  }

  async createChargingStationBlocklist(
    chargingStationId: string,
    status: BlockStatus,
    reason?: string
  ): Promise<ApiResponse> {
    return await this.POST(`charging-station-blocklist`, {
      id: chargingStationId,
      type: 'ChargingUnit',
      status,
      reason
    });
  }

  async getChargingStationReservations(
    chargingStationId: string
  ): Promise<ApiResponse> {
    return await this.GET(
      `${CS_RESOURCE_PATH}/${chargingStationId}/reservations`
    );
  }

  async deleteChargingStationReservation(
    reservationId: string
  ): Promise<ApiResponse> {
    return await this.DELETE(`${CS_RESOURCE_PATH}/${reservationId}/reservation`);
  }

  async getFactoryKeys(
    charging_station_ids: string
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      charging_station_ids,
    });
    return await this.GET(`factory-keys/${queryString}`);
  }

  async getChargingProfiles(chargingStationId: string): Promise<ApiResponse> {
    return await this.GET(
      `${CS_RESOURCE_PATH}/${chargingStationId}/charging-profiles`,
    );
  }

  async updateChargingProfiles(
    chargingStationId: string,
    charging_profiles: ChargingProfile[],
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${CS_RESOURCE_PATH}/${chargingStationId}/charging-profiles`,
      charging_profiles,
    );
  }
  
  async deleteChargingProfiles(
    chargingStationId: string,
    charging_profile_ids: string[],
  ): Promise<ApiResponse> {
    const queryString = charging_profile_ids
      .map(
        (id) =>
          encodeURIComponent('charging_profile_ids') +
          '=' +
          encodeURIComponent(id),
      )
      .join('&');
    return await this.DELETE(
      `${CS_RESOURCE_PATH}/${chargingStationId}/charging-profiles?${queryString}`,
    );
  }

  async getFreeOfChargeSchedules(
    chargingStationId: string,
  ): Promise<ApiResponse> {
    return await this.GET(
      `${CS_RESOURCE_PATH}/${chargingStationId}/settings/free-of-charge-schedules/list`,
    );
  }

  async createFreeOfChargeSchedules(
    chargingStationId: string,
    freeOfChargeSchedule: FreeOfChargeSchedule,
  ): Promise<ApiResponse> {
    return await this.POST(
      `${CS_RESOURCE_PATH}/${chargingStationId}/settings/free-of-charge-schedules`,
      freeOfChargeSchedule,
    );
  }
  
  async deleteFreeOfChargeSchedules(
    chargingStationId: string,
    free_of_charge_schedule_id: string,
  ): Promise<ApiResponse> {
    return await this.DELETE(
      `${CS_RESOURCE_PATH}/${chargingStationId}/settings/free-of-charge-schedules/${free_of_charge_schedule_id}`,
    );
  }
}

import { ChargingStationType } from 'src/store/model';

export const getChargingStationType = (
  uid: string | undefined | null,
): ChargingStationType => {
  if (!uid) {
    return ChargingStationType.UNIVERSAL;
  }

  let chargingStationType = ChargingStationType.GARO_CHARGING_STATION;
  if (uid.includes(ChargingStationType.GARO_CHARGING_STATION)) {
    chargingStationType = ChargingStationType.GARO_CHARGING_STATION;
  } else if (uid.includes(ChargingStationType.GARO_LOAD_INTERFACE)) {
    chargingStationType = ChargingStationType.GARO_LOAD_INTERFACE;
  } else {
    chargingStationType = ChargingStationType.UNIVERSAL;
  }

  return chargingStationType;
};

import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Avatar,
  Typography,
  Checkbox,
  Button
} from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  ChargingStationConnectionGroup,
  ChargingStationConnectionStatus,
  ChargingStationSearchItem,
  ConnectorStatus
} from 'src/store/model';
import { NavigateFunction } from 'react-router-dom';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudIcon from '@mui/icons-material/Cloud';
import styles from './row.module.scss';
import { BasicPopover } from 'src/components';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { useTranslation } from 'react-i18next';
type Props = {
  chargingStationSearchItem: ChargingStationSearchItem | undefined;
  connectionGroups: ChargingStationConnectionGroup[];
  isItemSelected: boolean;
  setCurrentChargingStationId: (chargingStationId: string) => void;
  getChargingStationSearchItemFromId: (
    id: string
  ) => ChargingStationSearchItem | undefined;
  getLocationNameFromId: (id: string) => string | null | undefined;
  select: (chargingStationId: string) => void;
  navigate: NavigateFunction;
  persistPagination: any;
};

export const Row = ({
  chargingStationSearchItem,
  connectionGroups,
  isItemSelected,
  setCurrentChargingStationId,
  getChargingStationSearchItemFromId,
  getLocationNameFromId,
  persistPagination,
  select,
  navigate
}: Props) => {

  const [open, setOpen] = useState(false);

  const isInGroup =
    connectionGroups.some(
      (group) =>
        group.charging_station_id ===
        chargingStationSearchItem?.charging_station?.id
    ) &&
    !!connectionGroups.find(
      (group) =>
        group.charging_station_id ===
        chargingStationSearchItem?.charging_station?.id
    )?.items?.length;

  const TableRowCustom = styled(TableRow)`
   &:last-child {
     .MuiTableCell-root {
      border-bottom: 0;
    }
`;

  const gotoChargingStationView = () => {
    setCurrentChargingStationId(
      chargingStationSearchItem?.charging_station?.id ?? ''
    );
    navigate(
      `/service-tech/charging-stations/${chargingStationSearchItem?.charging_station?.id}`
    );
    // persistPagination();
  }

  const gotoLocationView = () => {
    setCurrentChargingStationId(
      chargingStationSearchItem?.charging_station?.id ?? ''
    );
    const id = chargingStationSearchItem?.charging_station?.location_id;
    navigate(
      `/service-tech/locations/${id}`
    );
  }

  return (
    <React.Fragment>
      <TableRowCustom>
        <TableCell padding="checkbox">
          {!isInGroup && (
            <Checkbox
              color="primary"
              disabled={chargingStationSearchItem?.charging_station?.status?.connection !== ChargingStationConnectionStatus.connected}
              checked={isItemSelected}
              onClick={(event) => {
                event.stopPropagation();
                select(chargingStationSearchItem?.charging_station?.id ?? '');
              }}
              inputProps={{
                'aria-labelledby':
                  chargingStationSearchItem?.charging_station?.id ?? ''
              }}
            />
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setOpen(!open);
            }}
          >
            {isInGroup ? (
              open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            ) : null}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Avatar
            aria-label="recipe"
            className={'avatar'}
            src={
              chargingStationSearchItem?.charging_station?.load_interface
                ? require('../../../../images/load_interface.png')
                : require('../../../../images/charging_station.png')
            }
          ></Avatar>
        </TableCell>
        <TableCell align="left">
        {chargingStationSearchItem?.charging_station?.charging_unit?.firmware_version === null ? 
          '---' : chargingStationSearchItem?.charging_station?.charging_unit?.firmware_version}
        </TableCell>
        <TableCell align="center">
          <Button
            style={{ width: 150, textTransform: 'none' }}
            size="small"
            color="primary"
            onClick={gotoChargingStationView}
          >
            {chargingStationSearchItem?.charging_station?.name}
          </Button>
        </TableCell>
        <TableCell align="center">
          {chargingStationSearchItem?.location_name && (
            <Button
                style={{ width: 150, textTransform: 'none' }}
                size="small"
                color="primary"
                onClick={gotoLocationView}
              >
              {chargingStationSearchItem?.location_name}
            </Button>
          )}
        </TableCell>
        <OwnersInstallersPopover
          emails={chargingStationSearchItem?.owner_emails ?? []}
          isOwners={true}
        />
        <OwnersInstallersPopover
          emails={chargingStationSearchItem?.installer_emails ?? []}
          isOwners={false}
        />
        <TableCell align="left">
          {chargingStationSearchItem?.charging_station?.uid}
        </TableCell>
        {/* <TableCell align="center">
          {isInGroup && <div className="icon mesh-master" />}
        </TableCell> */}
        <TableCell align="center">
        {chargingStationSearchItem?.charging_station?.status?.connection ===
          ChargingStationConnectionStatus.connected ? (
            chargingStationSearchItem?.charging_station?.connectors_statuses?.find(c => c.connector_id === 0)?.status ===
            ConnectorStatus.Available ? (
               <div style={{width: 10, height: 10, borderRadius: 5, backgroundColor: 'green', marginLeft: 'auto', marginRight: 'auto'}} />
             ) : (
               <div style={{width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginLeft: 'auto', marginRight: 'auto'}} />
             )
          ) : (
            <div style={{width: 10, height: 10, borderRadius: 5, backgroundColor: 'grey', marginLeft: 'auto', marginRight: 'auto'}} />
          )}
        </TableCell>
        <ConnectionDetails online={chargingStationSearchItem?.charging_station?.status?.connection ===
          ChargingStationConnectionStatus.connected}
          usingProxy={!!chargingStationSearchItem?.charging_station?.status?.using_proxy} />
      </TableRowCustom>
      {isInGroup && (
        <TableRowCustom>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: '1rem', width: '100%' }}>
                <Table size="small">
                  <TableBody>
                    {connectionGroups
                      .find(
                        (group) =>
                          group.charging_station_id ===
                          chargingStationSearchItem?.charging_station?.id
                      )
                      ?.items?.map((id) =>
                        getChargingStationSearchItemFromId(id)
                      )
                      .map((searchItem) => (
                        <Row
                          key={searchItem?.charging_station?.id + '-in-group'}
                          chargingStationSearchItem={searchItem}
                          connectionGroups={connectionGroups}
                          isItemSelected={isItemSelected}
                          setCurrentChargingStationId={
                            setCurrentChargingStationId
                          }
                          select={() => null}
                          navigate={navigate}
                          getChargingStationSearchItemFromId={
                            getChargingStationSearchItemFromId
                          }
                          getLocationNameFromId={getLocationNameFromId}
                          persistPagination={persistPagination}
                        />
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRowCustom>
      )}
    </React.Fragment>
  );
};

function OwnersInstallersPopover({
  emails,
  isOwners
}: {
  emails: readonly string[];
  isOwners?: boolean;
}) {
  return (
    <TableCell align={'center'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <BasicPopover
          buttonContent={
            <div
              className={isOwners ? styles.ownerIcon : styles.installerIcon}
            />
          }
          popOverContent={emails.map((email) => (
            <Typography
              style={{
                margin: '5px 20px'
              }}
              key={email}
            >
              {email}
            </Typography>
          ))}
        />
        <span>({emails.length})</span>
      </div>
    </TableCell>
  );
}

function ConnectionDetails({
online,
usingProxy
}: {
  online: boolean;
  usingProxy: boolean;
}) {


  const { t } = useTranslation();

  return (
    <TableCell align="center">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <BasicPopover
          buttonContent={
            online ? (
              usingProxy ? (<DeviceHubIcon color="action" />) :
              (<CloudIcon color="action" />)
            ) : (
              <CloudOffIcon color="action" />
            )
          }
          popOverContent={ (
            <Typography
              style={{
                margin: '5px 20px'
              }}
            >
              {
              usingProxy ? t('Using Proxy') : (online ? t('Online') : t('Offline'))
              }
            </Typography>
          )}
        />
      </div>
  </TableCell>
  );
}
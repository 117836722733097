import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, TabPanel } from 'src/components';
import { useStores } from 'src/store';
import { Rfid, User, UserNotificationSettings } from 'src/store/model';
import globalStyles from '../../../global-styles.module.scss';
import { UserGeneral } from './components/user-general';
import { UserRfidKeys } from './components/user-rfid-keys';

export const UserDetails = () => {
  const { userStore } = useStores();

  const { id } = useParams<{ id: string }>();
  const [selectedUser, setSelectedUser] = useState<User | undefined>({
    ...userStore.userSearchResult.find(
      (user) => user.id === id)
  });

  const [userNotifications, setUserNotifications] = useState<
    UserNotificationSettings | undefined
  >(undefined);

  const [rfIdKeys, setRfIdKeys] = useState<Rfid[]>([]);

  const createRfIdKey = useCallback(
    (rfIdKey: Rfid) => {
      userStore
        .createRfIDKeyForUser(id ?? '', rfIdKey)
        .then((k) => setRfIdKeys(prev => {
          if (k) {
            return [...prev, k];
          } else {
            return prev;
          }
        }));
    },
    [id, userStore]
  );

  const updateRfIdKey = useCallback(
    (rfIdKey: Rfid) => {
      userStore
        .updateRfIDKeyForUser(id ?? '', rfIdKey)
        .then((k) => setRfIdKeys(prev => {
          if (k) {
            prev = prev.map(p => p.id !== k.id ? p : k);
          }
          return prev;
        }));
    },
    [id, userStore]
  );

  const removeRfIdKey = useCallback(
    (id: string) => {
      userStore.removeRfIDKeyForUser(id).then(() => {
        setRfIdKeys((rfIdKeys ?? [])?.filter((rfIdKey) => rfIdKey.id !== id));
      });
    },
    [rfIdKeys, userStore]
  );

  const updateUser = useCallback(
    (user: User) => {
      userStore.updateUser(user).then((u) => setSelectedUser(u));
    },
    [userStore]
  );

  useEffect(() => {
    const fetchUserNotifications = async () => {
      const userNotifications = await userStore.getUserNotificationSettings(
        id ?? ''
      );
      setUserNotifications(userNotifications);
    };

    const fetchRfIdKeys = async () => {
      const rfIdKeys = await userStore.getRfIDKeysForUser(id ?? '');
      setRfIdKeys(rfIdKeys ?? []);
    };

    fetchRfIdKeys();
    fetchUserNotifications();
  }, [id, userStore]);

  const getColor = useMemo(() => {
    let hash = 0;
    const id = selectedUser?.id ?? '1';
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }, [selectedUser?.id]);

  const handleTabChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`
    };
  };

  const [value, setValue] = useState(0);
  return (
    <div className={globalStyles.container} style={{ marginTop: '2rem' }}>
      <Card style={{ minHeight: 650 }}>
        <CardHeader
          avatar={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className={'avatar'}
                sx={{
                  bgcolor: getColor
                }}
              >{`${selectedUser?.first_name
                ?.charAt(0)
                .toUpperCase()}${selectedUser?.last_name
                  ?.charAt(0)
                  .toUpperCase()}`}</Avatar>
            </div>
          }
          title={`${selectedUser?.first_name} ${selectedUser?.last_name}`}
          titleTypographyProps={{ variant: 'h5' }}
          subheader={selectedUser?.email}
        />
        <CardContent style={{ minHeight: 650 }}>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: 'background.paper',
              display: 'flex'
            }}
          >
            <Tabs
              orientation="vertical"
              style={{ width: '15rem' }}
              value={value}
              onChange={handleTabChange}
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="RFID Tags" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              {userNotifications ? (
                <UserGeneral
                  user={selectedUser}
                  notifications={userNotifications}
                  updateUser={updateUser}
                  updateNotifications={async (notifications) => {
                    const updatedNotifications =
                      await userStore.updateUserNotificationSettings(
                        selectedUser?.id ?? '',
                        notifications
                      );
                    setUserNotifications(updatedNotifications);
                  }}
                />
              ) : <Loader />}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UserRfidKeys removeRfIdKey={removeRfIdKey} rfIdKeys={rfIdKeys} createRfIdKey={createRfIdKey} updateRfIdKey={updateRfIdKey} />
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

import { TableRow, TableCell, Avatar } from '@mui/material';
import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { User } from 'src/store/model';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  user: User | undefined;
  navigate: NavigateFunction;
  persistPagination: any;
};

export const Row = ({ user, navigate, persistPagination }: Props) => {
  const TableRowCustom = styled(TableRow)`
   &:last-child {
     .MuiTableCell-root {
      border-bottom: 0;
    }
`;

  const getColor = useMemo(() => {
    
    let hash = 0;
    const id = user?.id ?? '1';
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }, [user]);

  return (
    <>
      <TableRowCustom
        hover
        onClick={() => {
          navigate(`/service-tech/users/${user?.id}`);
          persistPagination();
        }}
      >
        <TableCell component="th" scope="row">
          <Avatar
            className={'avatar'}
            sx={{
              bgcolor: getColor
            }}
          >{`${user?.first_name?.charAt(0).toUpperCase()}${user?.last_name
            ?.charAt(0)
            .toUpperCase()}`}
            </Avatar>
        </TableCell>
        <TableCell align="left">{user?.first_name}</TableCell>
        <TableCell align="left">{user?.last_name}</TableCell>
        <TableCell align="left">{user?.email}</TableCell>
      </TableRowCustom>
    </>
  );
};

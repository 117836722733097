import {
  Avatar,
  CardHeader,
  Link,
  Typography
} from '@mui/material';

import React, { useEffect, useState, useCallback } from 'react';
import {
  ChargingStationConnectionStatus,
} from 'src/store/model';
import { useStores } from 'src/store';

import CloudOffIcon from '@mui/icons-material/CloudOff';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ChargingStationStatusHeader = () => {

  const { chargingStationStore } = useStores();
  const navigate = useNavigate();
  const [connectorStatus, setConnectorStatus] = useState('');
  const { t } = useTranslation();

  const getConnectorStatus = useCallback(() => {
    if (!chargingStationStore.currentChargingStation || !chargingStationStore.currentChargingStation.connectors_statuses) {
      return '';
    }

    const statuses: string[] = [];

    chargingStationStore.currentChargingStation.connectors_statuses.forEach(row => {
      statuses[row.connector_id] = row.connector_id + ':' + row.status;
    });
    
    setConnectorStatus('' + statuses.join(', '));

  }, [chargingStationStore, setConnectorStatus]);

  useEffect(() => {
    getConnectorStatus();
  }, [chargingStationStore?.currentChargingStation?.connectors_statuses, getConnectorStatus]);

  const getLocationName = () => {
    const locationName = chargingStationStore.currentChargingStationLocationName;
    if (locationName) {
      return locationName;
    }
    return '';
  };

  const gotoLocationView = () => {
    const locationId = chargingStationStore.currentChargingStation?.location_id ?? '';
    console.log('gotoLocationView: locationId', locationId);
    navigate(`/service-tech/locations/${locationId}`);
  }

  return (
    <CardHeader
      avatar={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            aria-label="recipe"
            src={
              chargingStationStore.currentChargingStation?.load_interface
                ? require('../../../../images/load_interface.png')
                : require('../../../../images/charging_station.png')
            }
          ></Avatar>
          <div style={{ position: 'relative', marginLeft: -20, marginRight: 20, marginBottom: -20 }}>

            {chargingStationStore.currentChargingStationStatus?.connection ===
              ChargingStationConnectionStatus.disconnected && (
                <CloudOffIcon color="action" />
              )}

            {chargingStationStore.currentChargingStationStatus?.connection === ChargingStationConnectionStatus.connected && chargingStationStore.currentChargingStationStatus?.using_proxy && (<DeviceHubIcon color="action" />)}
          </div>
        </div>
      }
      title={`${chargingStationStore.currentChargingStation?.name} ${chargingStationStore.currentChargingStationStatus?.using_proxy ? '(Using CSMS proxy)' : ''} `}
      titleTypographyProps={{ variant: 'h5' }}
      subheader={
        <Typography variant="subtitle2">
          Location:
            <strong>
              <Link
                style={{ marginLeft: 5 }}
                component="button"
                underline="none"
                color="primary"
                onClick={gotoLocationView}
              >
                {getLocationName()}
              </Link>
            </strong>
          <br />
          {t('Current firmware')}: {chargingStationStore.currentChargingStation?.charging_unit?.firmware_version ?? 'Not found'}
          <br />
          {t('Serial code')}: {chargingStationStore.currentChargingStation?.uid ?? 'Not found'}
          <br />
          {connectorStatus}
        </Typography>
      }
    />
  );
};

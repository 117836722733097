import { SnapshotIn, types } from 'mobx-state-tree';

// Configuration variables constants
export const GARO_CONNECTOR_PHASES_VARIABLE_KEY = 'GaroConnectorPhases';
export const CONNECTOR_PHASE_ROTATION_VARIABLE_KEY = 'ConnectorPhaseRotation';
export const GARO_BRACKET_MAX_CURRENT = 'GaroBracketMaxCurrent';
export const GARO_MAX_CHARGING_CURRENT = 'GaroOwnerMaxCurrent';
export const GARO_CSMS_URL_KEY = 'GaroCsmsUrl';
export const GARO_REMOTE_CSMS_URL_KEY = 'GaroRemoteCsmsUrl';
export enum CONNECTION_GROUP_DEVICES_KEYS {
  GaroConnectionGroupDevices1 = 'GaroConnectionGroupDevices1',
  GaroConnectionGroupDevices2 = 'GaroConnectionGroupDevices2',
  GaroConnectionGroupDevices3 = 'GaroConnectionGroupDevices3',
  GaroConnectionGroupDevices4 = 'GaroConnectionGroupDevices4',
}
export const CONNECTION_GROUP_MAX_CURRENT_VARIABLE_KEY =
  'GaroConnectionGroupMaxCurrent';
export const CONNECTION_GROUP_MASTER_VARIABLE_KEY = 'GaroConnectionGroupMaster';

export const NUMBER_OF_CONNECTORS_VARIABLE_KEY = 'NumberOfConnectors';

export const GARO_CONNECTOR_MANUALLY_LOCKED = 'GaroConnectorManuallyLocked';

export const GARO_FIXED_CABLE = 'GaroFixedCableMaxCurrent';

export const GARO_FREE_CHARGE_TAG = 'GaroFreeChargeTag';

export enum ChargingStationModelType {
  entity = 'Entity',
  dc_charger = 'DCCharger',
  Undefined = 'Undefined',
  Other = 'Other',
}
export enum OCPPVersion {
  v1_6 = '1.6',
  v2_0 = '2.0',
  v2_0_1 = '2.0.1',
  Undefined = 'Undefined',
}

export enum ChargingStationConfigurationStatus {
  accepted = 'Accepted',
  rejected = 'Rejected',
  reboot_required = 'RebootRequired',
  not_supported = 'NotSupported',
  failed = 'Failed',
}

export const ChargingStationConfigurationVariable = types.model({
  key: types.identifier,
  value: types.frozen(),
});

export type ChargingStationConfigurationVariable = SnapshotIn<typeof ChargingStationConfigurationVariable>

export const ChargingStationConfigurations = types
  .model({
    charging_station_id: types.identifier,
    configuration_variables: types.map(ChargingStationConfigurationVariable),
  })
  .views((self) => ({
    get isFixedCable(): boolean {
      return !!self.configuration_variables?.get(GARO_FIXED_CABLE)?.value;
    },
    get isManuallyLocked(): boolean {
      return !!self.configuration_variables?.get(GARO_CONNECTOR_MANUALLY_LOCKED)
        ?.value;
    },
  }));

export type ChargingStationConfigurations = SnapshotIn<typeof ChargingStationConfigurations>

export type ChargingStationChangeConfigurations = {
  configuration_variables: ChargingStationConfigurationVariable[];
};

import { Button, Card, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import styles from './configurations.module.scss';
import { t } from "i18next";
import { Send } from "@mui/icons-material";
import { useStores } from "src/store";
import { ChargingStation } from "src/store/model";
import { Loader } from "../loader/loader";

type Props = {
  chargingStation: ChargingStation;
};

export const Configurations = ({ chargingStation }: Props) => {
  const { actionStore, chargingStationConfigurationsStore } = useStores();
  const [storeInCSMS, setStoreInCSMS] = useState(true);
  const [getKey, setGetKey] = useState('');
  const [getValue, setGetValue] = useState('');
  const [setKey, setSetKey] = useState('');
  const [setValue, setSetValue] = useState('');
  const [getError, setGetError] = useState(''); 
  const [setMessage, setSetMessage] = useState(''); 
  const [getLoading, setGetLoading] = useState(false);
  const [setLoading, setSetLoading] = useState(false);

  const setParameter = async () => {
    setSetMessage('');
    setSetLoading(true);
    const value = await actionStore.changeConfiguration(chargingStation.id ?? '', {
      configuration_variables: [{ key: setKey, value: setValue }],
    });
    const actionResult = JSON.parse(JSON.stringify(value.data));
    const actionMessage = actionResult.status[setKey];
    let storeMessage = '';
    if (actionMessage === 'Accepted' && storeInCSMS) {
      const storeValue = await chargingStationConfigurationsStore.updateOrCreateConfiguration(
        setKey,
        setValue
      );
      const storedResult = JSON.parse(JSON.stringify(storeValue));
      if (storedResult.status === 'Accepted') {
        storeMessage = ' and Stored in CSMS';
      }
    }
    setSetMessage(actionMessage + storeMessage);
    setSetLoading(false);
  };

  const getParameter = async () => {
    setGetError('');
    setGetValue('');
    setGetLoading(true);
    const { data, error } = await actionStore.getConfiguration(chargingStation.id || '', [getKey]);
    if (error) {
      setGetError(error.detail);
    } else if (data.unknown_key) {
      setGetError('Unknown key');
    } else {
      setGetValue(data.configuration_key[0].value);
    }
    setGetLoading(false);
  };

  return (
    <>
      <Card style={{marginBottom: 20}} className={styles.container}>
        <Typography color="primary">
          {t('Get Parameter Value')}
        </Typography>
        <Grid container spacing={2}
          sx={{ margin: 'auto', marginBottom: 2 }}>
          <Grid xs={5} sx={{ paddingRight: 2 }}>
            <TextField
              error={getError !== ''}
              style={{width: '100%'}}
              id="standard-name"
              label={t('Key')}
              variant="standard"
              value={getKey}
              onChange={(v) => setGetKey(v.target.value)}
            />
          </Grid>
          <Grid xs={4} sx={{ paddingRight: 2 }}>
            <TextField
              disabled
              style={{width: '100%'}}
              id="standard-name"
              label={t('Value')}
              variant="standard"
              value={getValue}
            />
          </Grid>
          <Grid container xs={3} justifyContent="flex-end">
            <Button 
              style={{ marginTop: 10, marginRight: 20, minWidth: 150 }}
              disabled={!getKey || getLoading}
              variant="outlined" 
              onClick={getParameter} 
              endIcon={!getLoading ? <Send /> : null}
            >{getLoading ? <Loader /> : t('Get')}</Button>
          </Grid>
        </Grid>
        { getError && (
          <Grid xs={12} style={{marginTop: 5}}>
            <span style={{color: "red"}}>{getError}</span>
          </Grid>
        )}
      </Card>
      <Card className={styles.container}>
        <Typography color="primary">
          {t('Set Parameter Value')}
        </Typography>
        <Grid container spacing={2}
          sx={{ margin: 'auto', marginBottom: 2 }}>
          <Grid xs={5} sx={{ paddingRight: 2 }}>
            <TextField
              error={setMessage === 'NotSupported'}
              style={{width: '100%'}}
              id="standard-name"
              label={t('Key')}
              variant="standard"
              value={setKey}
              onChange={(v) => setSetKey(v.target.value)}
            />
          </Grid>
          <Grid xs={4} sx={{ paddingRight: 2 }}>
            <TextField
              style={{width: '100%'}}
              id="standard-name"
              label={t('Value')}
              variant="standard"
              value={setValue}
              onChange={(v) => setSetValue(v.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between"
          sx={{ margin: 'auto', marginBottom: 2 }}>
            <FormControlLabel control={
              <Checkbox 
                checked={storeInCSMS}
                onChange={(event, checked) => setStoreInCSMS(checked)}
              />
            } label={t('Store in CSMS')} />
            <Button 
              style={{ marginRight: 20, minWidth: 150 }}
              disabled={!setKey || setLoading}
              variant="outlined" 
              onClick={setParameter} 
              endIcon={!setLoading ? <Send /> : null}
            >{setLoading ? <Loader /> : t('Set')}</Button>
        </Grid>
        { !storeInCSMS && (
          <Grid container spacing={2}
            sx={{ marginLeft: 1, marginBottom: 2 }}>
              <Typography variant="subtitle2" style={{backgroundColor: '#f7e8ba'}} >
                &nbsp;{t('Store warning')}!&nbsp;
              </Typography>
          </Grid>
        )}
        { setMessage && (
          <Grid xs={12} style={{marginTop: 5, marginLeft: 10}}>
            <span style={{color: setMessage === 'NotSupported' ? "red" : "green"}}>
              {setMessage}
            </span>
          </Grid>
        )}
      </Card>
    </>
  );
};
import { SnapshotIn, types } from 'mobx-state-tree';
import { ChargingStationConfigurationVariable } from './ChargingStationConfigurations';
import { ChargingStationConnectorStatus } from './ChargingStationConnectorStatus';
import { ChargingStationInstallation } from './ChargingStationInstallation';
import { ChargingStationSettings } from './ChargingStationSettings';
import { ChargingStationStatus } from './ChargingStationStatus';
import { ChargingUnit } from './ChargingUnit';

export enum ChargingStationType {
  GARO_CHARGING_STATION = 'GaroCS',
  GARO_LOAD_INTERFACE = 'GaroLI',
  UNIVERSAL = 'UniversalCS',
}


export const ChargingStation = types.model({
  id: types.maybeNull(types.string),
  uid: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  location_id: types.maybeNull(types.string),
  load_interface: types.maybeNull(types.boolean),
  status: types.maybeNull(ChargingStationStatus),
  installation: types.maybeNull(ChargingStationInstallation),
  charging_station_group_id: types.maybeNull(types.string),
  charging_unit: types.maybeNull(ChargingUnit),
  configuration: types.maybeNull(types.array(ChargingStationConfigurationVariable)),
  settings: types.maybeNull(ChargingStationSettings),
  connectors_statuses: types.maybeNull(types.array(ChargingStationConnectorStatus)),
});

export type ChargingStation = SnapshotIn<typeof ChargingStation>

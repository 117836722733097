import { SnapshotIn, types } from 'mobx-state-tree';

export enum ChargingStationInstallationSteps {
  physical_installation = 1,
  internet_and_cloud_connection = 2,
  firmware_updates = 3,
  configuration = 4,
  testing = 5,
}

export enum ChargingStationInstallationStatusEnum {
  Awaiting = 'Awaiting',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export const ChargingStationInstallationStatus = types.enumeration([
  ChargingStationInstallationStatusEnum.Awaiting,
  ChargingStationInstallationStatusEnum.InProgress,
  ChargingStationInstallationStatusEnum.Completed,
]);

export const ChargingStationInstallation = types
  .model({
    charging_station_id: types.identifier,
    progress: types.maybeNull(types.number),
    physical_installation: types.maybeNull(ChargingStationInstallationStatus),
    internet_and_cloud_connection: types.maybeNull(
      ChargingStationInstallationStatus,
    ),
    firmware_updates: types.maybeNull(ChargingStationInstallationStatus),
    configuration: types.maybeNull(ChargingStationInstallationStatus),
    testing: types.maybeNull(ChargingStationInstallationStatus),
    declared_as_finished: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get itemsFinished(): number {
      return Object.keys(self)
        .filter((key) =>
          Object.keys(ChargingStationInstallationSteps).includes(key),
        )
        .reduce((acc: number, property) => {
          if (
            (self as any)[property] ===
            ChargingStationInstallationStatusEnum.Completed
          ) {
            acc++;
          }
          return acc;
        }, 0);
    },
    get itemsStatusesArray() {
      return Object.keys(self)
        .filter((key) =>
          Object.keys(ChargingStationInstallationSteps).includes(key),
        )
        .reduce(
          (acc: any[], property) => [
            ...acc,
            { key: property, status: (self as any)[property] },
          ],
          [],
        );
    },

    get totalItems(): number {
      return Object.keys(ChargingStationInstallationSteps).length / 2;
    },
  }));

export interface ChargingStationInstallation
  extends SnapshotIn<typeof ChargingStationInstallation> {}

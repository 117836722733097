import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Box
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from 'src/store';
import { useTranslation } from 'react-i18next';

import {
  AvailableFirmware,
  ChargingStation,
  ChargingStationStatus,
} from 'src/store/model';
import styles from './charging-station-general-details.module.scss';
import { DropZone } from './drop-zone';
import { useInterval } from 'src/hooks';

type Props = {
  chargingStation: ChargingStation | undefined | null;
  chargingStationStatus: ChargingStationStatus | null | undefined;
  officialFirmwares: AvailableFirmware[];
  unofficialFirmwares: AvailableFirmware[];
  changeFirmware: (update_type: string, location: string, signing_certificate: string, signature: string) => void;
};

export const ChargingStationGeneralDetails = ({
  chargingStation,
  chargingStationStatus,
  officialFirmwares,
  unofficialFirmwares,
  changeFirmware,
}: Props) => {

  const [selectedOfficialFirmware, setSelectedOfficialFirmware] = useState('');
  const [selectedUnofficialFirmware, setSelectedUnofficialFirmware] = useState('');
  const [customFirmwareUrl, setCustomFirmwareUrl] = useState('');
  const [customFirmwareCertificate, setCustomFirmwareCertificate] = useState('');
  const [customFirmwareSignature, setCustomFirmwareSignature] = useState('');
  const [firmwareUpdateStatus, setFirmwareUpdateStatus] = useState(chargingStationStatus?.firmware_update ?? '');
  const [signedFirmwareUpdate, setSignedFirmwareUpdate] = useState<boolean>(true);

  const handleOfficialFWChange = (event: any) => {
    setSelectedOfficialFirmware(event.target.value);
  };

  const handleUnofficialFWChange = (event: any) => {
    setSelectedUnofficialFirmware(event.target.value);
  };

  const {
    chargingStationStore,
    actionStore
  } = useStores();

  const { t } = useTranslation();

  const checkFWVersion = (fw: string) => {
    const versionArray = fw.split('.');
    const compareVersionArray = "2.0.0".split('.');
    let isFWAfter = false;
    for (let i = 0; i < versionArray.length; i++) {
      if (parseInt(versionArray[i]) >= parseInt(compareVersionArray[i])) {
        isFWAfter = true; break;
      } else if (parseInt(versionArray[i]) < parseInt(compareVersionArray[i])) {
        isFWAfter = false; break;
      }
    }
    return isFWAfter;
  }

  const hasCertSign = (fw: AvailableFirmware) => {
    return fw.signing_certificate && fw.signature;
  }

  const supportedFirmwares = useMemo(() => {
    return officialFirmwares.filter((fw) => {
      return signedFirmwareUpdate ? checkFWVersion(fw.version ?? '') && hasCertSign(fw) : true;
    });
  }, [officialFirmwares, signedFirmwareUpdate]);

  const supportedUnofficialFirmwares = useMemo(() => {
    return unofficialFirmwares.filter((fw) => {
      return signedFirmwareUpdate ? checkFWVersion(fw.version ?? '') && hasCertSign(fw) : true;
    });
  }, [unofficialFirmwares, signedFirmwareUpdate]);

  useEffect(() => {
    setSignedFirmwareUpdate(checkFWVersion(chargingStation?.charging_unit?.firmware_version ?? ''));
  }, [chargingStation?.charging_unit?.firmware_version]);

  const updateStatus = () => {
    setFirmwareUpdateStatus('Loading...');
    actionStore.triggerMessageFirmwareStatusNotification(chargingStation?.id ?? '');
  }
  
  useInterval(() => {
    console.log('useEffect');
    chargingStationStore.getFirmwareUpdateStatus(chargingStation?.id ?? '').then(res => {
      setFirmwareUpdateStatus(res.data?.status?.firmware_update ?? 'Not found');
    });
  }, 2500);

  const handleSignedFirmwareUpdate = (e: any) => {
    setSignedFirmwareUpdate(e.target.checked);
  }

  const handleCustomFirmwareChange = (e: any): void => {
    setCustomFirmwareUrl(e.target.value);
  }

  const handleFileChosen = (file: any) => {
    console.log(file);
    const reader = new FileReader();

    reader.onload = (event) => {
      const pemFile = event.target?.result as string;

      if (pemFile) {
        const pemFileParts = pemFile.split('-----BEGIN CERTIFICATE-----');

        if (pemFileParts.length > 1) {
          const certificateParts = pemFileParts[1].split('-----END CERTIFICATE-----');
          setCustomFirmwareCertificate('-----BEGIN CERTIFICATE-----' + certificateParts[0] + '-----END CERTIFICATE-----');

          const signatureParts = certificateParts[1].split('-----BEGIN RSA SIGNATURE-----');
          const signature = signatureParts ? signatureParts[1].split('-----END RSA SIGNATURE-----') : '';
          setCustomFirmwareSignature(signature[0]);
        } else {
          console.log('The file does not contain a valid PEM certificate and signature.');
          setCustomFirmwareCertificate('The file does not contain a valid PEM certificate and signature.');
        }
      }
    };

    reader.readAsText(file);
  };

  const handleOfficialFWUpdate = () => {
    const fw = supportedFirmwares.find((fw) => fw.location === selectedOfficialFirmware);
    changeFirmware(signedFirmwareUpdate ? 'Secure' : 'Standard', selectedOfficialFirmware, fw?.signing_certificate ?? '', fw?.signature ?? '');
  };

  const handleUnofficialFWUpdate = () => {
    const fw = supportedUnofficialFirmwares.find((fw) => fw.location === selectedUnofficialFirmware);
    changeFirmware(signedFirmwareUpdate ? 'Secure' : 'Standard', selectedUnofficialFirmware, fw?.signing_certificate ?? '', fw?.signature ?? '');
  }

  return (
    <div className={styles.chargingStationGeneralDetails}>
      <FormControlLabel control={
        <Checkbox 
          checked={signedFirmwareUpdate}
          onChange={handleSignedFirmwareUpdate}/>
        } label={t('Signed Firmware Update')} />
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControl style={{ width: 320 }} variant="standard">
          <InputLabel id="select-label">
            {t('Official firmware version:')}
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            fullWidth={true}
            value={selectedOfficialFirmware}
            onChange={handleOfficialFWChange}>
            {supportedFirmwares.map((fw) => {
              return (
              <MenuItem key={fw.key} value={fw.location ?? ''}>
                {`${fw.model} ${fw.version}`}
              </MenuItem>
            )})}
          </Select>
        </FormControl>
        <Button
          style={{ marginLeft: '1rem' }}
          disabled={!selectedOfficialFirmware}
          size="medium"
          onClick={handleOfficialFWUpdate}>
            {t('Change firmware')}
        </Button>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControl style={{ width: 320 }} variant="standard">
          <InputLabel id="select-label">
            {t('Unofficial firmware version:')}
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            fullWidth={true}
            value={selectedUnofficialFirmware}
            onChange={handleUnofficialFWChange}>
            {supportedUnofficialFirmwares.map((fw) => (
              <MenuItem key={fw.key} value={fw.location ?? ''}>
                {`${fw.model} ${fw.version}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          style={{ marginLeft: '1rem' }}
          disabled={!selectedUnofficialFirmware}
          size="medium"
          onClick={handleUnofficialFWUpdate}>
            {t('Change firmware')}
        </Button>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <TextField
          className={styles.field}
          id="standard-name"
          label={t('Custom firmware URL')}
          variant="standard"
          value={customFirmwareUrl}
          onChange={handleCustomFirmwareChange}
        />
        <Button
          style={{ marginLeft: '1rem' }}
          disabled={!customFirmwareUrl}
          size="medium"
          onClick={() => {
            changeFirmware(signedFirmwareUpdate ? 'Secure' : 'Standard', customFirmwareUrl, customFirmwareCertificate, customFirmwareSignature);
          }}>
          {t('Change firmware')}
        </Button>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <TextField
          inputProps={{style: {fontSize: 8}}}
          className={styles.field}
          disabled={!signedFirmwareUpdate}
          multiline
          rows={4}
          id="standard-name"
          label={t('Certificate')}
          variant="standard"
          value={signedFirmwareUpdate ? customFirmwareCertificate : ''}
        />
        <Box style={{marginLeft: '1.5rem'}}>
          <DropZone onChange={(file: File) => handleFileChosen(file)} />
        </Box>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <TextField
          inputProps={{style: {fontSize: 7}}}
          className={styles.field}
          disabled={!signedFirmwareUpdate}
          multiline
          rows={4}
          id="standard-name"
          label={t('Signature')}
          variant="standard"
          value={signedFirmwareUpdate ? customFirmwareSignature : ''}
        />
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <div style={{width: 320}}>Firmware update status: <b>{firmwareUpdateStatus}</b></div>
        <Button
          style={{ width: 150 }}
          size="medium"
          color="primary"
          onClick={updateStatus}>
          {t('Update status')}
        </Button>
      </div>
    </div>
  );
};

import { SnapshotIn, types } from 'mobx-state-tree';

export const AvailableFirmware = types.model({
  key: types.identifier,
  model: types.maybeNull(types.string),
  vendor: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  minimum_version: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  signing_certificate: types.maybeNull(types.string),
  signature: types.maybeNull(types.string),
});

export type AvailableFirmware = SnapshotIn<typeof AvailableFirmware>

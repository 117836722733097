import { SnapshotIn, types } from "mobx-state-tree";
import { InstallationCode } from "./InstallationCode";

export const InstallationJob = types.model({
    id: types.string,
    info: types.maybeNull(types.string),
    location_id: types.maybeNull(types.string),
    installation_code: types.maybeNull(InstallationCode),
});

export type InstallationJob = SnapshotIn<typeof InstallationJob>
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStores } from 'src/store';
import { useCurrentChargingStation, useInterval } from 'src/hooks';
import { TabPanel } from '../../../components/tab-panel';
import { DetailsTabs } from './components/details-tabs';
import { Details } from './components/details';
import { Groups } from './components/groups';
import { Configurations } from './components/configurations';
import { Access } from './components/access';
import { Actions } from './components/actions';
import { DataTransfer } from 'src/components/data-transfer/data-transfer';
import { LocationStatusHeader } from './components/location-status-header';
import { Card } from '@mui/material';
import globalStyles from '../../../global-styles.module.scss';
import { ChargingStation } from 'src/store/model';

export const Locations = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [value, setValue] = useState(0);
  const [locationChargingStations, setLocationChargingStations] = useState<ChargingStation[]>([]);

  const {
    chargingStationStore,
    locationStore,
    installationStore,
    chargingStationConfigurationsStore,
  } = useStores();

  useCurrentChargingStation(
    chargingStationStore,
    chargingStationConfigurationsStore,
    installationStore,
    locationStore,
  );

  useEffect(() => {
    chargingStationStore.clearLocationChargingStations();

    chargingStationStore.getChargingStationsForLocation('location_id', id, 250).then(() => {

      const chargingStationPromise = chargingStationStore.locationChargingStationSearchResult.map((ch) => {
        return chargingStationStore.getChargingStation(ch.charging_station?.id ?? '' );
      });

      Promise.all(chargingStationPromise).then(() => {
        const ch: ChargingStation[] = chargingStationStore.chargingStationSearchResult
          .filter(c => {
            return c.charging_station?.location_id === id;
          });
        setLocationChargingStations(ch);
      });
    });
  },[chargingStationStore, id]);

  return (
    <>
      <DetailsTabs setValue={(v) => {
        // Temporarily disable the "Access" and "Actions" tab
        if (v === 3 || v === 4) return;
        setValue(v);
      }} />
      <TabPanel value={value} index={0}>
        <Details chargingStations={locationChargingStations}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Groups chargingStations={locationChargingStations}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Configurations chargingStations={locationChargingStations}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Access />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Actions chargingStations={locationChargingStations}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Card className={globalStyles.container} style={{ paddingBottom: 25 }}>
          <LocationStatusHeader />
          <DataTransfer chargingStations={locationChargingStations} />
        </Card>
      </TabPanel>
    </>
  );
});

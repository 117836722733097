import { SnapshotIn, types } from 'mobx-state-tree';

export const Transaction = types
  .model({
    id: types.string,
    charging_station_id: types.maybeNull(types.string),
    charging_station_uid: types.maybeNull(types.string),
    connector_id: types.number,
    state: types.maybeNull(types.string),
    id_token: types.maybeNull(types.string),
    start_time: types.string,
    end_time: types.maybeNull(types.string),
    meter_start: types.maybeNull(types.number),
    meter_stop: types.maybeNull(types.number),
    ocpp_events: types.maybeNull(types.array(types.string)),
  })
  .views((self) => ({
    get startTime(): Date {
      return self.start_time ? new Date(self.start_time) : new Date(0);
    },
    get endTime(): Date {
      return self.end_time ? new Date(self.end_time) : new Date(0);
    },
  }));

export type Transaction = SnapshotIn<typeof Transaction>

import { useEffect, useState } from 'react';
import { MeterValue, MeterValueType } from 'src/store/model';

export const useLatestMeterValues = (
  meterValues: MeterValue[],
  type: MeterValueType,
): { phase: string; measure_value: number }[] => {
  const [latestMeterValues, setLatestMeterValues] = useState<
    { phase: string; measure_value: number }[]
  >([]);

  useEffect(() => {
    if (meterValues?.length) {
      const multipleValues = meterValues.filter((m) => m.measure_name === type);

      setLatestMeterValues(
        multipleValues
          .map((val) => ({
            phase: val.phase ?? '',
            measure_value: parseFloat(val?.measure_value || '0'),
          }))
          // no duplicates
          ?.filter(
            (cs, index, self) =>
              index === self.findIndex((t) => t?.phase === cs?.phase),
          )
          .sort((a, b) => (a.phase && b.phase && a.phase > b.phase ? 1 : -1)),
      );
    }
  }, [meterValues, type]);
  return latestMeterValues;
};

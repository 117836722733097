import { cast, flow, getEnv, getParent, types } from 'mobx-state-tree';
import {
  ChargingStationsApi,
  FirmwarePackagesApi,
  InstallationJobsApi,
} from 'src/api';
import { isNewSwVersion } from 'src/core';

import { RootModel } from '.';
import { AvailableFirmware, ChargingStationInstallation, InstallationJob } from './model';

export const InstallationStore = types
  .model({
    installationItems: types.map(ChargingStationInstallation), // @note not used?
    availableOfficialFirmware: types.map(AvailableFirmware),
    availableUnofficialFirmware: types.map(AvailableFirmware),
    installJobs: types.map(InstallationJob),
  })
  .volatile(() => ({
    isLoading: false,
  }))
  .views((self) => ({
    get firmwarePackagesApi(): FirmwarePackagesApi {
      return getEnv(self).firmwarePackagesApi;
    },
    get chargingStationsApi(): ChargingStationsApi {
      return getEnv(self).chargingStationsApi;
    },
    get installationJobsApi(): InstallationJobsApi {
      return getEnv(self).installationJobsApi;
    },
    get currentLocationId(): string {
      return getParent<typeof RootModel>(self).locationStore.currentLocationId;
    },
    get currentChargingStationId(): string {
      return getParent<typeof RootModel>(self).chargingStationStore
        .currentChargingStationId;
    },
    get currentChargingUnit(): any {
      return getParent<typeof RootModel>(self).chargingStationStore
        .currentChargingStation?.charging_unit;
    },
    get officialFirmwares(): AvailableFirmware[] {
      return Array.from(self.availableOfficialFirmware.values());
    },
    get unofficialFirmwares(): AvailableFirmware[] {
      return Array.from(self.availableUnofficialFirmware.values());
    },
    get latestFirmwareVersion() {
      return Array.from(self.availableOfficialFirmware.values())?.[0]?.version || '0';
    },
    get latestFirmwareVersionKey() {
      return Array.from(self.availableOfficialFirmware.values())?.[0]?.key;
    },
    getInstallationCode(locationId: string): string | undefined {
      const arr = Array.from(self.installJobs.values());
      const res = arr.find((job) => {
        return job.location_id === locationId;
      });
      return res?.installation_code?.id;
    },
    get newFirmwareAvailable() {
      return isNewSwVersion(
        this.latestFirmwareVersion || '0',
        this.currentChargingUnit?.firmware_version || '0',
      );
    },
  }))
  .actions((self) => {
    const markLoading = (loading: boolean) => {
      self.isLoading = loading;
    };

    // @note not used?
    const addInstallation = (installation: ChargingStationInstallation) => {
      if (installation?.charging_station_id) {
        self.installationItems.set(
          installation.charging_station_id,
          cast(installation),
        );
      }
    };

    // @note not used?
    const getCurrentInstallation = flow(function* () {
      if (self.currentChargingStationId) {
        const { data, error } =
          yield self.chargingStationsApi.getChargingStationInstallation(
            self.currentChargingStationId,
          );

        if (!error) {
          addInstallation(data);
        }
      }
    });

    // @note not used?
    const getInstallation = flow(function* (chargingStationId: string) {
      const { data, error } =
        yield self.chargingStationsApi.getChargingStationInstallation(
          chargingStationId,
        );

      if (!error) {
        addInstallation(data);
      }
    });

    const addAvailableOfficialFirmware = (firmware: AvailableFirmware) => {
      self.availableOfficialFirmware.set(firmware.key, cast(firmware));
    };

    const addAvailableUnofficialFirmware = (firmware: AvailableFirmware) => {
      self.availableUnofficialFirmware.set(firmware.key, cast(firmware));
    };

    const getAvailableOfficialFirmware = flow(function* () {
      const vendor = self.currentChargingUnit?.vendor_name || 'Garo';
      const model = self.currentChargingUnit?.model;
      const minimum_version = self.currentChargingUnit?.firmware_version;
      
      markLoading(true);
      const { data, error } =
        yield self.firmwarePackagesApi.getAvailableFirmwareVersions(
          vendor,
          model,
          minimum_version,
          true
        );

      if (!error) {
        self.availableOfficialFirmware.clear();
        data.forEach((fw: AvailableFirmware) => {
          addAvailableOfficialFirmware(fw);
        });
      }
      markLoading(false);
    });

    const getAvailableUnofficialFirmware = flow(function* () {
      const vendor = 'Garo_Unofficial';
      const model = self.currentChargingUnit?.model;
      const minimum_version = self.currentChargingUnit?.firmware_version;

      markLoading(true);
      const { data, error } =
        yield self.firmwarePackagesApi.getAvailableFirmwareVersions(
          vendor,
          model,
          minimum_version,
          true
        );

      if (!error) {
        self.availableUnofficialFirmware.clear();
        data.forEach((fw: AvailableFirmware) => {
          addAvailableUnofficialFirmware(fw);
        });
      }
      markLoading(false);
    });

    const addInstallationJob = (installationJob: InstallationJob) => {
      self.installJobs.set(installationJob.id, cast(installationJob));
    };

    const getInstallationJobs = flow(function* (locationId: string) {
      const { data, error } =
        yield self.installationJobsApi.getInstallationJobs(
          locationId,
          true,
        );

      if (!error) {
        addInstallationJob(data);
      }
    });

    return {
      getAvailableOfficialFirmware,
      getAvailableUnofficialFirmware,
      getInstallationJobs,
      getCurrentInstallation, // @note not used?
      getInstallation, // @note not used?
    };
  });

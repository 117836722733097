import React, { useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import styles from './charging-station-phase-rotation.module.scss';
type Props = {
  connectorPhaseRotation: string;
  bracketPhaseRotation: string;
  enabledPhases: number;
};

enum BracketRotation {
  RST = '0.RST',
  STR = '0.STR',
  TRS = '0.TRS'
}

enum ConnectorRotation {
  RST = '1.RST',
  STR = '1.STR',
  TRS = '1.TRS',
  SRT = '1.SRT'
}

const TN3PhaseRSTOrder = ['PE', 'L1', 'L2', 'L3', 'N'];

const rotationPairBracketRST_TN = TN3PhaseRSTOrder;
const rotationPairBracketSTR_TN = ['PE', 'L2', 'L3', 'L1', 'N'];
const rotationPairBracketTRS_TN = ['PE', 'L3', 'L1', 'L2', 'N'];

const rotationPairBracketRST_IT = ['PE', 'L2', 'L3', 'NC', 'L1'];
const rotationPairBracketSTR_IT = ['PE', 'L3', 'L1', 'NC', 'L2'];
const rotationPairBracketTRS_IT = ['PE', 'L1', 'L2', 'NC', 'L3'];

// Position of the labels are based on TN3 phase rotation. Equivalent position for other phase rotation can be found in the TN3PhaseRSTOrder object
const connectorTN3 = {
  [ConnectorRotation.RST]: ['PE', 'L1', 'L2', 'L3', 'N']
};

const connectorTN1 = {
  [ConnectorRotation.RST]: ['PE', 'L1', 'NC1', 'NC2', 'N'],
  [ConnectorRotation.STR]: ['PE', 'NC1', 'L1', 'NC2', 'N'],
  [ConnectorRotation.TRS]: ['PE', 'NC1', 'NC2', 'L1', 'N']
};

const connectorIT2 = {
  [ConnectorRotation.RST]: ['PE', 'L1', 'L2', 'NC', 'N']
};

const connectorIT1 = {
  [ConnectorRotation.RST]: ['PE', 'L1', 'NC1', 'NC2', 'N'],
  [ConnectorRotation.SRT]: ['PE', 'NC1', 'L1', 'NC2', 'N'],
  [ConnectorRotation.STR]: ['PE', 'N', 'L1', 'NC1', 'NC2']
};

export const ChargingStationPhaseRotation = ({
  connectorPhaseRotation,
  enabledPhases,
  bracketPhaseRotation
}: Props) => {
  const correctedEnabledPhases = useMemo(() => {
    switch (enabledPhases) {
      case 3:
      case 1:
      case -2:
      case -3:
        return enabledPhases;
      default:
        return 3;
    }
  }, [enabledPhases]);

  const connectorRotation = useMemo(() => {
    if (correctedEnabledPhases === 3) {
      return (
        (connectorTN3 as any)[connectorPhaseRotation] ??
        connectorTN3[ConnectorRotation.RST]
      );
    } else if (correctedEnabledPhases === 1) {
      return (
        (connectorTN1 as any)[connectorPhaseRotation] ??
        connectorTN3[ConnectorRotation.RST]
      );
    } else if (correctedEnabledPhases === -3) {
      return (
        (connectorIT2 as any)[connectorPhaseRotation] ??
        connectorTN3[ConnectorRotation.RST]
      );
    } else if (correctedEnabledPhases === -2) {
      return (
        (connectorIT1 as any)[connectorPhaseRotation] ??
        connectorTN3[ConnectorRotation.RST]
      );
    } else {
      return (connectorTN3 as any)[ConnectorRotation.RST];
    }
  }, [connectorPhaseRotation, correctedEnabledPhases]);

  const bracketRotation = useMemo(() => {
    switch (bracketPhaseRotation) {
      case BracketRotation.RST:
        return correctedEnabledPhases < 0
          ? rotationPairBracketRST_IT
          : rotationPairBracketRST_TN;
      case BracketRotation.STR:
        return correctedEnabledPhases < 0
          ? rotationPairBracketSTR_IT
          : rotationPairBracketSTR_TN;
      case BracketRotation.TRS:
        return correctedEnabledPhases < 0
          ? rotationPairBracketTRS_IT
          : rotationPairBracketTRS_TN;
      default:
        return correctedEnabledPhases < 0
          ? rotationPairBracketRST_IT
          : rotationPairBracketRST_TN;
    }
  }, [bracketPhaseRotation, correctedEnabledPhases]);

  const getXPosition = useCallback(
    (phase: string, bracket?: boolean) => {
      const distanceBetween = 98;
      const connectorIndex = TN3PhaseRSTOrder.indexOf(phase);
      const bracketIndex = connectorRotation?.indexOf(phase);

      const OFFSET = 28;

      if (!bracket || bracketIndex === -1 || bracketIndex === connectorIndex) {
        return connectorIndex * distanceBetween + OFFSET;
      } else {
        return bracketIndex * distanceBetween + OFFSET;
      }
    },
    [connectorRotation]
  );

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-around' }}>
          {TN3PhaseRSTOrder.map((item, index) => (
            <div key={`${item}-${index}`} className={styles.label}>
              {
                // enabled phases TN 3
              }
              <Typography
                style={{
                  color: item !== 'PE' ? 'green' : 'gray'
                }}
              >
                {item}
              </Typography>

              <ConnectionDot item={item} />
            </div>
          ))}
        </div>
        <svg className={styles.lineStyle}>
          {TN3PhaseRSTOrder.filter((label) =>
            connectorRotation.some(
              (validConnection: string) => label === validConnection
            )
          ).map((item, index) => (
            <polygon
              key={`${item}-${index}`}
              strokeWidth="2"
              stroke={
                item !== 'PE' ? 'green' : 'gray'
              }
              points={`${getXPosition(item)},0,${getXPosition(item, true)},30`}
            />
          ))}
        </svg>

        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-around' }}>
          {bracketRotation.map((item, index) => (
            <div key={`${item}-${index}`} className={styles.label}>
              <ConnectionDot item={item} />

              <Typography
                style={{
                  color: item !== 'PE' ? 'green' : 'gray'
                }}
              >
                {bracketRotation[index].includes('NC')
                  ? '-'
                  : bracketRotation[index]}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ConnectionDot = ({ item }: { item: string }) => {
  return (
    <div
      className={styles.phaseRotationConnector}
      style={{
        backgroundColor: item !== 'PE' ? 'green' : 'gray',
        opacity: item.includes('NC') ? 0 : 1
      }}
    />
  );
};
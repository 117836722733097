import styles from './navbar.module.scss';
import globalStyles from '../../global-styles.module.scss';
import { Card, IconButton, Modal } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStores } from 'src/store/RootStore';
import { SelectRegion } from '../select-region/select-region';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n/i18n';
import PersonIcon from '@mui/icons-material/Person';
import { theme } from 'src/theme';
import { MfaOptions } from '../mfa-options/mfa-options';

export const Navbar = observer(() => {
  const { userStore, /* sessionStore, */ chargingStationStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  const [openRegionModal, setOpenRegionModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const handleOpenModal = () => setOpenRegionModal(true);
  const handleCloseModal = () => setOpenRegionModal(false);
  const { t } = useTranslation();

  // check if width is mobile
  const isMobile = window.innerWidth <= 768;

  // strip: en-US
  const selectedLanguage = () => {
    return i18n.language.substring(0, 2);
  };

  return (
    <>
      <nav className={styles.navbar}>
        <div className={`${globalStyles.container} ${styles.innerContainer}`}>
          {location.pathname != '/service-tech/charging-stations' &&
            location.pathname != '/service-tech/users' &&
            location.pathname != '/landing' &&
            location.pathname != '/documentation' ? (
            <IconButton
              className={styles.backButton}
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </IconButton>
          ) : null}
          <div className={styles.brandContainer}>
            {(!isMobile || location.pathname === '/landing') && (
              <a
                href="http://corporate.garo.se/"
                rel="noreferrer"
                target="_blank">
                GARO Group
              </a>
            )}
            {/*sessionStore.region && */
              (!isMobile && (location.pathname === '/landing' || location.pathname === '/documentation')) && (
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={handleOpenModal}
                  rel="noreferrer"
                  target="_blank">
                  <div className={styles.globeIcon}></div>
                  {t(selectedLanguage())}
                </a>
              )}

            {userStore.isLoggedIn && (
              <>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenUserModal(true);
                  }}
                  rel="noreferrer"
                  target="_blank">
                  <PersonIcon />
                </a>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    chargingStationStore.resetPagination(); // @note quick fix
                    userStore.resetPagination(); // --||--
                    userStore.signOutUser().then(() => navigate('/landing'));
                  }}
                  rel="noreferrer"
                  target="_blank">
                  Log out
                </a>
              </>
            )}
          </div>
        </div>
      </nav>
      <Modal
        open={openRegionModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: 'auto' }}>
        <>
          <SelectRegion isModal={true} onClose={handleCloseModal} />
        </>
      </Modal>
      <Modal
        open={openUserModal}
        onClose={() => setOpenUserModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: 'auto' }}>
        <>
          <Card
            className={styles.userCard}
            style={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 0,
              position: 'relative',
            }}>
            <MfaOptions
              removeMfa={true}
              closeModal={() => {
                setOpenUserModal(false);
                navigate('/landing');
              }}
            />
          </Card>
        </>
      </Modal>
    </>
  );
});

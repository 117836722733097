import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'src/store';
import {
  countryCodeKeys,
  CountryCodes,
  LanguageCodes
} from 'src/store/SessionStore';
import { theme } from 'src/theme';
import styles from './select-region.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Card, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n/i18n';
type Props = {
  languangeSelector?: boolean;
  isModal?: boolean;
  onClose?: () => void;
};


export const SelectRegion = observer(
  ({ languangeSelector, isModal, onClose }: Props) => {
    const { sessionStore } = useStores();

    const handleChange = (event: {
      target: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
      sessionStore.setLanguageSameAsRegion(!!event.target.checked);
    };
  
    const { t } = useTranslation();
    
    return (
      <>
        <Card
          className={`${styles.regionCard} ${
            languangeSelector && styles.languangeCard
          }`}
          style={{
            backgroundColor: languangeSelector
              ? theme.palette.secondary.dark
              : theme.palette.secondary.main,
            borderRadius: 0,
            position: 'relative'
          }}
        >
          {isModal && (
            <IconButton
              onClick={onClose}
              style={{ position: 'absolute', top: 10, right: 10 }}
              aria-label="close"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}

          {languangeSelector ? (
            <h1 className={styles.regionTitle}>Select your language</h1>
          ) : (
            <>
              <div className={styles.logo} />

              <h1 className={styles.regionTitle}>Select your region</h1>
            </>
          )}

          <section className={styles.regionContainer}>
            {countryCodeKeys.map((countryCode) => (
              <CountryButton
                key={countryCode}
                countryCode={countryCode}
                languageSelector={languangeSelector}
                selected={
                  languangeSelector
                    ? i18n.language === countryCode.toLowerCase()
                    : sessionStore.region === countryCode
                }
                setRegion={(code) => {
                  if(sessionStore.languageSameAsRegion) {
                    sessionStore.setLanguage(code);
                    sessionStore.setRegion(code);
                  } else if(languangeSelector) {
                    sessionStore.setLanguage(code);
                  } else {
                    sessionStore.setRegion(code);
                  }
                }}
              />
            ))}
          </section>

          {!languangeSelector && (
            <FormControlLabel
              style={{ marginBottom: '1rem' }}
              control={
                <Checkbox
                  color="primary"
                  checked={sessionStore.languageSameAsRegion}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Language same as region' }}
                />
              }
              label={t("Language same as region")}
            />
          )}
        </Card>
        {!languangeSelector && !sessionStore.languageSameAsRegion && (
          <SelectRegion languangeSelector={true} />
        )}
      </>
    );
  }
);

const CountryButton = ({
  countryCode,
  languageSelector,
  setRegion,
  selected
}: {
  countryCode: string;
  languageSelector?: boolean;
  setRegion: (countryCode: string) => void;
  selected: boolean;
}) => {
  return (
    <button
      className={`${styles.region} ${selected ? styles.selected : undefined}`}
      onClick={() => setRegion(countryCode)}
    >
      <img src={require(`../../images/${countryCode}Flag.png`)}></img>
      {languageSelector ? (
        <span>
          {LanguageCodes[countryCode as keyof typeof LanguageCodes] ??
            LanguageCodes.UK}
        </span>
      ) : (
        <span>{CountryCodes[countryCode as keyof typeof CountryCodes]}</span>
      )}
    </button>
  );
};

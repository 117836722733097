import { SnapshotIn, types } from 'mobx-state-tree';

const Value = types.model({
  default: types.frozen(),
  type: types.enumeration([
    'boolean',
    'string',
    'number',
    'array',
    'integer',
    'undefined',
  ]),
  enum: types.maybeNull(types.array(types.string)),
  minimum: types.maybeNull(types.number),
  maximum: types.maybeNull(types.number),
  multipleOf: types.maybeNull(types.number),
});

export const JsonSchemaInnerProperty = types.model({
  value: Value,
});

export const JsonSchemaProperty = types.model({
  key: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  mutability: types.enumeration(['ReadWrite', 'ReadOnly', 'WriteOnly', 'None']),
  groups: types.array(types.string),
  properties: JsonSchemaInnerProperty,
  userrole: types.string,
});

export type JsonSchemaProperty = SnapshotIn<typeof JsonSchemaProperty>

export const ChargingStationSchema = types.model({
  $id: types.identifier,
  properties: types.map(JsonSchemaProperty),
});

export type ChargingStationSchema = SnapshotIn<typeof ChargingStationSchema>

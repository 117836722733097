
import { ApiResponse } from 'src/core';
import { Organization } from 'src/store/model';

import { CloudApi } from './cloud-api';

export const ORGANIZATIONS_RESOURCE_PATH = 'organizations';

export class OrganizationsApi extends CloudApi {
  async getOrganizations(context: string): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      context: context,
    });

    return await this.GET(`${ORGANIZATIONS_RESOURCE_PATH}${queryString}`);
  }

  async getOrganization(id: string): Promise<ApiResponse> {
    return await this.GET(`${ORGANIZATIONS_RESOURCE_PATH}/${id}`);
  }

  async getOrganizationAuthorizations(id: string): Promise<ApiResponse> {
    return await this.GET(
      `${ORGANIZATIONS_RESOURCE_PATH}/${id}/authorizations`,
    );
  }

  async addOrganizationAuthorizations(
    organizationId: string,
    authorization: any,
  ): Promise<ApiResponse> {
    return await this.POST(
      `${ORGANIZATIONS_RESOURCE_PATH}/${organizationId}/authorizations`,
      authorization,
    );
  }

  async removeOrganizationAuthorizations(
    id: string,
    authorizationId: string,
  ): Promise<ApiResponse> {
    return await this.DELETE(
      `${ORGANIZATIONS_RESOURCE_PATH}/${id}/authorizations/${authorizationId}`,
    );
  }

  async addOrganization(org: Organization): Promise<ApiResponse> {
    return await this.POST(`${ORGANIZATIONS_RESOURCE_PATH}`, {
      name: org.name,
      default: org.default,
    });
  }

  async updateOrganization(org: Organization): Promise<ApiResponse> {
    return await this.PUT(`${ORGANIZATIONS_RESOURCE_PATH}/${org.id}`, org);
  }

  async removeOrganization(id?: string): Promise<ApiResponse> {
    return await this.DELETE(`${ORGANIZATIONS_RESOURCE_PATH}/${id}`);
  }
}

import { SnapshotIn, types } from 'mobx-state-tree';


export enum BlockStatus {
  Blocked = 'Blocked',
  Unblocked = 'Unblocked',
}

export const BlocklistItem = types.model({
  id: types.identifier,
  type: types.maybeNull(types.string),
  status: types.maybeNull(types.enumeration('BlockStatus', Object.values(BlockStatus))),
  reason: types.maybeNull(types.string),
  blocklisted_since: types.maybeNull(types.string),
  locked_since: types.maybeNull(types.string),
});

export type BlocklistItem = SnapshotIn<typeof BlocklistItem>

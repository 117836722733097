import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import styles from './App.module.scss';

import { observer } from 'mobx-react-lite';
import { Navbar, SelectRegion } from './components';
import './i18n/i18n';
import { RootInstance, RootStoreProvider, useStores } from './store';
import { setupRootStore } from './store/SetupRootStore';
import { theme } from './theme';
import { Dashboard, Documentation, Login } from './views/landing';
import { ChargingStationDetails, TableTabs } from './views/service-technichian';
import { UserDetails } from './views/service-technichian/user-details/user-details';
import { NotFound } from './views/not-found/not-found';
import { Locations } from './views/service-technichian/location-details/locations';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const App = observer(() => {
  const [rootStore, setRootStore] = useState<RootInstance | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      setupRootStore().then(setRootStore);
    })();
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <RootStoreProvider value={rootStore}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div
            className={`${styles.app} ${!rootStore.userStore.isLoggedIn ? styles.landing : undefined
              }`}
          >
            <Navbar />
            <Routes>
              <Route path="/landing" element={<Landing />} />
              <Route
                path="/documentation"
                element={<DocumentationRoute />}
              />
              <Route element={<PrivateRoute />}>
                <Route
                  path="/service-tech/charging-stations"
                  element={<TableTabs />}
                />
                <Route
                  path="/service-tech/charging-stations/:id"
                  element={<ChargingStationDetails />}
                />
                <Route path="/service-tech/users" element={<TableTabs />} />
                <Route
                  path="/service-tech/users/:id"
                  element={<UserDetails />}
                />
                <Route
                  path="/service-tech/locations/:id"
                  element={<Locations />}
                />
              </Route>
              <Route
                path="/service-tech"
                element={<Navigate to="/service-tech/charging-stations" />}
              />
              <Route path="/" element={<Navigate to="/landing" />} />
              <Route path="/installer-support" element={<Navigate to="/landing" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </RootStoreProvider>
  );
});

const Landing = observer(() => {
  const { sessionStore, userStore } = useStores();
  if (sessionStore.region) {
    return (
      <div className={styles.dashboardContainer}>
        {!userStore.needToEnableMfa && (<Dashboard />)} <Login />
      </div>
    );
  } else {
    return <SelectRegion />;
  }
});

const DocumentationRoute = observer(() => {
  const { sessionStore } = useStores();
  if (sessionStore.region) {
    return (
      <div className={styles.dashboardContainer}>
        <Documentation />
      </div>
    );
  } else {
    return <SelectRegion />;
  }
});

const PrivateRoute = () => {
  const location = useLocation();
  const { userStore } = useStores();
  if (!userStore.isLoggedIn) {
    return <Navigate to="/landing" state={{ from: location }} />;
  }
  return <Outlet />;
};

export default App;

import {
  Avatar,
  Button, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Rfid } from 'src/store/model';
import globalStyles from '../../../../global-styles.module.scss';
import { DeleteRfid } from './delete-rfid';
import { NewRfid } from './new-rfid';
import { format, parseISO } from 'date-fns'
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/EditRounded';

type Props = {
  rfIdKeys: Rfid[] | undefined;
  removeRfIdKey: (id: string) => void;
  createRfIdKey: (rfIdKey: Rfid) => void;
  updateRfIdKey: (rfIdKey: Rfid) => void;
};

export const UserRfidKeys = ({
  rfIdKeys,
  removeRfIdKey,
  createRfIdKey,
  updateRfIdKey
}: Props) => {

  const [selectedRfId, setSelectedRfId] = useState<Rfid | undefined>(undefined);
  const [newOpen, setNewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <TableContainer
      className={globalStyles.container}
      style={{ width: 'unset' }}
      component={Paper}
    >
      <Button
        style={{
          left: '15%',
          transform: 'translateX(-50%)',
          marginTop: '10px',
          marginBottom: '10px'
        }}
        size="medium"
        variant="contained"
        color="primary"
        type="submit"
        onClick={() => { setNewOpen(true) }}
      >
        {t('Add RFID tag')}
      </Button>
      <DeleteRfid
        open={deleteOpen}
        rfId={selectedRfId}
        closeEdit={() => {
          setDeleteOpen(false);
          setSelectedRfId(undefined);
        }}
        deleteRfId={removeRfIdKey}
      />
      <NewRfid
        open={newOpen}
        rfId={selectedRfId}
        closeEdit={() => {
          setNewOpen(false)
          setSelectedRfId(undefined)
        }}
        create={(
          id_token,
          name,
          expiration_timestamp,
        ) => createRfIdKey({ id_token, name, expiration_timestamp, type: 'ISO14443' } as any)
        }
        update={(
          id,
          id_token,
          name,
          expiration_timestamp,
        ) => updateRfIdKey({ id, id_token, name, expiration_timestamp, type: 'ISO14443' } as any)
        }
      />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Key</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Expiration date</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rfIdKeys?.map((row) => (
            <TableRow
              key={row.id_token} 
            >
              <TableCell component="th" scope="row">
                <Avatar
                  aria-label="recipe"
                  className={'avatar'}
                  imgProps={{ style: { objectFit: 'contain' } }}
                  src={require('../../../../images/GARO_RFID_tag.png')}
                ></Avatar>
              </TableCell>
              <TableCell>{row.id_token}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{(row.expiration_timestamp) ? format(parseISO(row.expiration_timestamp), 'yyyy-MM-dd HH:ii') : ''}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setSelectedRfId(row)
                    setNewOpen(true)
                  }}
                ><EditIcon color='action'></EditIcon>
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setSelectedRfId(row);
                    setDeleteOpen(true);
                  }}
                ><DeleteIcon color='action'></DeleteIcon>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import { cast, flow, getEnv, types } from 'mobx-state-tree';

import { OrganizationsApi, RfidTokenApi, UsersApi } from '../api';
import {
  changePassword,
  confirmSignIn,
  currentAuthenticatedUser,
  getTOTPQRCode,
  requestAuth,
  signOut,
  signUp,
  turnOffTOTP,
  turnOnTOTP,
} from '../core/aws-auth';
import {
  Rfid,
  User,
  UserNotificationSettings,
  UserSearchResult,
} from './model';

export const UserStore = types
  .model({
    isLoggedIn: false,
    user: types.maybe(User),
    userSearchItems: types.maybeNull(UserSearchResult),
  })
  .volatile(() => ({
    isLoading: false,
    isLoadingUserGroups: true,
    authenticationErrorMessage: '',
    rememberCredentials: false,
    credentials: { email: '', password: '' },
    selectedUserId: '',
    needToEnableMfa: false,
    needToEnterMfaCode: false,
    userIsServiceTechnician: false,
    cognitoUser: {challengeName: '', session: null},
    totpQRCode: '',

    // grid cache
    searchString: '',
    searchFilter: 'email' ,
    loadingBatch: false,
    previousNext: [],
    rowsPerPage: 25,
    page: 0,
    persistedPagination: false
  }))
  .views((self) => {
    return {
      get api(): UsersApi {
        return getEnv(self).usersApi;
      },
      get organizationsApi(): OrganizationsApi {
        return getEnv(self).organizationsApi;
      },
      get rfidTokenApi(): RfidTokenApi {
        return getEnv(self).rfidTokenApi;
      },
      get userSearchResult() {
        return Array.from(self.userSearchItems?.items.values() ?? []);
      },
      get selectedUser() {
        return this.userSearchResult?.find(
          (user) => user.id === self.selectedUserId,
        );
      },
      get userSearchItemsCount() {
        return self.userSearchItems?.total ?? 0;
      },
      get userSearchItemsNext() {
        return self.userSearchItems?.next;
      },
      getUser(email: string): User | undefined {
        const arr = Array.from(this.userSearchResult.values());
        const user = arr?.find(
            (user) => user.email === email,
        );
        return user;
      },
    };
  })
  .actions((self) => {
    const getAUser = flow(function* (email: string) {
      const { data, error } = yield self.api.getUserByEmail(email);
      if (!error && data) {
        addUserSearchItems(data);
        return data.items;
      }
    });

    const setPersistedPagination = (persist: boolean) => {
      self.persistedPagination = persist;
    };

    const setPage = (page: number) => {
      self.page = page;
    };

    const setRowsPerPage = (rowsPerPage: number) => {
      self.rowsPerPage = rowsPerPage;
    };

    const setPreviousNext = (previousNext: any) => {
      self.previousNext = previousNext;
    };

    const setSearchString = (searchString: string) => {
      self.searchString = searchString;
    }

    const setSearchFilter = (searchFilter: string) => {
      self.searchFilter = searchFilter;
    }

    const resetPagination = () => {
      self.persistedPagination = false;
      self.page = 0;
      self.rowsPerPage = 25;
      self.previousNext = [];
      self.searchString = '';
      self.searchFilter = '';
    };

    const markLoading = (loading: boolean) => {
      self.isLoading = loading;
    };

    const getCurrentUser = flow(function* () {
      const { data, error } = yield self.api.getCurrentUser();
      if (!error && data) {
        self.user = data;
      }
    });

    const addUserSearchItems = (result: UserSearchResult) => {
      self.userSearchItems = cast(result);
    };

    const addUserSearchItem = (user: User) => {
      if (self.userSearchItems) {
        const index = self.userSearchItems?.items.findIndex(
          (item) => item.id === user.id,
        );

        if (index !== -1) {
          const item = JSON.stringify({
            ...self.userSearchItems.items.at(index),
          });
          self.userSearchItems.items.splice(index, 1);
          self.userSearchItems.items.push({ ...JSON.parse(item), user });
        }
      }
    };

    const getUser = flow(function* (id: string) {
      markLoading(true);

      const { data, error } = yield self.api.getUser(id);

      if (!error && data) {
        addUserSearchItem(data);
      }
      markLoading(false);
    });

    const updateUser = flow(function* (user: User) {
      if (user.id && user.first_name && user.last_name) {
        const { data, error } = yield self.api.updateUser(
          user.id,
          user.first_name,
          user.last_name,
        );
        if (!error && data) {
          addUserSearchItem(data);
          return data as User;
        }
      }
    });

    const getUsers = flow(function* (
      filterType = '',
      searchString = '',
      pageSize = 25,
      next = '',
    ) {
      markLoading(true);

      const { data, error } = yield self.api.getUsers(
        searchString.length ? filterType : '',
        searchString,
        pageSize,
        next,
      );

      if (!error) {
        if (!error) {
          addUserSearchItems(data);
        }
      }
      markLoading(false);

      return data;
    });

    // @note hackish way to determine that user is authorized as a "ST user"
    const authorize = flow(function* () {
      markLoading(true);
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { data, error } = yield self.api.getUsers('', '', 1, '');

      if (!error) {
        self.userIsServiceTechnician = true;
      } else {
        self.userIsServiceTechnician = false;
      }
      markLoading(false);
    });

    const setLoggedIn = (loggedIn: boolean) => {
      self.isLoggedIn = loggedIn;
    };

    const authenticate = flow(function* (email: string, password: string) {
      try {
        markLoading(true);
        self.authenticationErrorMessage = '';
        self.cognitoUser = yield requestAuth(email, password);
        self.needToEnterMfaCode = self.cognitoUser?.challengeName === 'SOFTWARE_TOKEN_MFA';
        console.log('self.cognitoUser?.challengeName: ', self.cognitoUser?.challengeName);
        if( self.needToEnterMfaCode || self.needToEnableMfa) {
          self.isLoggedIn = false;
        } else {
          self.isLoggedIn = true;
        }
      } catch (err: any) {
        console.log('error in authentication: ', err);
        self.authenticationErrorMessage = err.message;

        // need to enable MFA.
        if (err.message.includes('[401][*09*]')) {
          self.needToEnableMfa = true;
          console.log('need to enable MFA');
        } else {
          self.isLoggedIn = false;
        }
      } finally {
        markLoading(false);
      }
    });

    const confirmAuth = flow(function* (code: string) {
      try {
        markLoading(true);
        self.authenticationErrorMessage = '';
        self.cognitoUser = yield confirmSignIn(self.cognitoUser, code);
        self.isLoggedIn = true;
        self.needToEnterMfaCode = false;
      } catch (err: any) {
        console.log('error in confirmAuth: ', err);
        self.authenticationErrorMessage = err.message;
        self.isLoggedIn = false;
      } finally {
        markLoading(false);
      }
    });

    const generateTotpQrCode = flow(function* (username: string, password: string) {
      try {
        if(!self.totpQRCode) {
          const resp = yield getTOTPQRCode(username, password);
          self.totpQRCode = resp.qrCode;
          self.cognitoUser = resp.cognitoUser;
        }
      } catch (err: any) {
        console.log('error in generateTotpQrCode: ', err);
      }
    });

    const enableMfa = flow(function* (code: string) {
      try {
        markLoading(true);
        console.log('Enabling MFA');
        const message = yield turnOnTOTP(self.cognitoUser, code);
        console.log('enableMfa: ', message);
        self.needToEnableMfa = false;
      } catch (err: any) {
        console.log('error in enableMfa: ', err);
      } finally {
        markLoading(false);
      }
    });

    const disableMfa = flow(function* () {
      try {
        markLoading(true);
        console.log('Disabling MFA');
        const message = yield turnOffTOTP();
        console.log('disableMfa: ', message);
        signOutUser();
      } catch (err: any) {
        console.log('error in disableMfa: ', err);
      } finally {
        markLoading(false);
      }
    });


    const signOutUser = flow(function* () {
      try {
        yield signOut();
        self.isLoggedIn = false;
        self.userIsServiceTechnician = false;
        self.needToEnableMfa = false;
        self.totpQRCode = '';
      } catch (error) {
        console.log('Unable to sign out: ', JSON.stringify(error));
      }
    });

    const changePasswordUser = flow(function* (
      oldPassword: string,
      newPassword: string,
    ) {
      try {
        yield changePassword(oldPassword, newPassword);
        return '';
      } catch (err: any) {
        console.log('Unable to sign out: ', JSON.stringify(err));
        return err?.message;
      }
    });

    const signUpUser = flow(function* (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
    ) {
      try {
        return yield signUp(email, password, firstName, lastName);
      } catch (err) {
        console.log('Unable to sign up: ', JSON.stringify(err));
        return err;
      }
    });

    const checkAlreadyLoggedIn = flow(function* () {
      try {
        const cognitoUser = yield currentAuthenticatedUser();
        console.log('checkAlreadyLoggedIn: Signed in')
        if (cognitoUser) {
          self.isLoggedIn = true;
        }
      } catch (err) {
        console.log('checkAlreadyLoggedIn: Not signed in');
      }
    });

    const getUserNotificationSettings = flow(function* (id: string) {
      const { data, error } = yield self.api.getUserNotificationSettings(id);

      if (!error && data) {
        return data as UserNotificationSettings;
      }
    });

    const updateUserNotificationSettings = flow(function* (
      userId: string,
      userNotificationSettings: UserNotificationSettings,
    ) {
      const { data, error } = yield self.api.updateUserNotificationSettings(
        userId,
        userNotificationSettings,
      );

      if (!error && data) {
        return data as UserNotificationSettings;
      }
    });

    const getRfIDKeysForUser = flow(function* (userId: string) {
      const { data, error } = yield self.rfidTokenApi.getRfIdKeys(userId);

      if (!error) {
        return data.filter(
          (token: Rfid) => token.type === 'ISO14443',
        ) as Rfid[];
      }
    });

    const createRfIDKeyForUser = flow(function* (userId: string, rfid: Rfid) {
      const { data, error } = yield self.rfidTokenApi.createRfIdKey({
        ...rfid,
        user_id: userId,
      });

      if (!error) {
        return data as Rfid;
      }
    });

    const updateRfIDKeyForUser = flow(function* (userId: string, rfid: Rfid) {
      const { data, error } = yield self.rfidTokenApi.updateRfIdKey({
        ...rfid,
        user_id: userId,
      });

      if (!error) {
        return data as Rfid;
      }
    });

    const removeRfIDKeyForUser = flow(function* (id: string) {
      const { error } = yield self.rfidTokenApi.deleteRfIdKey(id);

      if (!error) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    });

    const setSelectedUser = (id: string) => {
      self.selectedUserId = id;
    };

    return {
      markLoading,
      authenticate,
      authorize,
      signOutUser,
      signUpUser,
      changePasswordUser,
      checkAlreadyLoggedIn,
      getCurrentUser,
      getAUser,
      getUser,
      updateUser,
      getUsers,
      setSelectedUser,
      updateUserNotificationSettings,
      getUserNotificationSettings,
      getRfIDKeysForUser,
      createRfIDKeyForUser,
      updateRfIDKeyForUser,
      removeRfIDKeyForUser,
      setLoggedIn,
      confirmAuth,
      generateTotpQrCode,
      enableMfa,
      disableMfa,

      setPage,
      setRowsPerPage,
      setPreviousNext,
      setSearchString,
      setSearchFilter,
      setPersistedPagination,
      resetPagination     
    };
  });

import { Box, Card, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { load } from './dlm-parser';
import globalStyles from '../../../../global-styles.module.scss';
import { LocationStatusHeader } from './location-status-header';
import { ChargingStation } from 'src/store/model';

type Props = {
  chargingStations: ChargingStation[];
};

export const Groups = ({ chargingStations }: Props) => {
  const [DLMTree, setDLMTree] = useState('');
  const [noDLM, setNoDLM] = useState('');
  const [network, setNetwork] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    console.log(chargingStations.length);
      const data = {
        "items": chargingStations,
        "next": null,
        "total": 14,
        "size": 14,
        "limit": 250
      }
  
      const dlm_tree_val = load(data);
      const groups = dlm_tree_val.split(/(dlmtree|nodlm|network)/);
      setDLMTree(groups[2]);
      setNoDLM(groups[4]);
      setNetwork(groups[6]);
  }, [chargingStations]);
  
  return (
    <Card className={globalStyles.container}>
      <LocationStatusHeader />
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <TextField
          style={{width: '100%', margin: 5}}
          InputProps={{style: {fontFamily: 'monospace', fontSize: 14}}}
          disabled={false}
          multiline
          id="standard-name"
          label={t('DLM-tree(s)')}
          variant="outlined"
          value={DLMTree}
        />
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <TextField
          style={{width: '100%', margin: 5}}
          InputProps={{style: {fontFamily: 'monospace', fontSize: 14}}}
          disabled={false}
          multiline
          id="standard-name"
          label={t('No DLM')}
          variant="outlined"
          value={noDLM}
        />
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: 4}}>
        <TextField
          style={{width: '100%', margin: 5}}
          InputProps={{style: {fontFamily: 'monospace', fontSize: 14}}}
          disabled={false}
          multiline
          id="standard-name"
          label={t('Network')}
          variant="outlined"
          value={network}
        />
      </Box>
    </Card>
  );
};

import { Instance } from 'mobx-state-tree';
import { useEffect } from 'react';
import { ChargingStationStore } from 'src/store/ChargingStationStore';

import { UserStore } from '../store/UserStore';

export const useLoggedIn = (
  userStore: Instance<typeof UserStore>,
  chargingStationStore: Instance<typeof ChargingStationStore>,
) => {
  useEffect(() => {
    if (userStore.isLoggedIn) {
      chargingStationStore.getChargingStations();
    } else {
      userStore.checkAlreadyLoggedIn();
    }
  }, [chargingStationStore, userStore, userStore.isLoggedIn]);
};

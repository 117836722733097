import { SnapshotIn, types } from 'mobx-state-tree';

export const FileLocation = types.model({
  Key: types.string,
  Bucket: types.string,
});

export const ChargingStationLog = types.model({
  id: types.number,
  charging_station_id: types.string,
  log_type: types.string,
  timestamp: types.string,
  upload_protocol: types.string,
  upload_status: types.string,
  file_location: FileLocation,
  start_time: types.maybeNull(types.string),
  end_time: types.maybeNull(types.string),
  download_url: types.maybeNull(types.string),
});

export interface ChargingStationLog
  extends SnapshotIn<typeof ChargingStationLog> {}

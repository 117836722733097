import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { OcppEvent } from 'src/store/model';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';

type Props = {
  events: OcppEvent[];
};

export const ChargingStationStatus = ({ events }: Props) => {

  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        style={{ marginBottom: '1rem' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {t('Status log')}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Connector ID</TableCell>
              <TableCell align="right">{t('Info')}</TableCell>
              <TableCell align="right">{t('Error code')}</TableCell>
              <TableCell align="right">{t('Vendor error code')}</TableCell>
              <TableCell align="right">{t('Status')}</TableCell>
              <TableCell align="right">{t('Timestamp')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row) => (
              <TableRow
                hover
                key={row.id}
              >
                <TableCell>{row.payload?.connector_id}</TableCell>
                <TableCell align="right">{row.payload?.info}</TableCell>
                <TableCell align="right">{row.payload?.error_code}</TableCell>
                <TableCell align="right">
                  {row.payload?.vendor_error_code}
                </TableCell>
                <TableCell align="right">{row.payload?.status}</TableCell>
                <TableCell align="right">
                  {dateFns.format(
                    new Date(row.timestamp as any),
                    'yyyy-MM-dd HH:mm'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

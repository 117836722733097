import {
  Avatar,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
  } from '@mui/material';
  import React, { useCallback, useEffect, useMemo } from 'react';
  import { useStores } from 'src/store';
  
  export const AccessOwners = () => {
    const { userStore } = useStores();
    const prev: string[] = [];
    
    const search = useCallback(
      (next?: string) => {
        userStore.getUsers(
          userStore.searchFilter,
          userStore.searchString,
          userStore.rowsPerPage,
          next
        );
      },[userStore]
    );

    useEffect(() => {
      handleChangePage(null, userStore.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleChangePage = useCallback(
      (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
  
        let next = '';
  
        if (newPage <= userStore.page || userStore.persistedPagination) { 
          next = userStore.previousNext[newPage] ?? '';
        } else {
  
          userStore.setPersistedPagination(false);
  
          next = userStore.userSearchItemsNext ?? '';
          prev[newPage] = next;
          userStore.setPreviousNext(prev);
        }
  
        search(next)
        userStore.setPage(newPage);
      },
      [userStore, search]
    );
    
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
  
      const rowsPerPage = parseInt(event.target.value, 10);
  
      userStore.getUsers(
        userStore.searchFilter,
        userStore.searchString,
        rowsPerPage,
        userStore.previousNext[userStore.page]
      );
  
      userStore.setRowsPerPage(rowsPerPage);
      userStore.setPage(0);
    };

    const getColor = (user: any) => {
    
      let hash = 0;
      const id = user?.id ?? '1';
      for (let i = 0; i < id.length; i++) {
        hash = id.charCodeAt(i) + ((hash << 5) - hash);
      }
  
      let color = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      return color;
    };
    
    return (
      <Box>
        <Typography
          style={{ marginBottom: '1rem' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Owners
        </Typography>
        <TableContainer
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          component={Paper}
        >
          <Table sx={{ minWidth: 800 }}size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">First name</TableCell>
                <TableCell align="left">Last name</TableCell>
                <TableCell align="left">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {userStore.userSearchResult.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                >
                  <TableCell component="th" scope="row">
                    <Avatar
                      className={'avatar'}
                      sx={{ bgcolor: getColor(row) }}
                    >
                      {`${row?.first_name?.charAt(0).toUpperCase()}${row?.last_name?.charAt(0).toUpperCase()}`}
                    </Avatar>
                  </TableCell>
                  <TableCell align="left">{row?.first_name}</TableCell>
                  <TableCell align="left">{row?.last_name}</TableCell>
                  <TableCell align="left">{row?.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={userStore.userSearchItemsCount}
            page={userStore.page}
            onPageChange={handleChangePage}
            rowsPerPage={userStore.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    );
  };
  
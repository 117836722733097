import { Instance } from 'mobx-state-tree';
import { useEffect } from 'react';
import { ChargingStationConfigurationsStore } from 'src/store/ChargingStationConfigurationsStore';
import { InstallationStore } from 'src/store/InstallationStore';

import { ChargingStationStore } from '../store/ChargingStationStore';
import { LocationStore } from 'src/store/LocationStore';

export const useCurrentChargingStation = (
  chargingStationStore: Instance<typeof ChargingStationStore>,
  chargingStationConfigurationsStore: Instance<
    typeof ChargingStationConfigurationsStore
  >,
  installationStore: Instance<typeof InstallationStore>,
  locationStore: Instance<typeof LocationStore>,
) => {
  useEffect(() => {
    if (
      chargingStationStore.currentChargingStationId
    ) {
      chargingStationConfigurationsStore.getConfigurationForChargingStation();
      chargingStationStore.getChargingStationTransactions(
        chargingStationStore.currentChargingStationId,
      );
      installationStore.getAvailableOfficialFirmware();
      installationStore.getAvailableUnofficialFirmware();
      installationStore.getInstallationJobs(chargingStationStore?.currentChargingStation?.location_id ?? '');
      chargingStationStore.getCurrentChargingStationOcppEvents();
      chargingStationStore.getChargingStationReservation(chargingStationStore.currentChargingStationId);
      chargingStationStore.getFactoryKeys(chargingStationStore.currentChargingStation?.uid ?? '');
      chargingStationStore.getChargingProfiles(chargingStationStore.currentChargingStationId);
      chargingStationStore.getCurrentChargingStationSettings();
    }
    const charginUnitSerialNumber = chargingStationStore.currentChargingStation?.charging_unit?.serial_number;
    if(charginUnitSerialNumber) {
      chargingStationStore.getChargingUnitBlocklist(charginUnitSerialNumber);
    }
  }, [
    chargingStationConfigurationsStore,
    chargingStationStore,
    chargingStationStore.currentChargingStationId,
    installationStore,
    locationStore,
  ]);
};

import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import { ApiResponse, HTTPValidationError } from 'src/core';
import { getToken } from '../core/aws-auth';

type CloudApiOptions = {
  headers?: AxiosRequestHeaders;
  body?: any;
};

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`;

axiosRetry(axios, {
  retries: 2,
  retryCondition: (e: AxiosError) => {

    // should be enough for no-response errors
    const noResponseCodes = ['ECONNRESET', 'ENOTFOUND', 'ETIMEDOUT', 'ECONNREFUSED', 'ERRADDRINUSE', 'ECONNABORTED'];

    if (!noResponseCodes.includes(typeof e.code === 'string' ? e.code : '')) {
      return false;
    }

    // basically skipping POST/DELETE retries
    return typeof e.config?.method === 'string' && ['GET', 'HEAD', 'OPTIONS', 'PUT'].includes(e.config.method.toUpperCase());
  },
  onRetry: (retryCount) => {
    console.log(`Retry attempt #${retryCount}`);
  }
});

/**
 * Abstract root class for all Api resources. Contains
 * methods for maping responses.
 */
export abstract class CloudApi {

  static objToQueryString(obj: any): string {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj[key]) {
        keyValuePairs.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]),
        );
      }
    }
    const queryString = keyValuePairs.join('&');
    return queryString.length ? `?${queryString}` : '';
  }

  protected GET(
    route: string,
    options: CloudApiOptions = {},
  ): Promise<ApiResponse> {
    return this.fetch('GET', route, options);
  }

  protected async POST(route: string, body: any): Promise<ApiResponse> {
    return this.fetch('POST', route, { body });
  }

  protected async PUT(route: string, body: any): Promise<ApiResponse> {
    return this.fetch('PUT', route, { body });
  }

  protected async DELETE(route: string): Promise<ApiResponse> {
    return this.fetch('DELETE', route);
  }

  private async fetch(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    route: string,
    { body, headers }: CloudApiOptions = {},
  ) {

    const response = new ApiResponse();

    try {
      const token = await getToken();
      const data = body ? JSON.stringify(body) : null;

      const axiosResponse = await axios({
        url: `${route}`,
        method: method,
        headers: headers || {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data
      });

      response.data = axiosResponse.data;

    } catch (error: any) {

      // @todo: maybe handle 404 gracefully here?

      response.error = new HTTPValidationError();

      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        response.error = error.response.data;
        response.status = error.response.status;
        console.warn(error.response.data);
        console.warn(error.response.status);
        console.warn(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received
         */
        console.warn(error.request);
        response.error = {
          detail: [
            { msg: 'The request was made but no response was received' },
          ],
        };

      } else {

        // Something happened in setting up the request and triggered an Error
        console.warn('Error', error.message);
        response.error = {
          detail: [
            {
              msg: error.message,
            },
          ],
        };
      }
      console.warn(error);
    }
    return response;
  }
}



import {
  ActionsApi,
  ChargingStationConfigurationsApi,
  ChargingStationFirmwareApi,
  ChargingStationInstallationApi,
  ChargingStationsApi,
  ChargingStationStatusApi,
  ChargingUnitsApi,
  ConnectionGroupsApi,
  FirmwarePackagesApi,
  LocationsApi,
  OrganizationsApi,
  RfidTokenApi,
  UsersApi,
  InstallationJobsApi
} from '../api';

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    this.actionApi = new ActionsApi();
    this.chargingStationsApi = new ChargingStationsApi();
    this.chargingUnitsApi = new ChargingUnitsApi();
    this.chargingStationFirmwareApi = new ChargingStationFirmwareApi();
    this.chargingStationInstallationApi = new ChargingStationInstallationApi();
    this.firmwarePackagesApi = new FirmwarePackagesApi();
    this.locationsApi = new LocationsApi();
    this.organizationsApi = new OrganizationsApi();
    this.usersApi = new UsersApi();
    this.chargingStationStatusApi = new ChargingStationStatusApi();
    this.chargingStationConfigurationsApi =
      new ChargingStationConfigurationsApi();
    this.connectionGroupsApi = new ConnectionGroupsApi();
    this.rfidTokenApi = new RfidTokenApi();
    this.installationJobsApi = new InstallationJobsApi();
  }

  /**
   * Our apis.
   */
  actionApi: ActionsApi;
  chargingStationsApi: ChargingStationsApi;
  chargingUnitsApi: ChargingUnitsApi;
  locationsApi: LocationsApi;
  organizationsApi: OrganizationsApi;
  usersApi: UsersApi;
  firmwarePackagesApi: FirmwarePackagesApi;
  chargingStationFirmwareApi: ChargingStationFirmwareApi;
  chargingStationStatusApi: ChargingStationStatusApi;
  chargingStationInstallationApi: ChargingStationInstallationApi;
  chargingStationConfigurationsApi: ChargingStationConfigurationsApi;
  connectionGroupsApi: ConnectionGroupsApi;
  rfidTokenApi: RfidTokenApi;
  installationJobsApi: InstallationJobsApi;
}

import { cast, destroy, flow, getEnv, getParent, types } from 'mobx-state-tree';

import { LocationsApi } from '../api';
import { Location } from './model/Location';
import { RootModel } from './RootStore';

export const LocationStore = types
  .model({
    items: types.map(Location),
  })
  .volatile(() => ({
    currentLocationId: '',
    previousLocationId: '',
    isLoading: true,
  }))
  .views((self) => {
    return {
      get api(): LocationsApi {
        return getEnv(self).locationsApi;
      },
      get locations() {
        return Array.from(self.items.values()).sort((a, b) =>
          `${a.organization_id}` > `${b.organization_id}` ? 1 : -1,
        );
      },
      get currentLocationName(): string {
        if (self.isLoading) {
          return '';
        } else {
          return self.items.size
            ? self.items.get(self.currentLocationId)?.name || ''
            : 'No locations';
        }
      },
      get currentLocationOrganization(): string {
        return self.items.get(self.currentLocationId)?.organization_id || '';
      },
      get currentContext(): string | null {
        return 'Installer';
      },
      get currentUserId(): string {
        return getParent<typeof RootModel>(self).userStore.user?.id || '';
      },
    };
  })
  .actions((self) => {
    const markLoading = (loading: boolean) => {
      self.isLoading = loading;
    };

    const addLocation = (location: Location) => {
      self.items.set(`${location.id}`, location);
    };

    const deleteLocation = (locationId: string) => {
      if (self.items.has(locationId)) {
        destroy(cast(self.items.get(locationId)));
      }
    };

    const clearLocations = () => {
      // self.currentLocationId = '';
      self.items.clear();
    };

    const getLocations = flow(function* (clear = false) {
      if (self.currentContext) {
        markLoading(true);

        if (clear) {
          clearLocations();
        }

        const { data, error } = yield self.api.getLocations(
          self.currentContext,
        );

        if (!error) {
          const currentLoc = data.find(
            (loc: Location) => loc.id === self.previousLocationId,
          );
          data?.forEach((location: Location) => {
            addLocation(location);
          });

          setCurrentLocation(currentLoc?.id || data?.[0]?.id || '');
        }
        markLoading(false);
      }
    });

    const getLocation = flow(function* (id: string) {
      const { data, error } = yield self.api.getLocation(id);

      if (!error && data) {
        addLocation(data);
      }
    });

    const createLocation = flow(function* (location: Location) {
      const { data, error } = yield self.api.addLocation(location);

      if (!error && data) {
        addLocation(data);
      }
    });

    const updateCurrentLocation = flow(function* (location: Location) {
      const currentLocation = self.locations.find(
        (loc) => loc.id === self.currentLocationId,
      );

      const { data, error } = yield self.api.updateLocation({
        ...currentLocation,
        ...location,
      });

      if (!error && data) {
        addLocation(data);
      }
    });

    const removeLocation = flow(function* (locationId: string) {
      const { error } = yield self.api.removeLocation(locationId);

      if (!error) {
        deleteLocation(locationId);

        setCurrentLocation(
          self.locations.length
            ? self.locations[0].id
            : (self.currentLocationId = ''),
        );
      }
    });

    const setCurrentLocation = (id: string | undefined | null) => {
      self.previousLocationId = self.currentLocationId;
      self.currentLocationId = id || '';
    };

    return {
      getLocations,
      getLocation,
      createLocation,
      updateCurrentLocation,
      removeLocation,
      setCurrentLocation,
    };
  });
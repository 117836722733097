import {
    Box, Button,
  } from '@mui/material';
  import React from 'react';
import { ChargingStation } from 'src/store/model';
  
  type Props = {
    chargingStations: ChargingStation[];
  };

  export const Actions = ({ chargingStations }: Props) => {

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 1,
            borderRadius: 2,
            p: 2,
          }}>
          <Button
              style={{ width: 150, textTransform: 'none' }}
              size="small"
              color="primary"
            >
              Reboot
          </Button>
          <Button
              style={{ width: 150, textTransform: 'none' }}
              size="small"
              color="primary"
            >
              Read config
          </Button>
          <Button
              style={{ width: 150, textTransform: 'none' }}
              size="small"
              color="primary"
            >
              Update
          </Button>
        </Box>
      </div>
    );
  };
  
import React, {  } from 'react';
import globalStyles from '../../../global-styles.module.scss';
import { ChargingStationTable } from '..';
import { TableTabsButtons } from './components';
import { UsersTable } from '../users-table/users-table';
import { useLocation, useNavigate } from 'react-router-dom';

export const TableTabs = () => {
  const location = useLocation();
  const currentTab = location.pathname;
  const navigate = useNavigate();

  return (
    <div className={globalStyles.container}>
      <TableTabsButtons
        currentTab={currentTab}
        setValue={(loc) => navigate(loc.toString())}
      />
      <TabPanel path="/service-tech/charging-stations" currentTab={currentTab}>
        <ChargingStationTable />
      </TabPanel>
      <TabPanel path="/service-tech/users" currentTab={currentTab}>
        <UsersTable />
      </TabPanel>
    </div>
  );
};

type PanelProps = {
  children?: React.ReactNode;
  path: string;
  currentTab: string;
};

const TabPanel = ({ path, currentTab, children, ...other }: PanelProps) => {
  const isSame = path === currentTab;
  return (
    <div
      role="tabpanel"
      hidden={!isSame}
      id={`vertical-tabpanel-${path}`}
      aria-labelledby={`vertical-tab-${path}`}
      {...other}
    >
      {isSame && children}
    </div>
  );
};

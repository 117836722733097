export const hexEncode = (str: string) => {
  let hex = '';
  for (let i = 0; i < str.length; i++) {
    hex += '' + str.charCodeAt(i).toString(16);
  }
  return hex;
};

export const hexDecode = (str: string) => {
  let hex = '';
  for (let i = 0; i < str.length; i += 2) {
    hex += String.fromCharCode(parseInt(str.substr(i, 2), 16));
  }
  return hex;
};

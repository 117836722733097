import {
    Autocomplete,
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    createFilterOptions
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import { useStores } from 'src/store';
  import { t } from 'i18next';
  import styles from './data-transfer.module.scss';
  import { ChargingStationSearchItem } from 'src/store/model';
  import { Send, CopyAll } from '@mui/icons-material';
  import * as dateFns from 'date-fns';
  import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Loader } from '../loader/loader';
  
  type Props = {
    chargingStations: any;
  };
  interface CommandTypes {
    name: string;
    value: string;
    inputValue?: string;
  }
  const filter = createFilterOptions<CommandTypes>();
  
  const commands: CommandTypes[] = [
    { name: 'mpath', value: 'diag.net.mesh.mpath' },
    { name: 'station', value: 'diag.net.mesh.station' },
    { name: 'link', value: 'diag.net.ip.link' },
    { name: 'ifconfig', value: 'diag.net.ip.ifconfig' },
    { name: 'arp', value: 'diag.net.arp' },
    { name: "showmacs", value: 'diag.net.bridge.showmacs' },
    { name: 'route', value: 'diag.net.ip.route' },
    { name: 'netstat', value: 'diag.net.stats.netstat' },
    { name: 'uptime', value: 'diag.net.stats.uptime' },
    { name: 'processes', value: 'diag.system.processes' },
    { name: 'currentoperator', value: 'diag.net.modem.currentoperator' },
    { name: 'operators', value: 'diag.net.modem.operators' },
  ];
  
  export const DataTransfer = ({ chargingStations }: Props) => {
    const { actionStore } = useStores();
    const [open, setOpen] = useState(false);
    const [command, setCommand] = useState<CommandTypes>(commands[0]);
    const [data, setData] = useState('');
    const [progress, setProgress] = React.useState(0);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      const storedCommands = localStorage.getItem('commands');
      const commandArray = storedCommands?.split(',');
      commandArray?.forEach((c) => {
        const foundCommand = commands.find((command) => {
          return command.name === c;
        });
        if (storedCommands && !foundCommand) {
          commands.push({ name: c, value: c });
        }
      });
    }, []);
  
    const getHeader = (chargingStationId: string) => {
      const cs = chargingStations.find((chargingStation: ChargingStationSearchItem) => {
        return chargingStation.charging_station?.id === chargingStationId;
      });
      const now = dateFns.format(new Date(), 'yyyy-MM-dd HH:mm:ss');
      return `--- ${cs?.charging_station?.uid}, ${now} ---`;
    }
  
    const executeCommand = async () => {
      setData('');
      setProgress(0);
      setLoading(true);
      const incValue = 100 / chargingStations.length;
      const promises = chargingStations.map((chargingStation: ChargingStationSearchItem) => {
        if (!chargingStation.charging_station?.id) return Promise.resolve();
        return actionStore.dataTransfer(chargingStation.charging_station?.id, '', command.value).then((res) => {
          if (res.data.status === 'Accepted') {
            setData((t) => `${t}${getHeader(res.data.charging_station_id)}\n${window.atob(res.data.data)}\n\n`);
          } else {
            setData((t) => `${t}${getHeader(res.data.charging_station_id)}\n${res.data.status}\n\n`);
          }
          setProgress((prevProgress) => (prevProgress + incValue));
        });
      });
      if (promises) {
        await Promise.all(promises);
      }
      setLoading(false);
    };
  
    const storeCommand = (command: string) => {
      let storedCommands = localStorage.getItem('commands');
      storedCommands += `,${command}`;
      localStorage.setItem('commands', storedCommands || '');
      commands.push({ name: command, value: command });
    }
  
    const handleCopyToClipboard = () => {
      setOpen(true);
      navigator.clipboard.writeText(data);
    }
  
    function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress variant="determinate" {...props} />
          </Box>
        </Box>
      );
    }
    
    return (
      <Card className={styles.container}>
      <Grid container spacing={2} justifyContent="center"
        sx={{ margin: 'auto', width: '1000px', marginBottom: 5, marginTop: 4, paddingBottom: 0 }}>
        <Grid xs={4}>
          <Autocomplete
            style={{ width: 320, marginBottom: 20 }}
            size="small"
            value={command.name.replace(t('Add command') + ': ', '')}
            onChange={(event, value) => {
              console.log('onChange', value);
              if (typeof value === 'string') {
                setCommand(commands[0]);
              } else if (value && value.inputValue) {
                storeCommand(value.inputValue);
                setCommand(value);
              } else {
                setCommand(value || commands[0]);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Creating a new value
              const isExisting = options.some((option) => inputValue === option.name);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  value: inputValue,
                  inputValue: inputValue,
                  name: `${t('Add command')}: ${inputValue}`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={commands}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (<TextField variant="standard" {...params} label={t('Command')} />)}
          />
        </Grid>
        <Grid xs={7}>
          <Button 
          style={{ marginTop: 10, minWidth: 200 }}
          disabled={loading}
          variant="outlined" 
          onClick={executeCommand} 
          endIcon={!loading ? <Send /> : null}
          >{loading ? <Loader /> : t('Execute Command')}</Button>
        </Grid>
        <Grid xs={1}>
          <Tooltip title={t('Tooltip copy message')}>
            <IconButton style={{float: 'right', marginTop: 2}} color="primary" onClick={handleCopyToClipboard}>
              <CopyAll />
            </IconButton>
          </Tooltip>
        </Grid>
        {chargingStations.length > 1 && <Grid xs={12}>
          <LinearProgressWithLabel value={progress} />
        </Grid>}
        <Grid xs={12}>
          <TextField
            className={styles.text}
            variant="standard"
            style={{width: '100%'}}
            InputProps={{style: {fontFamily: 'monospace', fontSize: 14}}}
            disabled={false}
            multiline
            id="standard-name"
            label={t('Received data')}
            value={data}
          />
        </Grid>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message={t('Snackbar copy message')}
        />
      </Grid>
      </Card>
    );
  };
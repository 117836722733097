import { SnapshotIn, types } from 'mobx-state-tree';

export const ChargingStationReservation = types.model({
  id: types.identifier,
  charging_station_id: types.maybeNull(types.string),
  charging_station_uid: types.maybeNull(types.string),
  connector_id: types.maybeNull(types.number),
  id_token_id: types.maybeNull(types.string),
  expiry_date: types.maybeNull(types.string),
});

export type ChargingStationReservation = SnapshotIn<typeof ChargingStationReservation>
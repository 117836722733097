export default {
  translation: {
    'uk': 'English (UK)',
    'en': 'English (UK)',
    'fi': 'Suomi',
    'se': 'Svenska',
    'no': 'Norsk',
    'de': 'Deutsch',
    'ie': 'English (IE)',
    'pl': 'Polski',
    'Name': 'Name',
    'Email': 'Email',
    'Password': 'Password',
    'Login': 'Login',
    'Language same as region': 'Language same as region',
    'Rename charging station': 'Rename charging station',
    'Vendor': 'Vendor',
    'Model': 'Model',
    'Serial code': 'Serial code',
    'Current firmware': 'Current firmware',
    'Custom firmware URL': 'Custom firmware URL',
    'Change firmware': 'Change firmware',
    'Remove all reservations': 'Remove all reservations',
    'Reboot device': 'Reboot device',
    'Flash LED': 'Flash LED',
    'Select firmware version:': 'Select firmware version:',
    'Official firmware version:': 'Official firmware version:',
    'Unofficial firmware version:': 'Unofficial firmware version:',
    'Documentation': 'Documentation',
    'Product information': 'Product information',
    'Support': 'Support',
    'Download GARO Connect': 'Download GARO Connect',
    'GET IT ON': 'GET IT ON',
    'Manual': 'Manual',
    'Quick Guide': 'Quick Guide',
    'Polemount manual': 'Polemount manual',
    'Stand manual': 'Stand manual',
    'Installation kit': 'Installation kit',
    'Using Proxy': 'Using Proxy',
    'Online': 'Online',
    'Offline': 'Offline',
    'Generating logfile': 'Generating logfile',
    'Generate logfile': 'Generate logfile',
    'Currently this portal is for service tecnicians only.': 'Currently this portal is for service tecnicians only.',
    'Restart software': 'Restart software',
    'Theft block status:': 'Theft block status:',
    'Current schedule': 'Current schedule',
    'Edited schedule': 'Edited schedule',
    'Monday': 'Monday',
    'Tuesday': 'Tuesday',
    'Wednesday': 'Wednesday',
    'Thursday': 'Thursday',
    'Friday': 'Friday',
    'Saturday': 'Saturday',
    'Sunday': 'Sunday',
    'Weekly': 'Weekly',
    'One time': 'One time',
    'Delete event': 'Delete event',
    'Delete description': 'Do you want to delete the schedule event permanently?',
    'No': 'No',
    'Yes': 'Yes',
    'Start time': 'Start time',
    'Stop time': 'Stop time',
    'Free of charge': 'Free of charge',
    'Sync configuration always on boot': 'Sync configuration always on boot',
    'Ignore unknown keys on sync': 'Ignore unknown keys on sync',
    'Update firmware automatically': 'Update firmware automatically',
    'Sync configuration as best effort': 'Sync configuration as best effort',
    'Store warning': 'Warning: The value will not be stored in CSMS and hence might be overwritten on next reboot of the device',
    'Store in CSMS': 'Store in CSMS',
    'Key': 'Key',
    'Value': 'Value',
    'Set Parameter Value': 'Set Parameter Value',
    'Get Parameter Value': 'Get Parameter Value',
    'Command': 'Command',
    'Add command': 'Add command',
    'Execute Command': 'Execute Command',
    'Received data': 'Received data',
    'Snackbar copy message': 'Copy to clipboard',
    'Tooltip copy message': 'Copied to clipboard',
    'Update status': 'Updated status',
  },
};
import styles from './charging-station-log-files.module.scss';

import React, { useState, useEffect } from 'react';
import { useStores } from 'src/store';
import * as dateFns from 'date-fns';
import { observer } from 'mobx-react-lite';

import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Card,
  CardContent,
  TableSortLabel,
} from '@mui/material';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import globalStyles from '../../../../global-styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'src/hooks';
import { ChargingStationStatusHeader } from './charging-station-status-header';

interface Data {
  upload_status: string;
  timestamp: string;
}

export const ChargingStationLogFiles = observer(() => {
  const { actionStore, chargingStationStore } = useStores();
  const [isGenerating, setIsGenerating] = useState(false);
  
  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('timestamp');

  const generateLogFile = () => {
    setIsGenerating(true);
    actionStore
      .generateChargingStationLogFile(
        chargingStationStore.currentChargingStationId
      )
      .finally(() => {
        setIsGenerating(false);
        chargingStationStore.getChargingStationLogs(chargingStationStore.currentChargingStationId);
      });
  };

  useInterval(() => {
    chargingStationStore.getChargingStationLogs(
      chargingStationStore.currentChargingStationId,
    );
  }, 5000);

  useEffect(() => {
    chargingStationStore.getChargingStationLogs(
      chargingStationStore.currentChargingStationId
    );
  }, [chargingStationStore.currentChargingStationId, chargingStationStore]);

  const { t } = useTranslation();

  const renderDownloadLink = (row: any) => {
  if (row.upload_status !== 'Uploaded' || !row.download_url) {
      return '';
    }
    return <a className={styles.downloadButton} href={row.download_url} download={`${row.log_type}-${row.timestamp}`}><CloudDownloadIcon color='action'></CloudDownloadIcon> </a>
  };

  const getStatusText = (row: any) => {
    const isOlderThanADay = dateFns.isBefore(
      new Date(row.timestamp),
      dateFns.subDays(new Date(), 1),
    );
    return row.upload_status !== 'Uploaded' && isOlderThanADay  ? t('Failed') : row.upload_status;
  };

  interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
  }

  interface HeadCell {
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'upload_status',
      numeric: false,
      label: 'Status',
    },
    {
      id: 'timestamp',
      numeric: true,
      label: 'Date',
    },
  ];

  const handleRequestSort = (_: any, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell>File</TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell> 
          ))}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = () => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  }

  return (
    <Card className={globalStyles.container}>
      <ChargingStationStatusHeader />
      <CardContent style={{ minHeight: 650 }}>
        <div className={`${globalStyles.container} ${styles.filterActions}`}>
          <Button
            style={{ width: 180 }}
            size="medium"
            color="primary"
            onClick={generateLogFile}>
            {t(isGenerating ? 'Generating logfile' : 'Generate logfile')}
          </Button>
        </div>
        <TableContainer className={globalStyles.container} style={{ width: 'unset' }} component={Paper}>
          <Table
            aria-label="simple table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {chargingStationStore.chargingStationLogFiles(chargingStationStore.currentChargingStationId)
                .sort(getComparator()).map((row) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell component="th" scope="row">
                      {row?.file_location.Key?.split('/')?.pop() ?? 'diagnostics.log'}
                    </TableCell>
                    <TableCell>{getStatusText(row)}</TableCell>
                    <TableCell align="right">{dateFns.format(new Date(row.timestamp), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell align="right">
                      {renderDownloadLink(row)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
});

import {
  Backdrop, Box, Button, Card, CardContent, Checkbox, Divider, TextField, Typography
} from '@mui/material';
import React from 'react';
import { useState, useEffect } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Rfid } from 'src/store/model';
import styles from './new-rfid.module.scss';
import { format, parseISO } from 'date-fns'

type Props = {
  rfId: Rfid | undefined;
  create: (id_token: string, name: string, expiration_timestamp: any) => void;
  update: (id: string, id_token: string, name: string, expiration_timestamp: any) => void;
  closeEdit: () => void;
  open: boolean;
};

export const NewRfid = ({ create, update, closeEdit, open, rfId }: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    register,
    trigger,
    formState: { isValid, isDirty }
  } = useForm<Rfid>({
    mode: 'onChange'
  });

  const onSubmit = (data: any) => {
    const { name, id_token, expiration_timestamp } = data;

    let isoTimestamp; // @note undefined
    if (expirationTimestampChecked && expiration_timestamp) {
      isoTimestamp = parseISO(expiration_timestamp).toISOString();
    }
    const token = id_token.toLowerCase();

    if (rfId && rfId.id) {
      update(rfId.id, token, name, isoTimestamp);
    } else {
      create(token, name, isoTimestamp);
    }

    closeEdit();
  };

  const { t } = useTranslation();
  const [expirationTimestampChecked, setExperationTimestamp] = useState(false);

  const isNameValid = (val: any): boolean => {
    return val ? true : false;
  };

  const isTokenValid = (val: any): boolean => {
    return val ? true : false;
  };

  const isExpirationTimestampValid = (val: any): boolean => {
    if (!expirationTimestampChecked) {
      return true;
    }
    return val ? true : false;
  };

  useEffect(() => {
    setExperationTimestamp(Boolean(rfId && rfId.expiration_timestamp));
  }, [rfId])

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <div
        style={{
          position: 'absolute',
          zIndex: +1,
          left: '50%',
          top: '30%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        {open ? (
          <Card style={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent style={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  {t(!rfId ? 'Add new RFID tag' : 'Edit RFID tag')}
                </Typography>
                <Divider sx={{ marginTop: 0, marginBottom: 2 }} />

                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className={styles.rfIdDetailsRow}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        validate: {
                          isNameValid
                        }
                      }}
                      render={({ field: { onChange } }) => (
                        <TextField
                          {...register('name')}
                          onChange={onChange}
                          defaultValue={rfId?.name}
                          label={t('RFID name')}
                          className={styles.field}
                          color="primary"
                          variant="standard"
                          type="text"
                        />
                      )}
                    />
                  </div>

                  <div className={styles.rfIdDetailsRow}>
                    <Controller
                      name="id_token"
                      control={control}
                      rules={{
                        validate: {
                          isTokenValid
                        }
                      }}
                      render={({ field: { onChange } }) => (
                        <TextField
                          {...register('id_token')}
                          onChange={onChange}
                          defaultValue={rfId?.id_token}
                          label={t("RFID token")}
                          className={styles.field}
                          color="primary"
                          variant="standard"
                          type="text"
                        />
                      )}
                    />
                  </div>

                  <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

                  <div className={styles.rfIdDetailsRow}>

                    <Controller
                      name="expiration_timestamp"
                      control={control}
                      rules={{
                        validate: {
                          isExpirationTimestampValid
                        }
                      }}
                      render={({ field: { onChange } }) => (
                        <TextField
                          onChange={onChange}
                          disabled={!expirationTimestampChecked}
                          defaultValue={rfId && rfId.expiration_timestamp ? format(parseISO(rfId.expiration_timestamp), "yyyy-MM-dd'T'HH:ii:ss") : ''}
                          label={t('Expiration date')}
                          className={styles.field}
                          color="primary"
                          InputLabelProps={{ shrink: true }}
                          variant="standard"
                          type="datetime-local"
                        />
                      )}
                    />

                    <Checkbox
                      color="primary"
                      className={styles.field}
                      checked={expirationTimestampChecked}
                      onChange={() => {
                        setExperationTimestamp(!expirationTimestampChecked)
                        trigger('expiration_timestamp');
                        // unsetting timestamp
                        if (rfId?.expiration_timestamp && expirationTimestampChecked) {
                          setValue("expiration_timestamp", null, { shouldDirty: true })
                        }
                      }}
                    />

                  </div>

                  <Button
                    disabled={!isDirty || !isValid}
                    style={{ marginLeft: 'auto' }}
                    size="medium"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >{t(!rfId ? 'Create' : 'Save')}
                  </Button>

                  <Button
                    style={{ marginLeft: 4 }}
                    size="medium"
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => {
                      closeEdit()
                    }}
                  >
                    {t('Cancel')}
                  </Button>

                </form>
              </CardContent>
            </Box>
          </Card>
        ) : null}
      </div>
    </Backdrop>
  );
};

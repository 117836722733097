export default {
  translation: {
    'uk': 'English (UK)',
    'en': 'English (UK)',
    'fi': 'Suomi',
    'se': 'Svenska',
    'Name': 'Namn',
    'Email': 'Email',
    'Password': 'Lösenord',
    'Login': 'Logga in',
    'Language same as region': 'Samma språk som region',
    'Rename charging station': 'Byt namn på laddningsstation',
    'Vendor': 'Tillverkare',
    'Model': 'Modell',
    'Serial code': 'Serienummer',
    'Current firmware': 'Nuvarande firmware',
    'Custom firmware URL': 'Anpassad firmware-URL',
    'Change firmware': 'Ändra firmware',
    'Remove all reservations': 'Ta bort alla reservationer',
    'Reboot device': 'Starta om enheten',
    'Flash LED': 'Flash LED',
    'Select firmware version:': 'Välj firmware-version:',
    'Official firmware version:': 'Officiell firmware-version:',
    'Unofficial firmware version:': 'Inofficiell firmware-version:',
    'Documentation': 'Dokumentation',
    'Product information': 'Produktinformation',
    'Support': 'Support',
    'Download GARO Connect': 'Ladda ner GARO Connect',
    'GET IT ON': 'HÄMTA DEN PÅ',
    'Manual': 'Manual',
    'Quick Guide': 'Snabbguide',
    'Polemount manual': 'Stolpfäste manual',
    'Stand manual': 'Markstativ manual',
    'Installation kit': 'Installationskit',
    'Using Proxy': 'Använder proxy',
    'Online': 'Online',
    'Offline': 'Offline',
    'Generating logfile': 'Skapar loggfil',
    'Generate logfile': 'Skapa loggfil',
    'Currently this portal is for service tecnicians only.': 'För närvarande är denna portal endast för servicetekniker.',
    'Restart software': 'Starta om mjukvara',
    'Theft block status:': 'Stöldskydd status:',
    'CE Declaration GARO Entity Pro': 'CE Deklaration GARO Entity Pro',
    'CE Declaration GARO Entity Balance': 'CE Deklaration GARO Entity Balance',
    'CE Declaration GARO Entity Pro Stand': 'CE Deklaration GARO Entity Pro markstativ',
    'GARO source code': 'GARO källkod',
    'Current schedule': 'Aktuellt schema',
    'Edited schedule': 'Redigerat schema',
    'Monday': 'Måndag',
    'Tuesday': 'Tisdag',
    'Wednesday': 'Onsdag',
    'Thursday': 'Torsdag',
    'Friday': 'Fredag',
    'Saturday': 'Lördag',
    'Sunday': 'Söndag',
    'Weekly': 'Veckovis',
    'One time': 'En gång',
    'Delete event': 'Ta bort händelse',
    'Delete description': 'Vill du ta bort schemahändelsen permanent?',
    'No': 'Nej',
    'Yes': 'Ja',
    'Start time': 'Starttid',
    'Stop time': 'Sluttid',
    'Free of charge': 'Gratis laddning',
    'Sync configuration always on boot': 'Synkronisera alltid konfiguration vid uppstart',
    'Ignore unknown keys on sync': 'Ignorera okända nycklar vid synkronisering',
    'Update firmware automatically': 'Uppdatera firmware automatiskt',
    'Sync configuration as best effort': 'Synkronisera konfiguration så gott det går',
    'Store warning': 'Varning: Värdet kommer inte att sparas i CSMS och kan därför skrivas över vid nästa omstart av enheten',
    'Store in CSMS': 'Spara i CSMS',
    'Key': 'Nyckel',
    'Value': 'Värde',
    'Set Parameter Value': 'Ange parametervärde',
    'Get Parameter Value': 'Hämta parametervärde',
    'Command': 'Kommando',
    'Add command': 'Lägg till kommando',
    'Execute Command': 'Utför kommando',
    'Received data': 'Mottagen data',
    'Snackbar copy message': 'Kopiera till urklipp',
    'Tooltip copy message': 'Kopierat till urklipp',
    'Update status': 'Uppdatera status',
  },
};
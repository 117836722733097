
import { ApiResponse } from 'src/core';

import { CloudApi } from './cloud-api';

const CONNECTION_GROUPS_RESOURCE_PATH = 'connection-groups';

export class ConnectionGroupsApi extends CloudApi {
  async getConnectionGroups(locationId: string): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      location_id: locationId,
    });

    return await this.GET(`${CONNECTION_GROUPS_RESOURCE_PATH}${queryString}`);
  }
}

import { ApiResponse } from 'src/core';
import { Rfid } from 'src/store/model';
import { CloudApi } from './cloud-api';

const RFID_RESOURCE_PATH = 'rfid-tokens';

export class RfidTokenApi extends CloudApi {
  async getRfIdKeys(user_id: string): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      user_id: user_id,
    });
    return await this.GET(`${RFID_RESOURCE_PATH}${queryString}`);
  }

  async createRfIdKey(rfId: Rfid): Promise<ApiResponse> {
    return await this.POST(`${RFID_RESOURCE_PATH}`, rfId);
  }

  async updateRfIdKey(rfId: Rfid): Promise<ApiResponse> {
    const { id, name, id_token, expiration_timestamp, user_id } = rfId;
    return await this.PUT(`${RFID_RESOURCE_PATH}/${id}`, {
      name,
      id_token,
      expiration_timestamp,
      user_id
    });
  }

  async deleteRfIdKey(id: string): Promise<ApiResponse> {
    return await this.DELETE(`${RFID_RESOURCE_PATH}/${id}`);
  }
}

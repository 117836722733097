import {
  Backdrop, Box, Button, Card, CardContent,
  Typography, Divider
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rfid } from 'src/store/model';
import styles from './delete-rfid.module.scss';

type Props = {
  rfId: Rfid | undefined;
  deleteRfId: (id: string) => void;
  closeEdit: () => void;
  open: boolean;
};

export const DeleteRfid = ({
  rfId,
  open,
  closeEdit,
  deleteRfId
}: Props) => {

  const { t } = useTranslation();

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={closeEdit}
    >
      <div
        style={{
          position: 'absolute',
          zIndex: +1,
          left: '50%',
          top: '30%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        {rfId ? (
          <Card style={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent style={{ flex: '1 0 auto' }}>

              <Typography component="div" variant="h5">
                  {t('Delete RFID tag')}
                </Typography>
                <Divider sx={{ marginTop: 0, marginBottom: 2 }} />

                <div className={styles.rfIdDetailsRow}>
                <Typography>
                  Key: {rfId.id_token}
                </Typography>
                </div>

                <div className={styles.rfIdDetailsRow}>
                  <Typography
                    className={styles.field}
                  >Name: {rfId.name}</Typography>
                </div>
                
                <Button
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  size="medium"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={() => {
                    deleteRfId(rfId.id ?? '');
                    closeEdit();
                  }}
                >
                  {t('Delete')}
                </Button>
                <Button
                  style={{ marginLeft: 4 }}
                  size="medium"
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={closeEdit}
                >
                  {t('Cancel')}
                </Button>
              </CardContent>
            </Box>
          </Card>
        ) : null}
      </div>
    </Backdrop>
  );
};

import { CardHeader, Typography } from '@mui/material';
import React from 'react';
import { useStores } from 'src/store';
import styles from './location-status-header.module.scss';

export const LocationStatusHeader = () => {

  const { chargingStationStore, installationStore } = useStores();

  const getLocationName = () => {
    const locationName = chargingStationStore.currentChargingStationLocationName;
    if (locationName) {
      return locationName;
    }
    return '';
  };
  
  const getOwnersName = () => {
    if (chargingStationStore.chargingStationOwners) {
      return 'Owners: ' + chargingStationStore.chargingStationOwners.join('; ');
    }
  };

  const getInstallationCode = () => {
    const locationId = chargingStationStore.currentChargingStation?.location_id ?? '';
    const installationCode = installationStore.getInstallationCode(locationId);
    if (installationCode) {
      return installationCode;
    }
    return <div className={styles.loading}>Loading...</div>;
  };

  return (
    <CardHeader
      title={getLocationName()}
      titleTypographyProps={{ variant: 'h5' }}
      subheader={
        <Typography variant="subtitle2">
          {getOwnersName()}
          <br />
          Installation code: {getInstallationCode()}
        </Typography>
      }
    />
  );
};

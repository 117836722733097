function getStation(name, uid, serial, isMaster) {
  var station = {
    name: name,
    uid: uid,
    serial: serial,
    netInt: "",
    isMaster: isMaster,
    info: "",
    dlmMaster: "",
    dlmSlaves: [],
    meshSlaves: [],
    heartBeat: "",
    version: "",
    csId: "",
  };
  return station;
}
function isMaster(conf) {
  for (var i = 0; i < conf.length; i++) {
    var confItem = conf[i];
    if (confItem.key == "GaroConnectionGroupMaster") {
      return confItem.value;
    }
  }
  return false;
}
function setSlaves(station, conf) {
  for (var i = 0; i < conf.length; i++) {
    var confItem = conf[i];
    if (confItem.key.startsWith("GaroConnectionGroupDevices")) {
      if (typeof confItem.value === 'string') {
        var val = confItem.value.split(",");
        for (var j = 0; j < val.length; j++) {
          var b = val[j].includes(":");
          var d = val[j].includes("<");
          var m = val[j].includes(">");
          var uid = val[j].split(/:|<|>/)[0];
          if (b) {
            station.dlmSlaves.push(uid);
            station.meshSlaves.push(uid);
          }
          if (d) {
            station.dlmSlaves.push(uid);
          }
          if (m) {
            station.meshSlaves.push(uid);
          }
        }
      }
    }
  }
}
function getConfValue(station, conf, key) {
  for (var i = 0; i < conf.length; i++) {
    var confItem = conf[i];
    if (confItem.key.trim() == key.trim()) {
      return confItem.value;
    }
  }
  return null;
}
function getSlaveString(stations, uid) {
  var str = "";
  for (var i = 0; i < stations.length; i++) {
    if (stations[i].uid == uid) {
      str += getBaseString(stations[i]);
    }
  }
  return str;
}
function getBaseString(station) {
  return (
    station.name +
    ", " +
    station.uid +
    ", " +
    station.serial +
    ", " +
    getMac(station.serial) +
    ", :" +
    station.netInt +
    ":" +
    "\n"
  );
}
function getMac(serial) {
  var mac = "";
  try {
    for (var i = 0; i < serial.length; i++) {
      mac += serial[i];
      if ((i - 1) % 2 == 0 && i < serial.length - 1) {
        mac += ":";
      }
    }
    return mac.toLocaleLowerCase();
  } catch (e) {
    return "";
  }
}
function getStationObj(stations, uid) {
  for (var i = 0; i < stations.length; i++) {
    if (stations[i].uid === uid) {
      return stations[i];
    }
  }
  return null;
}
function spaces(level) {
  var str = "";
  for (var i = 0; i < level; i++) {
    str += " ";
  }
  return str;
}
function printDlmSlaves(stations, dlmSlaves, level) {
  var l = level + 2;
  let str = '';
  for (var i = 0; i < dlmSlaves.length; i++) {
    var sep = "| ";
    var station = getStationObj(stations, dlmSlaves[i]);
    if (station !== null) {
      if (station.dlmSlaves.length > 0) {
        sep = "- ";
      } else if (dlmSlaves.length - 1 == i) {
        sep = "\\ ";
      }
      str += spaces(l) + sep + getBaseString(station);
      str += printDlmSlaves(stations, station.dlmSlaves, l);
    } else {
      // no slave found in data
      if (dlmSlaves.length - 1 == i) {
        sep = "\\ ";
      }
      str += spaces(l) + sep + " <i>" + dlmSlaves[i] + "</i>\n";
    }
  }
  return str;
}

function printDlmTree(stations) {
  let str = 'dlmtree';
  for (var i = 0; i < stations.length; i++) {
    if (stations[i].dlmMaster === "" && stations[i].isMaster) {
      str += "- " + getBaseString(stations[i]);
      str += printDlmSlaves(stations, stations[i].dlmSlaves, 0);
    }
  }
  str += "nodlm";
  for (var ii = 0; ii < stations.length; ii++) {
    if (stations[ii].dlmMaster === "" && !stations[ii].isMaster) {
      str += getBaseString(stations[ii]);
    }
  }
  return str;
}
function findMyDlmMaster(stations, uid) {
  var dm = "";
  for (var i = 0; i < stations.length; i++) {
    if (stations[i].dlmSlaves.length > 0) {
      for (var j = 0; j < stations[i].dlmSlaves.length; j++) {
        if (stations[i].dlmSlaves[j] === uid) {
          return stations[i].uid;
        }
      }
    }
  }
  return dm;
}
function setDlmMasters(stations) {
  for (var i = 0; i < stations.length; i++) {
    stations[i].dlmMaster = findMyDlmMaster(stations, stations[i].uid);
  }
}
function appendMeshInfo(stations) {
  var str = "network";
  for (var i = 0; i < stations.length; i++) {
    if (stations[i].meshSlaves.length > 0) {
      str += getBaseString(stations[i]);
      for (var j = 0; j < stations[i].meshSlaves.length; j++) {
        str += "  " + getSlaveString(stations, stations[i].meshSlaves[j]);
      }
    }
  }
  return str;
}
export function load(data) {
  const stations = [];
    const location = '';
    for (let i = 0; i < data.items.length; i++) {
      const item = data.items[i];
      if (location.length > 0 && location !== item.location_name) {
        continue;
      }
      const master = isMaster(item.charging_station.configuration);
      const station = getStation(
        item.charging_station.name,
        item.charging_station.uid,
        item.charging_station.charging_unit.serial_number,
        master
      );
      station.heartBeat = item.charging_station.status.heartbeat_timestamp;
      station.version = item.charging_station.charging_unit.firmware_version;
      station.csId = item.charging_station.id;
      setSlaves(station, item.charging_station.configuration);
      const nI = getConfValue(
        station,
        item.charging_station.configuration,
        "GaroNetworkInterface"
      );
      station.netInt = nI == null ? "" : nI;
      stations.push(station);
    }
    //stations.sort(uidSort)
    setDlmMasters(stations);
    stations.sort(dlmMasterSort);
    let dlmTree = printDlmTree(stations);
    dlmTree += appendMeshInfo(stations);
    return dlmTree;
}

function dlmMasterSort(a, b) {
  return a.dlmMaster < b.dlmMaster
    ? -1
    : a.dlmMaster > b.dlmMaster
    ? 1
    : 0;
}
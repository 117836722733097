import styles from './login.module.scss';
import { Button, Card, TextField, Alert } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'src/store/RootStore';
import { useLoggedIn } from '../../../hooks';
import { Loader, MfaOptions } from '../../../components';
import { useTranslation } from 'react-i18next';
import { EnterMfaCode } from './enter-mfa-code';

export const Login = observer(() => {
  const { userStore, chargingStationStore } = useStores();
  const navigate = useNavigate();

  useLoggedIn(userStore, chargingStationStore);
  const { t } = useTranslation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [showDisclaimer, setDisclaimer] = useState(false);

  const onSubmit = () => {
    userStore.authenticate(username, password).then(() => {
      userStore.authorize().then(() => {
        setDisclaimer(!userStore.userIsServiceTechnician);
      });
    });
  };

  useEffect(() => {
    if (
      userStore.isLoggedIn &&
      !userStore.needToEnterMfaCode &&
      !userStore.needToEnableMfa
    ) {
      setDisclaimer(false);
      navigate('/service-tech');
    }
  }, [
    navigate,
    userStore.isLoggedIn,
    userStore.needToEnterMfaCode,
    userStore.needToEnableMfa,
  ]);

  return (
    <Card style={{ borderRadius: 0 }} className={styles.loginCard}>
      {showDisclaimer && (
        <Alert variant="filled" severity="info">
          {t('Currently this portal is for service tecnicians only.')}
        </Alert>
      )}
      <div className={styles.loginForm}>
        {userStore.needToEnableMfa ? (
          <MfaOptions />
        ) : userStore.needToEnterMfaCode ? (
          <>
            <EnterMfaCode />
          </>
        ) : (
          <>
            <div className={styles.logo} />

            <TextField
              color="primary"
              type="username"
              className={styles.loginInput}
              label={t('Email')}
              variant="standard"
              defaultValue=""
              onChange={(e) => setUsername(e.target.value)}
            />

            {/* {errors.username && <span>This field is required</span>} */}

            <TextField
              type="password"
              className={styles.loginInput}
              variant="standard"
              label={t('Password')}
              defaultValue=""
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              disabled={password.length === 0 || username.length === 0}
              className={styles.submitButton}
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              onClick={onSubmit}>
              {t('Login')}
            </Button>
          </>
        )}

        {userStore.isLoading && (
          <div className={styles.loggingIn}>
            <Loader />
          </div>
        )}
      </div>
    </Card>
  );
});

import React from "react";
import styles from "./drop-zone.module.css";

interface Props {
    onClick: () => void;
    onDrop: (file: File) => void;
}

const Banner = ({ onClick, onDrop }: Props) => {
  const handleDragOver = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDrop(ev.dataTransfer.files);
  };

  return (
    <div className={styles.banner} onClick={onClick} onDragOver={handleDragOver} onDrop={handleDrop}>
      <span className={styles.banner_text}>Click to add .pem file</span>
      <span className={styles.banner_text}>or</span>
      <span className={styles.banner_text}>Drag & Drop .pem file here</span>
    </div>
  );
};

const DropZone = ({ onChange }: any) => {
  const inputRef = React.useRef<any>(null);
  
  return (
    <div>
      <Banner 
        onClick={() => {
          if (inputRef.current) {
              inputRef.current.click();
          }
        }} 
        onDrop={(files: any) => {
          onChange(files[0]);
        }}
      />
      <input
        type="file"
        aria-label="add files"
        className={styles.input}
        ref={inputRef}
        onChange={(f) => onChange(f.target.files?.[0])}
        accept=".pem"
      />
    </div>
  );
};
  
DropZone.propTypes = {
  onChange: Function
};

export { DropZone };
import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import { Amplify } from 'aws-amplify';
import { useStores } from 'src/store';
import { observer } from 'mobx-react-lite';
import { AwsConfig } from 'src/core/aws-config/AwsConfig';
import { DownloadTheApp } from 'src/components';
import { useNavigate } from 'react-router-dom';

export type MfaOptionProps = {
  removeMfa?: boolean;
  closeModal?: () => void;
};

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const mfaAwsConfig = {
  Auth: {
    endpoint: '',
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_MFA_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType:
      process.env.REACT_APP_AWS_COGNITO_AUTHENTICATION_FLOW_TYPE,

    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    oauth: {
      domain: `https://${process.env.NODE_ENV}-auth.garo-next-gen.com`,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `https://${process.env.NODE_ENV}.garo-next-gen.com`,

      redirectSignOut: `https://${process.env.NODE_ENV}.garo-next-gen.com`,

      responseType: 'code',
    },
  },
};

export const MfaOptions = observer(
  ({ closeModal, removeMfa = false }: MfaOptionProps) => {
    const { userStore } = useStores();
    const [challengeAnswer, setChallengeAnswer] = useState('');
    const [emailState, setEmailState] = useState('');
    const [passwordState, setPasswordState] = useState('');

    useEffect(() => {
      Amplify.configure(mfaAwsConfig);
    }, []);

    const onSubmit = async () => {
      Amplify.configure(mfaAwsConfig);

      if (removeMfa) {
        await userStore.disableMfa();
      } else {
        await userStore.enableMfa(challengeAnswer);
      }
      Amplify.configure(AwsConfig);
      userStore.confirmAuth(challengeAnswer);
      closeModal && closeModal();
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {!removeMfa && userStore.totpQRCode && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <br />
            <QRCode value={userStore.totpQRCode} />

            <Typography
              style={{
                marginBottom: '1rem',
                marginTop: '1rem',
                textAlign: 'center',
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
              component="div">
              {userStore.isLoggedIn
                ? 'To update MFA (Multi Factor Authentication), please scan the QR code with your authenticator app and enter the code below. Remember to remove the old MFA device from your authenticator app.'
                : 'To enable MFA (Multi Factor Authentication), please scan the QR code with your authenticator app and enter the code below.'}
            </Typography>
          </div>
        )}

        {!userStore.totpQRCode && (
          <>
            <TextField
              variant="standard"
              style={{
                width: '300px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              label="Email"
              defaultValue=""
              onChange={(e) => setEmailState(e.target.value)}
            />

            <TextField
              variant="standard"
              style={{ width: '300px', marginBottom: '1rem' }}
              label="Password"
              type="password"
              defaultValue=""
              onChange={(e) => setPasswordState(e.target.value)}
            />
          </>
        )}

        {!removeMfa && !userStore.totpQRCode && (
          <Button
            disabled={!emailState || !passwordState}
            size="medium"
            style={{ marginTop: '1rem', marginBottom: '1rem', width: '300px' }}
            variant="contained"
            color="primary"
            onClick={() =>
              userStore.generateTotpQrCode(emailState, passwordState)
            }>
            Generate QR Code
          </Button>
        )}

        {(removeMfa ? true : userStore.totpQRCode) && (
          <>
            <TextField
              variant="standard"
              label="Code"
              defaultValue=""
              onChange={(e) => setChallengeAnswer(e.target.value)}
            />

            <Button
              disabled={
                removeMfa
                  ? !challengeAnswer
                  : !challengeAnswer || !emailState || !passwordState
              }
              size="medium"
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                width: '300px',
              }}
              variant="contained"
              color="primary"
              onClick={onSubmit}>
              {removeMfa ? 'Disable MFA and log out' : userStore.isLoggedIn ? 'Update MFA' : 'Enable MFA'}
            </Button>
          </>
        )}

        {removeMfa && (
          <Typography
            style={{
              textAlign: 'center',
              marginLeft: '1rem',
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
            component="div">
            If you like to remove MFA, please enter your login details and the
            code you received from your authenticator app. Then click on the
            button above.
            <br />
            Note: To be able to use the Service Technician Portal with full
            functionality, you need to have MFA enabled.
          </Typography>
        )}

        {!removeMfa && (
          <>
            <Typography
              style={{
                textAlign: 'center',
                marginLeft: '1rem',
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
              component="div">
              Note: If you dont have an authenticator app, you can download one
              from the App Store or Google Play. For example, Google
              Authenticator.
            </Typography>
            <DownloadTheApp
              showTitle={false}
              iosLink="https://apps.apple.com/gb/app/google-authenticator/id388497605"
              androidLink="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US"
            />
          </>
        )}
      </div>
    );
  },
);

import { SnapshotIn, types } from 'mobx-state-tree';

export const Modem = types.model({
  id: types.maybeNull(types.string),
  iccid: types.maybeNull(types.string),
  imsi: types.maybeNull(types.string),
});

export const ChargingUnit = types.model({
  id: types.maybeNull(types.string),
  serial_number: types.maybeNull(types.string),
  vendor_name: types.maybeNull(types.string),
  model: types.maybeNull(types.string),
  firmware_version: types.maybeNull(types.string),
  modem: types.maybeNull(Modem),
  charging_station_id: types.maybeNull(types.string),
});

export interface ChargingUnit extends SnapshotIn<typeof ChargingUnit> {}

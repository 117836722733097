

import { ApiResponse } from 'src/core';
import { ChargingStationInstallation } from 'src/store/model';

import { CloudApi } from './cloud-api';

export const CSINSTALLATION_RESOURCE_PATH = 'charging-station-installation';

export class ChargingStationInstallationApi extends CloudApi {
  async getChargingStationInstallation(
    chargingStationId?: string,
  ): Promise<ApiResponse> {
    return await this.GET(
      `${CSINSTALLATION_RESOURCE_PATH}/${chargingStationId}`,
    );
  }

  async updateChargingStationInstallation(
    chargingStationInstallation: ChargingStationInstallation,
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${CSINSTALLATION_RESOURCE_PATH}/${chargingStationInstallation.charging_station_id}`,
      chargingStationInstallation,
    );
  }
}

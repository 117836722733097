import { Checkbox, FormControlLabel} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStores } from 'src/store';
import { useTranslation } from 'react-i18next';
import styles from './charging-station-general-details.module.scss';
import { observer } from 'mobx-react-lite';

export const ChargingStationSettings = observer(() => {
  const { chargingStationStore } = useStores();
  const { t } = useTranslation();

  const [syncOnBoot, setSyncOnBoot] = useState(false);
  const [ignoreKeysOnSync, setIgnoreKeysOnSync] = useState(false);
  const [automaticFirmwareUpdate, setAutomaticFirmwareUpdate] = useState(false);
  const [syncAsBestEffort, setSyncAsBestEffort] = useState(false);
  
  useEffect(() => {
    setSyncOnBoot(chargingStationStore.isCurrentChargingStationSyncOnBoot);
    setIgnoreKeysOnSync(chargingStationStore.isCurrentChargingStationIgnoreOnSync);
    setAutomaticFirmwareUpdate(chargingStationStore.isCurrentChargingStationAutomaticFirmwareUpdate);
    setSyncAsBestEffort(chargingStationStore.isCurrentChargingStationSyncAsBestEffort);
  }, [
    chargingStationStore.isCurrentChargingStationSyncOnBoot, 
    chargingStationStore.isCurrentChargingStationIgnoreOnSync, 
    chargingStationStore.isCurrentChargingStationAutomaticFirmwareUpdate,
    chargingStationStore.isCurrentChargingStationSyncAsBestEffort
  ]);

  const handleSyncOnBoot = () => {
    setSyncOnBoot(!syncOnBoot);
    chargingStationStore.toggleSyncOnBoot();
  }

  const handleIgnoreKeysOnSync = () => {
    setIgnoreKeysOnSync(!ignoreKeysOnSync);
    chargingStationStore.toggleIgnoreKeysOnSync();
  }

  const handleAutomaticFirmwareUpdate = () => {
    setAutomaticFirmwareUpdate(!automaticFirmwareUpdate);
    chargingStationStore.toggleAutomaticFirmwareUpdate();
  }

  const handleSyncAsBestEffort = () => {
    setSyncAsBestEffort(!syncAsBestEffort);
    chargingStationStore.toggleSyncAsBesteffort();
  }

  return (
    <>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControlLabel control={
          <Checkbox 
            checked={syncOnBoot}
            onChange={handleSyncOnBoot}/>
        } label={t('Sync configuration always on boot')} />
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControlLabel control={
          <Checkbox 
            checked={syncAsBestEffort}
            onChange={handleSyncAsBestEffort}/>
        } label={t('Sync configuration as best effort')} />
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControlLabel control={
          <Checkbox 
            checked={ignoreKeysOnSync}
            onChange={handleIgnoreKeysOnSync}/>
        } label={t('Ignore unknown keys on sync')} />
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControlLabel control={
          <Checkbox 
            checked={automaticFirmwareUpdate}
            onChange={handleAutomaticFirmwareUpdate}/>
        } label={t('Update firmware automatically')} />
      </div>
    </>
  );
});

import { SnapshotIn, types } from 'mobx-state-tree';

export const Rfid = types.model({
  id: types.identifier,
  id_token: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  expiration_timestamp: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  user_id: types.maybeNull(types.string),
});

export interface Rfid extends SnapshotIn<typeof Rfid> { }

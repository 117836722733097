import { SnapshotIn, types } from 'mobx-state-tree';

export const Location = types.model({
  id: types.maybeNull(types.string),
  organization_id: types.maybeNull(types.string),
  default: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  street: types.maybeNull(types.string),
  postal_code: types.maybeNull(types.string),
  latitude: types.maybeNull(types.string),
  longitude: types.maybeNull(types.string),
});

export interface Location extends SnapshotIn<typeof Location> {}

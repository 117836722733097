import { Card, CardContent, Grid, Link, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStores } from 'src/store';
import { useTranslation } from 'react-i18next';
import { ChargingStation } from 'src/store/model';
import styles from './charging-station-general-details.module.scss';
import * as dateFns from 'date-fns';
import { useNavigate } from 'react-router-dom';

type Props = {
  chargingStation: ChargingStation | undefined | null;
};

export const ChargingStationInfo = ({ chargingStation }: Props) => {
  const { chargingStationStore, userStore } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const checkValue = (val: string | undefined | null): string => {
    return val ? val : 'Loading...';
  }

  const gotoUserView = (email: any) => {
    console.log('Goto', email);
    console.log('User2', userStore.getAUser(email));
    userStore.getAUser(email).then((user) => {
      console.log('User', user[0].id);
      navigate(`/service-tech/users/${user[0]?.id}`);
    });
  }

  return (
    <div className={styles.chargingStationGeneralDetails}>
      <Grid container spacing={1}>
        <Grid item width={400}>
          <div className={styles.chargingStationGeneralDetailsRow}>
            <TextField
              className={styles.field}
              id="standard-name"
              label={t('Name')}
              variant="standard"
              disabled={true}
              value={chargingStation?.name}
            />
          </div>
          <TextField
            label={t('Vendor')}
            defaultValue={chargingStation?.charging_unit?.vendor_name}
            variant="standard"
            disabled={true}
            className={styles.field}
          />

          <TextField
            label={t('Model')}
            defaultValue={chargingStation?.charging_unit?.model}
            variant="standard"
            disabled={true}
            className={styles.field}
          />
          <TextField
            label={t('CU number')}
            value={checkValue(chargingStation?.charging_unit?.serial_number)}
            variant="standard"
            disabled={true}
            className={styles.field}
          />
          <TextField
            label={t('Serial code')}
            value={checkValue(chargingStation?.uid)}
            variant="standard"
            disabled={true}
            className={styles.field}
          />
          <TextField
            label={t('Group key')}
            value={checkValue(chargingStationStore.groupKey)}
            variant="standard"
            disabled={true}
            className={styles.field}
          />
          <TextField
            label={t('Last heartbeat')}
            value={dateFns.format(
                new Date(chargingStation?.status?.heartbeat_timestamp as any),
                'yyyy-MM-dd HH:mm:ss'
                )}
            variant="standard"
            disabled={true}
            className={styles.field}
          />
        </Grid>
        <Grid item width={400}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h6" component="div">Owners</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {chargingStationStore.chargingStationOwners.map(i => (
                  <>
                    <Link
                      key={i}
                      underline="none"
                      component="button"
                      variant="body2"
                      onClick={() => gotoUserView(i)}
                    >
                      {i}
                    </Link>
                    <br/>
                  </>
                ))}
              </Typography>
              <Typography variant="h6" component="div">Installers</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {chargingStationStore.chargingStationInstallers.map(i => (
                  <>
                    <Link
                      key={i}
                      underline="none"
                      component="button"
                      variant="body2"
                      onClick={() => gotoUserView(i)}
                    >
                      {i}
                    </Link>
                    <br/>
                  </>
                ))}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

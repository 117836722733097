import { SnapshotIn, types } from 'mobx-state-tree';

export const ChargingStationSettings = types.model({
  sync_configuration_always_on_boot: types.boolean,
  ignore_unknown_keys_on_sync: types.boolean,
  update_firmware_automatically: types.boolean,
  free_of_charge: types.maybeNull(types.boolean),
  sync_configuration_as_best_effort: types.maybeNull(types.boolean),
});

export type ChargingStationSettings = SnapshotIn<typeof ChargingStationSettings>

import { types } from 'mobx-state-tree';
import i18n from 'src/i18n/i18n';

export enum CountryCodes {
  UK = 'United Kingdom',
  SE = 'Sweden',
  NO = 'Norway',
  FI = 'Finland',
  IE = 'Ireland',
  PL = 'Poland',
  DE = 'Germany'
}

export enum LanguageCodes {
  UK = 'English (UK)',
  SE = 'Svenska',
  NO = 'Norsk',
  FI = 'Suomi',
  PL = 'Polski',
  DE = 'Deutsch'
}

export const countryCodeKeys = Object.keys(CountryCodes);
export const languageCodeKeys = Object.keys(LanguageCodes);


export const SessionStore = types
  .model({
    region: types.optional(types.enumeration([...countryCodeKeys,  '']), ''),
    languageSameAsRegion: types.optional(types.boolean, true),
  })
  .views(self => ({
    get language() {
      return self.languageSameAsRegion ? self.region : i18n.language;
    }
  }))
  .actions((self) => {
    const setRegion = (region: typeof self.region) => {
      self.region = region;
    }

    const setLanguage = (language: string) => {
      i18n.changeLanguage(language.toLowerCase());
    }

    const setLanguageSameAsRegion = (same: boolean) => {
      self.languageSameAsRegion = same;
    }

    return {
      setRegion,
      setLanguage,
      setLanguageSameAsRegion,
    };
  });

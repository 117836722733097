import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import globalStyles from '../../../../global-styles.module.scss';
import { ChargingStationStatusHeader } from './charging-station-status-header';
import { useStores } from 'src/store';
import { BlocklistItem, BlockStatus, ChargingStation, ChargingStationReservation } from 'src/store/model';
import styles from './charging-station-actions.module.scss';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  chargingStation: ChargingStation | undefined | null;
  blocklistItem: BlocklistItem;
  blockList: (blockStatus: BlockStatus) => void;
  flashLed: () => void;
  reservations: ChargingStationReservation[];
  clearReservations: () => void;
};
export const ChargingStationActions = ({
  chargingStation,
  blocklistItem, 
  blockList,
  flashLed,
  reservations,
  clearReservations,
}: Props) => {
  const {
    chargingStationStore,
  } = useStores();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openDeleteDialog, showDeleteDialog] = useState(false);

  const confirmDeleteChargingStation = () => {
    showDeleteDialog(true);
  };

  const handleDelete = () => {
    if (!chargingStation) {
      return;
    }
    chargingStationStore.removeChargingStation(chargingStation).then(() => {
      showDeleteDialog(false);
      navigate('/service-tech/charging-stations');   
    });
  }

  const handleCancelDelete = () => {
    showDeleteDialog(false);
  }

  return (
    <div className={styles.chargingStationGeneralDetails}>
      <Card className={globalStyles.container}>
        <ChargingStationStatusHeader />
        <CardContent style={{ minHeight: 650 }}>
          <Box
            sx={{
              marginLeft: 33,
              component: "section",
            }}
          >
          <div className={styles.chargingStationGeneralDetailsRow}>
            <FormControl style={{ width: 320 }} variant="standard">
              <InputLabel id="select-label">{t('Theft block status:')}</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                fullWidth={true}
                value={blocklistItem.status ?? BlockStatus.Unblocked}
                onChange={(s) => blockList(s.target.value as BlockStatus)}>
                {Object.values(BlockStatus).map((status) => (
                  <MenuItem key={status} value={status ?? ''}>
                    {`${status}`}
                  </MenuItem>
                ))}
              </Select>
              {blocklistItem.blocklisted_since && (
                <Typography>
                  Since:{' '}
                  {dateFns.format(
                    new Date(blocklistItem.blocklisted_since),
                    'yyyy-MM-dd HH:mm',
                  )}
                </Typography>
              )}
            </FormControl>
          </div>
          <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
          }}
          >
            <Button style={{ width: 300 }} size="medium" onClick={flashLed}>
              {t('Flash LED')}
            </Button>
            {reservations.length > 0 && (
              <Button
                style={{ width: 300 }}
                size="medium"
                color="primary"
                onClick={clearReservations}>
                {t('Remove all reservations')}
              </Button>
            )}

            <Button
              style={{ width: 300 }}
              size="medium"
              color="warning"
              onClick={confirmDeleteChargingStation}>
              {t('Delete device')}
            </Button>

            <Dialog
              open={openDeleteDialog}
              onClose={handleCancelDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('Delete charging station: ') + chargingStation?.name}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('Do you really want to delete this device?')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelDelete}>Cancel</Button>
                <Button onClick={handleDelete} autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

          </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
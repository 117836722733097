import React from 'react'
import styles from './download-the-app.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  iosLink: string,
  androidLink: string,
  showTitle?: boolean
}

export const DownloadTheApp = ({iosLink, androidLink, showTitle = true}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.downloadApp}>
    {showTitle && <h1>{t('Download GARO Connect')}</h1>}
    <div className={styles.buttonContainer}>
      <a
        href={iosLink}
        rel="noreferrer"
        className={styles.downloadAppButton}
        target="_blank"
      >
        <img
          className={styles.picture}
          src={require(`../../images/apple.png`)}
        ></img>
        <div className={styles.textContainer}>
          <span className={styles.getItOn}>{t('GET IT ON')}</span>
          <span className={styles.store}>{t('App Store')}</span>
        </div>
      </a>

      <a
        href={androidLink}
        rel="noreferrer"
        className={styles.downloadAppButton}
        style={{ marginRight: '0px' }}
        target="_blank"
      >
       <img
          className={styles.picture}
          src={require(`../../images/google.png`)}
        ></img>
        <div className={styles.textContainer}>
          <span className={styles.getItOn}>{t('GET IT ON')}</span>
          <span className={styles.store}>{t('Google Play')}</span>
        </div>
      </a>
    </div>
  </div>
  )
}

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
  } from '@mui/material';
  import React from 'react';
  import { useStores } from 'src/store';
  
  export const AccessInstallers = () => {
    const { chargingStationStore } = useStores();
    
    return (
      <Box>
        <Typography
          style={{ marginBottom: '1rem' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Installers
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }}size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="right"></TableCell>
                <TableCell align="right">First name</TableCell>
                <TableCell align="right">Last name</TableCell>
                <TableCell align="right">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
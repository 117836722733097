import { cast, flow, getEnv, getParent, types } from 'mobx-state-tree';

import { ChargingStationConfigurationsApi, ConnectionGroupsApi } from '../api';
import { RootModel } from '.';

import {
  ChargingStationChangeConfigurations,
  ChargingStationConfigurations,
  ChargingStationConfigurationStatus,
  ChargingStationConfigurationVariable,
  ChargingStationConnectionGroup,
  ChargingStationConnectionStatus,
  ChargingStationModelType,
  ChargingStationSchema,
  ChargingStationType,
  GARO_BRACKET_MAX_CURRENT,
  GARO_CONNECTOR_PHASES_VARIABLE_KEY,
  JsonSchemaProperty,
  OCPPVersion,
} from './model';

import { ApiResponse, hexEncode } from 'src/core';
import { CsmsOption } from './model/CsmsOption';

export const ChargingStationConfigurationsStore = types
  .model({
    connectionGroupItems: types.map(ChargingStationConnectionGroup),
    chargingStationConfigurationItems: types.map(ChargingStationConfigurations),
    schemaItems: types.map(ChargingStationSchema),
    supportedDestinationCsmsDict: types.map(types.frozen<CsmsOption>()),
  })
  .volatile(() => ({
    isLoading: {} as { [key: string]: boolean },
    isLoadingConfigurationAndSchema: false,
    currentlyLoadingSchemas: new Map<string, Promise<any>>(),
    isCreatingConnectionGroups: false,
    isLoadingConnectionGroups: true,
  }))
  .views((self) => {
    return {
      get api(): ChargingStationConfigurationsApi {
        return getEnv(self).chargingStationConfigurationsApi;
      },
      get connectionGroupsApi(): ConnectionGroupsApi {
        return getEnv(self).connectionGroupsApi;
      },
      get changeConfiguration(): (
        chargingStationId: string,
        changeConfigurations: ChargingStationChangeConfigurations,
      ) => Promise<ApiResponse | undefined> {
        return (
          chargingStationId: string,
          changeConfigurations: ChargingStationChangeConfigurations,
        ) => {
          const variables = changeConfigurations?.configuration_variables?.map(
            (variable) => {
              const v = { ...variable };
              // Check if type is array and convert to comma separated string
              const schemaItem =
                this.defaultConfigurationForCurrentChargingStation[
                  variable.key
                ];
              if (
                variable?.value instanceof Array &&
                schemaItem &&
                schemaItem.properties.value.type === 'array'
              ) {
                v.value = variable.value?.join(',');
              }
              return v;
            },
          );

          const change = {
            ...changeConfigurations,
            configuration_variables: variables,
          };

          return getParent<typeof RootModel>(
            self,
          ).actionStore.changeConfiguration(chargingStationId, change);
        };
      },
      get currentChargingStationId(): string {
        return getParent<typeof RootModel>(self).chargingStationStore
          .currentChargingStationId;
      },
      get currentChargingStationOnline(): boolean {
        return (
          getParent<typeof RootModel>(self).chargingStationStore
            .currentChargingStationStatus?.connection ===
          ChargingStationConnectionStatus.connected
        );
      },
      get schemas() {
        return Array.from(self.schemaItems.values());
      },
      get currentChargingStationType(): ChargingStationType {
        return getParent<typeof RootModel>(self).chargingStationStore
          .currentChargingStationType;
      },
      get chargingStationType(): (
        chargingStationId: string,
      ) => ChargingStationType {
        return (chargingStationId: string) =>
          getParent<typeof RootModel>(
            self,
          ).chargingStationStore.chargingStationType(chargingStationId);
      },
      get defaultConfigurationForCurrentChargingStation(): {
        [key: string]: JsonSchemaProperty;
      } {
        const chargingStationType = this.currentChargingStationType;
        const modelType =
        chargingStationType !== ChargingStationType.UNIVERSAL
          ? 'entity'
          : 'other';
      const schemaKeysValue = Array.from(
        self.schemaItems
          .get(`${modelType}.ocpp_v16.schema.json`)
          ?.properties.keys() ?? [],
      ).reduce(
        (acc, curr) => [
          ...acc,
          {
            ...self.schemaItems
              .get(`${modelType}.ocpp_v16.schema.json`)
              ?.properties.get(curr),
            key: curr,
          },
        ],
        [] as any[],
      );

        // groups that is relevant.
        const dependantGroups = schemaKeysValue
          .map((item: JsonSchemaProperty) => item.groups)
          .flat()
          .filter((value, index, s) => s.indexOf(value) === index)
          .filter((group) => {
            if (
              chargingStationType === ChargingStationType.GARO_LOAD_INTERFACE
            ) {
              const [model, visible] = (group ?? '')?.split(':') ?? [];
              return model === 'EntityBalance' && visible !== 'false';
            } else if (
              chargingStationType === ChargingStationType.GARO_CHARGING_STATION
            ) {
              const [model, visible] = (group ?? '')?.split(':') ?? [];
              return model !== 'EntityBalance' && visible !== 'false';
            } else {
              return true;
            }
          })
          //Check if group is part of configuration for current charging station (dynamic schema)
          .filter((group) =>
            !group?.includes('EntityPro') &&
            !group?.includes('EntityBalance') &&
            !group?.includes('EntityCompact')
              ? !!self.chargingStationConfigurationItems
                  .get(this.currentChargingStationId)
                  ?.configuration_variables.get(group ?? '')?.value
              : true,
          );

        return (
          schemaKeysValue
            //filter out groups that are not relevant
            .filter((e: JsonSchemaProperty) =>
              chargingStationType !== ChargingStationType.UNIVERSAL
                ? e?.groups?.some((group) => dependantGroups.includes(group))
                : true,
            )
            .map(
              (
                e: JsonSchemaProperty, // Clear the default values showing it blank for write only properties
              ) =>
                e.mutability === 'WriteOnly'
                  ? {
                      ...e,
                      properties: {
                        ...e.properties,
                        value: { ...e.properties.value, default: '' },
                      },
                    }
                  : e,
            )
            .reduce(
              (acc, curr) => Object.assign(acc, { [curr.key ?? '']: curr }),
              {},
            )
        );
      },
      get currentChargingStationConfigurationVariables() {
        return self.chargingStationConfigurationItems.get(
          this.currentChargingStationId,
        )?.configuration_variables;
      },
      get getConfigVariableValueForChargingStation(): (
        id: string,
        key: string,
      ) => any {
        const getVariableFromConfigOrSchema = (
          id: string,
          variableKey: string,
        ) => {
          const configVariable = self.chargingStationConfigurationItems
            .get(id)
            ?.configuration_variables.get(variableKey);

          if (configVariable) {
            return configVariable.value;
          }

          const schemaVariable =
            this.defaultConfigurationForCurrentChargingStation[variableKey];
          if (schemaVariable) {
            return schemaVariable.properties?.value?.default;
          }

          return null;
        };

        return getVariableFromConfigOrSchema;
      },
      get getConfigVariableValueForCurrentChargingStation(): (
        key: string,
      ) => any {
        return (key: string) =>
          this.getConfigVariableValueForChargingStation(
            this.currentChargingStationId,
            key,
          );
      },

      get getMaxCurrentForChargingStation(): (
        chargingStationId: string,
      ) => number {
        return (chargingStationId: string) => {
          const maxCurrent = this.getConfigVariableValueForChargingStation(
            chargingStationId,
            GARO_BRACKET_MAX_CURRENT,
          );

          const noOfPhases =
            this.getConfigVariableValueForChargingStation(
              chargingStationId,
              GARO_CONNECTOR_PHASES_VARIABLE_KEY,
            )?.[0].split('.')?.[1] || 3;
          if (maxCurrent && noOfPhases) {
            return noOfPhases * maxCurrent;
          } else {
            return 3 * 32;
          }
        };
      },
    };
  })
  .actions((self) => {
    const markLoading = (key: string, loading: boolean) => {
      self.isLoading = { [key]: loading };
    };

    const markLoadingConfigurationAndSchema = (loading: boolean) => {
      self.isLoadingConfigurationAndSchema = loading;
    };

    const schemaKeyFromModelAndOcpp = (
      model: ChargingStationModelType,
      ocppVersion: OCPPVersion,
    ) => {
      // @note hide warnings for now
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const [chargerSchema]: any = Object.entries(
        ChargingStationModelType,
      ).find(([_, value]) => value === model);
      const [ocppVer]: any = Object.entries(OCPPVersion).find(
        ([_, value]) => value === ocppVersion,
      );
      const occp = ocppVer.split('_').join('');
      return `${chargerSchema}.ocpp_${occp}.schema.json`;
    };

    const addChargingStationSchema = (schema: ChargingStationSchema) => {
      self.schemaItems.set(schema.$id, cast(schema));
    };

    const addChargingStationConfigurationVariable = (
      chargingStationId: string,
      chargingStationConfigurationVariable: ChargingStationConfigurationVariable,
    ) => {
      // Check if type is array and convert from comma separated string to array
      const schemaItem =
        self.defaultConfigurationForCurrentChargingStation[
          chargingStationConfigurationVariable.key
        ];

      if (
        schemaItem &&
        schemaItem.properties.value.type === 'array' &&
        !schemaItem.properties.value.enum &&
        !!chargingStationConfigurationVariable.value
      ) {
        if (chargingStationConfigurationVariable.value.includes(',')) {
          chargingStationConfigurationVariable.value =
            chargingStationConfigurationVariable.value
              ?.split(',')
              ?.filter((value: any) => !!value) || [];
        } else {
          chargingStationConfigurationVariable.value = [
            chargingStationConfigurationVariable.value,
          ];
        }
      }

      if (!self.chargingStationConfigurationItems.has(chargingStationId)) {
        self.chargingStationConfigurationItems.set(chargingStationId, {
          charging_station_id: chargingStationId,
        });
      }

      self.chargingStationConfigurationItems
        .get(chargingStationId)
        ?.configuration_variables.set(
          chargingStationConfigurationVariable.key,
          cast(chargingStationConfigurationVariable),
        );
    };

    const removeChargingStationConfiguration = (chargingStationId: string) => {
      self.chargingStationConfigurationItems.delete(chargingStationId);
    };

    const clearChargingStationConfigurations = () => {
      self.chargingStationConfigurationItems.clear();
    };

    const getSupportedDestinationCsmsDict = flow(function* () {
      const { data, error } = yield self.api.getSupportedCsmsDict();
      if (error) {
        const err = Array.isArray(error?.detail)
          ? error?.detail?.map((det: { msg: string }) => `${det.msg} `)
          : error?.detail;

        console.log(err);
        return 'Error when loading supported destination CSMS list';
      }
      const csmsMap: { [k: string]: CsmsOption } = data;
      for (const [key, csms] of Object.entries(csmsMap)) {
        self.supportedDestinationCsmsDict.set(key, csms);
      }
    });

    const updateOrCreateConfiguration = flow(function* (
      key: string,
      value: any,
      chargingStationId?: string,
    ) {

      markLoading(key, true);
      const newDefinition =
        !self.currentChargingStationConfigurationVariables?.get(key);

      const updatedVariable: ChargingStationConfigurationVariable = {
        key: key,
        value: value,
      };

      const schemaItem = self.defaultConfigurationForCurrentChargingStation[key];

      // @note check if update value is array
      if (schemaItem &&  schemaItem.properties.value.type === 'array' && !schemaItem.properties.value?.enum && Array.isArray(updatedVariable.value)) {
        updatedVariable.value = updatedVariable.value?.join(',');
      }

      // Hex encode if key is AuthorizationKey
      if (key === 'AuthorizationKey') {
        updatedVariable.value = hexEncode(updatedVariable.value);
      }

      if (newDefinition) {
        const { error } = yield self.api.createConfigurationVariable(
          chargingStationId || self.currentChargingStationId,
          key,
          updatedVariable.value,
        );
        if (error) {
          showChangeVariableErrorSnackbar(true, updatedVariable.key, error);

          return {
            key: key,
            status: `Error when creating ${updatedVariable.key}`,
          };
        }
      } else {
        const { error } = yield self.api.updateConfigurationVariable(
          chargingStationId || self.currentChargingStationId,
          updatedVariable.key,
          updatedVariable.value,
        );
        if (error) {
          showChangeVariableErrorSnackbar(false, updatedVariable.key, error);
        }
      }

      // Update state object
      addChargingStationConfigurationVariable(
        chargingStationId || self.currentChargingStationId,
        updatedVariable,
      );

      if (self.currentChargingStationOnline) {
        const changeConfiguration: ChargingStationChangeConfigurations = {
          configuration_variables: [updatedVariable],
        };

        // only if online and fix sync_required
        const { error, data: changeConfigStatus } =
          yield self.changeConfiguration(
            chargingStationId || self.currentChargingStationId,
            changeConfiguration,
          );

        if (error) {
          showChangeVariableErrorSnackbar(false, updatedVariable.key, error);
        }

        if (changeConfigStatus) {
          return {
            key: key,
            status: changeConfigStatus.status[
              key
            ] as ChargingStationConfigurationStatus,
          };
        }
      }
      markLoading(key, false);
    });

    const removeAllConfigurationsForCurrentChargingStation = flow(function* () {
      const { error } = yield self.api.removeConfiguration(
        self.currentChargingStationId,
      );

      yield getConfigurationForChargingStation();

      if (error) {
        const err = Array.isArray(error?.detail)
          ? error?.detail?.map((det: { msg: string }) => `${det.msg} `)
          : error?.detail;

        console.log(err);
        return 'Error when removing configuration';
      }

      if (self.currentChargingStationOnline) {
        const { error: err } = yield self.changeConfiguration(
          self.currentChargingStationId,
          {
            configuration_variables: Array.from(
              self.currentChargingStationConfigurationVariables?.values() || [],
            ),
          },
        );

        self.chargingStationConfigurationItems.delete(
          self.currentChargingStationId,
        );
        if (err) {
          showChangeVariableErrorSnackbar(false, 'all', error);
        }
      }
    });

    const showChangeVariableErrorSnackbar = (
      create: boolean,
      key: string,
      error: any,
    ) => {
      markLoading(key, false);

      const err = Array.isArray(error?.detail)
        ? error.detail?.map((det: { msg: string }) => `${det.msg} `)
        : error.detail;

      console.log(err);
    };

    const getConfigurationForChargingStation = flow(function* (
      chargingStationId?: string,
    ) {
      const id = chargingStationId || self.currentChargingStationId;
      if (id) {
        markLoadingConfigurationAndSchema(true);
        const { data, error } = yield self.api.getConfiguration(id);

        if (!error && data) {
          if (id) {
            yield getSchema(
              self.chargingStationType(id) === ChargingStationType.UNIVERSAL
                ? ChargingStationModelType.Other
                : ChargingStationModelType.entity,
              OCPPVersion.v1_6,
            );
          }
          data.forEach(
            (configurationVariable: ChargingStationConfigurationVariable) =>
              addChargingStationConfigurationVariable(
                id,
                configurationVariable,
              ),
          );
        }
        markLoadingConfigurationAndSchema(false);
      }
    });

    const getSchema = flow(function* (
      chargerModel: ChargingStationModelType,
      ocppSchema: OCPPVersion,
    ) {
      const schemaKey = schemaKeyFromModelAndOcpp(chargerModel, ocppSchema);
      const schema = self.schemaItems.get(schemaKey);
      if (!schema) {
        if (!self.currentlyLoadingSchemas.has(schemaKey)) {
          self.currentlyLoadingSchemas.set(
            schemaKey,
            flow(function* () {
              const { data, error } = yield self.api.getSchema(
                chargerModel,
                ocppSchema,
              );

              if (!error) {
                addChargingStationSchema(data);
              }
              self.currentlyLoadingSchemas.delete(schemaKey);
            })(),
          );
        }
        yield self.currentlyLoadingSchemas.get(schemaKey) as Promise<any>;
      }
    });

    return {
      clearChargingStationConfigurations,
      getConfigurationForChargingStation,
      getSupportedDestinationCsmsDict,
      removeAllConfigurationsForCurrentChargingStation,
      removeChargingStationConfiguration,
      updateOrCreateConfiguration,
    };
  });

import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ReferenceLine
} from 'recharts';
import { useCurrentMeterValues } from 'src/hooks/useCurrentMeterValues';
import { useLatestMeterValue } from 'src/hooks/useLatestMeterValue';
import { useLatestMeterValues } from 'src/hooks/useLatestMeterValues';
import { useStores } from 'src/store';
import {
  CONNECTOR_PHASE_ROTATION_VARIABLE_KEY,
  GARO_BRACKET_MAX_CURRENT,
  GARO_MAX_CHARGING_CURRENT,
  MeterValueType
} from 'src/store/model';
import { ChargingStationPhaseRotation } from './charging-station-phase-rotation';

export type ChartData = {
  name: string;
  chargingCurrent: number;
  unusedChargingCurrent: number;
  dynamicLimMaxCurrent: number;
  maxInstalledCurrent: number;
  voltage: number;
};

export const ChargingStationMonitoring = () => {
  const { chargingStationStore, chargingStationConfigurationsStore } =
    useStores();
  const [meterValues] = useCurrentMeterValues(
    chargingStationStore.currentChargingStationId,
    1,
    chargingStationStore,
    false
  );

  const [chartData, setChartData] = useState<ChartData[]>(
    Array.from(Array(4).keys()).map((key) => {
      let name = '';
      switch (key) {
        case 0:
          name = 'L1';
          break;
        case 1:
          name = 'L2';
          break;
        case 2:
          name = 'L3';
          break;
        case 3:
          name = 'N';
          break;
        default:
          break;
      }

      return {
        name,
        chargingCurrent: 0,
        unusedChargingCurrent: 0,
        dynamicLimMaxCurrent: 0,
        maxInstalledCurrent: 0,
        voltage: 0
      };
    })
  );

  const bracketMaxCurrentValue =
    chargingStationConfigurationsStore.getConfigVariableValueForCurrentChargingStation(
      GARO_BRACKET_MAX_CURRENT
    ) ?? 32;

  const maxInstalledCurrentValue = Math.min(
    chargingStationConfigurationsStore.getConfigVariableValueForCurrentChargingStation(
      GARO_MAX_CHARGING_CURRENT
    ) ?? 32,
    bracketMaxCurrentValue
  );

  useLatestMeterValue(meterValues, MeterValueType.CurrentOffered, 'Inlet');

  const latestCurrentMeterValues = useLatestMeterValues(
    meterValues,
    MeterValueType.CurrentImport
  );
  // const latestCurrentMeterValues = [{phase: "L1", measure_value: 8}, {phase: "L2", measure_value: 10}, {phase: "L3", measure_value: 12}];

  const latestVoltageMeterValues = useLatestMeterValues(
    meterValues,
    MeterValueType.Voltage
  );

  const latestCurrentControlPilotMeterValue = useLatestMeterValue(
    meterValues,
    MeterValueType.CurrentOffered,
    'Outlet'
  );

  // const latestCurrentControlPilotMeterValue = 12;

  useEffect(() => {
    setChartData((prev) =>
      prev.map((item, index) => {
        const chargingCurrent = Math.min(
          latestCurrentMeterValues?.find((value) =>
            value.phase?.endsWith(item.name)
          )?.measure_value || 0,
          maxInstalledCurrentValue
        );

        let unusedChargingCurrent = bracketMaxCurrentValue - chargingCurrent;

        if (chargingCurrent > 0 && index !== 3) {
          unusedChargingCurrent =
            latestCurrentControlPilotMeterValue - chargingCurrent;
        } else if (index !== 3) {
          unusedChargingCurrent = maxInstalledCurrentValue;
        }

        const dynamicLimMaxCurrent =
          index !== 3
            ? maxInstalledCurrentValue - unusedChargingCurrent - chargingCurrent
            : 0;

        const maxInstalledCurrent =
          bracketMaxCurrentValue -
          chargingCurrent -
          unusedChargingCurrent -
          dynamicLimMaxCurrent;

        const voltage =
          index !== 3
            ? latestVoltageMeterValues?.find(
                (value) => value.phase === `${item.name}-N`
              )?.measure_value || 0
            : (latestVoltageMeterValues
                ?.filter((value) => value.phase.endsWith('-N'))
                ?.reduce((acc, value) => acc + value.measure_value, 0) ?? 0) /
              3;

        return {
          ...item,
          chargingCurrent,
          unusedChargingCurrent,
          dynamicLimMaxCurrent,
          maxInstalledCurrent,
          voltage: Math.round(voltage)
        };
      })
    );
  }, [
    bracketMaxCurrentValue,
    maxInstalledCurrentValue,
    latestVoltageMeterValues,
    latestCurrentMeterValues,
    latestCurrentControlPilotMeterValue
  ]);


  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: 500, height: 500 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            {/* <Tooltip /> */}
            <Legend />
            <Bar
              name="Charging current"
              dataKey="chargingCurrent"
              stackId="a"
              fill="#2F5DFF"
            />
            <Bar
              name="Unused charging current"
              dataKey="unusedChargingCurrent"
              stackId="a"
              fill="#959499"
            />
            <Bar
              name="Dynamic limitation"
              dataKey="dynamicLimMaxCurrent"
              stackId="a"
              fill="#333333"
            />
            <Bar
              name="Max installed"
              dataKey="maxInstalledCurrent"
              stackId="a"
              fill="#7F6F3D"
            />
            {!!latestCurrentControlPilotMeterValue && (
              <ReferenceLine
                y={latestCurrentControlPilotMeterValue}
                stroke="white"
                label={{ position: 'top',  value: 'Control pilot signal', fill: 'grey', fontSize: 14 }}
                strokeWidth={3}
              />
            )}
          </BarChart>
        </ResponsiveContainer>

      </div>

      <ChargingStationPhaseRotation
       connectorPhaseRotation={         chargingStationConfigurationsStore.getConfigVariableValueForCurrentChargingStation(
        CONNECTOR_PHASE_ROTATION_VARIABLE_KEY,
      )?.[1] || '1.RST'}
       bracketPhaseRotation={
         chargingStationConfigurationsStore.getConfigVariableValueForCurrentChargingStation(
           CONNECTOR_PHASE_ROTATION_VARIABLE_KEY,
         )?.[0] || '0.RST'
       }
        enabledPhases={3} />
      <div style={{ width: 500, height: 500, marginTop: 20, marginLeft: 60 }}>
        <Typography>
          Active power:{' '}
          {useLatestMeterValue(meterValues, MeterValueType.PowerActiveImport) /
            1000}{' '}
          kW
        </Typography>

        <Typography>
          Reactive power:{' '}
          {useLatestMeterValue(
            meterValues,
            MeterValueType.PowerReactiveImport
          ) / 1000}{' '}
          kVAr
        </Typography>

        {useLatestMeterValues(meterValues, MeterValueType.Voltage)?.map(
          (val) => (
            <Typography key={val.phase}>
              Voltage {val.phase}: {val.measure_value} V
            </Typography>
          )
        )}

        {useLatestMeterValues(meterValues, MeterValueType.CurrentImport)?.map(
          (val) => (
            <Typography key={val.phase}>
              Current {val.phase}: {val.measure_value} A
            </Typography>
          )
        )}

        <Typography>
          Control pilot signal:{' '}
          {useLatestMeterValue(meterValues, MeterValueType.CurrentOffered)} A
        </Typography>

        <Typography>
          Thermal max current:{' '}
          {useLatestMeterValue(
            meterValues,
            MeterValueType.CurrentOffered,
            'Body'
          )}{' '}
          A
        </Typography>

        <Typography>
          Dynamic limitation to max:{' '}
          {chargingStationConfigurationsStore.getConfigVariableValueForCurrentChargingStation(
            GARO_MAX_CHARGING_CURRENT
          )}{' '}
          A
        </Typography>

      </div>
    </div>
  );
};

import { SnapshotIn, types } from 'mobx-state-tree';

export enum ConnectorStatus {
  Available = 'Available',
  Preparing = 'Preparing',
  Charging = 'Charging',
  SuspendedEVSE = 'SuspendedEVSE',
  SuspendedEV = 'SuspendedEV',
  Finishing = 'Finishing',
  Reserved = 'Reserved',
  Unavailable = 'Unavailable',
  Faulted = 'Faulted',
  Occupied = 'Occupied',
}

export const ChargingStationConnectorStatus = types.model({
  connector_id: types.number,
  charging_station_id: types.string,
  timestamp: types.maybeNull(types.string),
  status: types.enumeration([
    ConnectorStatus.Available,
    ConnectorStatus.Preparing,
    ConnectorStatus.Charging,
    ConnectorStatus.SuspendedEVSE,
    ConnectorStatus.SuspendedEV,
    ConnectorStatus.Finishing,
    ConnectorStatus.Reserved,
    ConnectorStatus.Unavailable,
    ConnectorStatus.Faulted,
    ConnectorStatus.Occupied,
  ]),
});
export interface ChargingStationConnectorStatus
  extends SnapshotIn<typeof ChargingStationConnectorStatus> {}

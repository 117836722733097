import { Snackbar, Card, Box, CardContent, Typography, ButtonGroup, Button } from '@mui/material';
import React from 'react'
import { Loader } from 'src/components';

type Props = {
  rowsSelected: number;
  loading: boolean;
  enable: () => void;
  disable: () => void;
}

export const ChangeAvailability =({rowsSelected, loading, disable, enable}: Props) => {
  return (
    <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'bottom'}} open={rowsSelected > 0} onClose={() => null}>
    <Card style={{ display: 'flex' }}>
      {loading && (
      <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: +1, backgroundColor: 'rgba(0, 0, 0, 0.2)', display: 'flex', alignItems: 'center'}}>
      <Loader></Loader>

      </div>

      )}

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent style={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            Change availability
          </Typography>
          <Typography variant="subtitle1" component="div">
            {rowsSelected} selected
          </Typography>
        </CardContent>
        <ButtonGroup style={{ display: 'flex' }} variant="outlined">
          <Button onClick={enable} style={{ flexGrow: 1 }}>Enable device{rowsSelected > 1 && 's'}</Button>
          <Button onClick={disable} style={{ flexGrow: 1 }}>Disable device{rowsSelected > 1 && 's'}</Button>
        </ButtonGroup>
      </Box>
    </Card>
  </Snackbar>
    )
  
}

import { SnapshotIn, types } from 'mobx-state-tree';
import { ChargingStation } from './ChargingStation';

import { SearchResult } from './SearchResult';

export const ChargingStationSearchItem = types.model({
  charging_station: types.maybeNull(ChargingStation),
  owner_emails: types.maybeNull(types.array(types.string)),
  installer_emails: types.maybeNull(types.array(types.string)),
  location_name: types.maybeNull(types.string)
});

export const ChargingStationSearchResult = types.compose(
  SearchResult,
  types.model({
    items: types.array(ChargingStationSearchItem),
  }),
);

export type ChargingStationSearchItem = SnapshotIn<
  typeof ChargingStationSearchItem
>;


export type ChargingStationSearchResult = SnapshotIn<
  typeof ChargingStationSearchResult
>;

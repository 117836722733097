import { flow, getEnv, getParent, types } from 'mobx-state-tree';

import { OrganizationsApi } from '../api';
import { RootModel } from '.';
import { Organization } from './model/Organization';

export const OrganizationStore = types
  .model({
    items: types.map(Organization),
  })
  .volatile(() => ({
    isLoading: false,
  }))
  .views((self) => {
    return {
      get api(): OrganizationsApi {
        return getEnv(self).organizationsApi;
      },
      get organizations() {
        return Array.from(self.items.values());
      },
    };
  })
  .actions((self) => {
    const addOrganization = (organization: Organization) => {
      if (organization) {
        self.items.set(organization.id, organization);
      }
    }

    const getOrganizations = flow(function* () {
        const { data, error } = yield self.api.getOrganizations(
          'Installer',
        );
        if (!error) {
          self.items.clear();
          data?.forEach((organization: Organization) => {
            if (!organization?.name) {
              organization.name = 'unnamed_organization';
            }
            addOrganization(organization);
          });
        }
    });

    const createOrganization = flow(function* (org: Organization) {
      const { data, error } = yield self.api.addOrganization(org);

      if (!error && data) {
        addOrganization(data);
        getParent<typeof RootModel>(self).locationStore.getLocations();
      }
    });

    const updateOrganizationName = flow(function* (id: string, name: string) {
      const org = self.items.get(id) as Organization;
      if (name && name !== org?.name) {
        const { data, error } = yield self.api.updateOrganization({
          ...org,
          name,
        });

        if (!error && data) {
          addOrganization(data);
        }
      }
    });

    return {
      getOrganizations,
      createOrganization,
      updateOrganizationName,
    };
  });

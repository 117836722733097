import { User } from './User';
import { SnapshotIn, types } from 'mobx-state-tree';
import { SearchResult } from './SearchResult';

export const AuditLogTypes = ["All", "TriggerAction" , "ModifyConfiguration"] as const;
export type AuditLogType = typeof AuditLogTypes[number];

export const AuditLogOperations = ["All", "ChangeConfiguration" , "Create" , "Delete" , "DeleteAll" , "Update"] as const;
export type AuditLogOperation = typeof AuditLogOperations[number];

export const AuditLogValue = types.model({
  configuration_variables:  types.maybeNull(types.array(types.frozen())),
  key: types.optional(types.string, ''),
  value: types.optional(types.frozen(), {})
});

export const AuditLog = types.model({
  id: types.maybeNull(types.string),
  audit_id: types.maybeNull(types.string),
  timestamp: types.maybeNull(types.string),
  user: User,
  user_id: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  operation: types.maybeNull(types.string),
  value: types.optional(AuditLogValue, {})
});

export const AuditLogSearch = types.model( {
  next: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  size: types.maybeNull(types.number),
  limit: types.maybeNull(types.number),
  items:types.array(AuditLog)
});

export const AuditLogSearchResult = types.compose(
  SearchResult,
  types.model({
    items: types.array(AuditLog),
  }),
);

export type AuditLog = SnapshotIn<
  typeof AuditLog
>;

export type AuditLogSearchResult = SnapshotIn<
  typeof AuditLogSearchResult
>;


/*
export type AuditLog = {
  id?: string;
  audit_id: string;
  timestamp?: string;
  user: User;
  user_id: string;
  type: AuditLogType;
  operation: AuditLogOperation;
  value: any;
}
*/

import styles from './loader.module.scss';

import React from 'react'

export const Loader = () =>  {
  return (
    <div className={styles.spinner}>
        <div className={styles.bounce1}></div>
        <div className={styles.bounce2}></div>
        <div className={styles.bounce3}></div>
    </div>
  )
}


import { ApiResponse } from 'src/core';
import { UserNotificationSettings } from 'src/store/model';

import { CloudApi } from './cloud-api';

const USERS_RESOURCE_PATH = 'users';

export class UsersApi extends CloudApi {
  async getUsers(
    filter_type: string | null | undefined,
    filter_value: string | null | undefined,
    limit = 25,
    next: string | null | undefined
  ): Promise<ApiResponse> {
    
    const queryString = CloudApi.objToQueryString({
      filter_type,
      filter_value,
      next,
      limit
    });
    return await this.GET(`${USERS_RESOURCE_PATH}${queryString}`);
  }


  async getUser(id: string): Promise<ApiResponse> {
    return await this.GET(`${USERS_RESOURCE_PATH}/${id}`);
  }

  async getUserByEmail(email: string): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      filter_type: 'email',
      filter_value: email,
    });
    return await this.GET(`${USERS_RESOURCE_PATH}/${queryString}`);
  }
  async getCurrentUser(): Promise<ApiResponse> {
    return await this.GET(`${USERS_RESOURCE_PATH}/current/`);
  }

  async updateUser(
    id: string,
    first_name: string,
    last_name: string,
    locale?: string,
  ): Promise<ApiResponse> {
    return await this.PUT(`${USERS_RESOURCE_PATH}/${id}/`, {
      first_name,
      last_name,
      locale,
    });
  }

  async getAuthorizations(): Promise<ApiResponse> {
    return await this.GET(`${USERS_RESOURCE_PATH}/current/authorizations`);
  }


  async getUserNotificationSettings(id: string): Promise<ApiResponse> {
    return await this.GET(`${USERS_RESOURCE_PATH}/${id}/notification-settings`);
  }

  async updateUserNotificationSettings(
    id: string,
    userNotificationSettings: UserNotificationSettings,
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${USERS_RESOURCE_PATH}/${id}/notification-settings`,
      userNotificationSettings,
    );
  }

}

import { flow, getEnv, types, cast } from 'mobx-state-tree';

import { ChargingStationsApi } from '../api';
import {
    AuditLogOperation,
    AuditLogSearch,
    AuditLogType,

} from './model';

export const AuditLogStore = types
    .model({
        searchItems: types.maybeNull(AuditLogSearch),
    })
    .volatile(() => ({
        isLoading: true,
        isLoadingAuth: true,
        currentChargingStationId: '',
    }))
    .views((self) => ({
        get searchResult() {   
            return Array.from(self.searchItems?.items.values() ?? []);
            },

        get searchItemsNext() {
            return self.searchItems?.next;
        },

        get api(): ChargingStationsApi {
            return getEnv(self).chargingStationsApi;
        }

    }))
    .actions(self => {
        const addSearchItems = (
            result: typeof AuditLogSearch
        ) => {
            self.searchItems = cast(result);
        };

        const getChargingStationAuditLog = flow(function* (
            chargingStationId: string,
            type: AuditLogType | null | undefined,
            operation: AuditLogOperation | null | undefined,
            pageSize = 25,
            next = ''
        ) {

            const { data /*, error */ } = yield self.api.getAutidlog(
                chargingStationId,
                type !== 'All' ? type : undefined,
                operation !== 'All' ? operation : undefined,
                pageSize,
                next
            );

            addSearchItems(data);
        });

        return {
            getChargingStationAuditLog,
        };
    });

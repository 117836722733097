
import { ApiResponse } from 'src/core';

import { CloudApi } from './cloud-api';

const INSTALLATION_JOBS_RESOURCE_PATH = 'installation-jobs';

export class InstallationJobsApi extends CloudApi {
  async getInstallationJobs(
    location_id: string,
    include_relationships: boolean,
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
        location_id,
        include_relationships,
    });
    return await this.GET(`${INSTALLATION_JOBS_RESOURCE_PATH}${queryString}`);
  }
}

import { ApiResponse } from 'src/core';
import { BatchAction, ChargingProfilePurposeEnum, ChargingStationChangeConfigurations, OCPPChargingProfile } from 'src/store/model';

import { CloudApi } from './cloud-api';
import { objToQueryString } from './object-to-query-string';

const ACTIONS_RESOURCE_PATH = 'actions';

export class ActionsApi extends CloudApi {

  async getConfiguration(
    chargingStationId: string,
    key?: string[],
  ): Promise<ApiResponse> {
    const body = key ? { key } : {};

    console.log('getConfiguration', chargingStationId, body);
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/get-configuration/${chargingStationId}`,
      body,
    );
  }
  
  async changeConfiguration(
    chargingStationId: string,
    changeConfiguration: ChargingStationChangeConfigurations,
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/change-configuration/${chargingStationId}`,
      changeConfiguration,
    );
  }

  async batchAction(actions: BatchAction[]): Promise<ApiResponse> {
    return await this.PUT(`batch/${ACTIONS_RESOURCE_PATH}`, actions);
  }

  async updateFirmware(
    chargingStationId: string,
    location: string,
    signing_certificate= '',
    signature = '',
    update_type = 'Standard',
    firmware_key = '',
  ): Promise<ApiResponse> {
    console.log('updateFirmware', chargingStationId, location, signing_certificate, signature, firmware_key, update_type);
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/update-firmware/${chargingStationId}`,
      { firmware_key, update_type, location, signing_certificate, signature },
    );
  }

  async resetSoft(chargingStationId: string): Promise<ApiResponse> {
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/reset/${chargingStationId}`,
      {
        type: 'Soft',
      },
    );
  }

  async resetHard(chargingStationId: string): Promise<ApiResponse> {
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/reset/${chargingStationId}`,
      {
        type: 'Hard',
      },
    );
  }

  // @note no start/end date from web
  async generateChargingStationLogFile(
    chargingStationId: string,
    log_type: string
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/get-charging-station-log/${chargingStationId}`,
      {
        log_type,
      },
    );
  }

  async setChargingProfile(
    chargingStationId: string,
    connector_id: number,
    occpChargingProfile: OCPPChargingProfile,
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/set-charging-profile/${chargingStationId}?response_required=true`,
      { connector_id, cs_charging_profiles: occpChargingProfile },
    );
  }

  async clearChargingProfile(
    chargingStationId: string,
    charging_profile_id: number,
    connector_id: number,
  ): Promise<ApiResponse> {
    return await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/clear-charging-profile/${chargingStationId}?response_required=true`,
      {
        id: charging_profile_id ?? 10,
        connector_id: connector_id ?? 1,
        charging_profile_purpose: ChargingProfilePurposeEnum.TxDefaultProfile,
        stack_level: 1,
      },
    );
  }

  async dataTransfer(
    chargingStationId: string,
    data: string,
    message_id: string,
  ): Promise<ApiResponse> {
    const res = await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/data-transfer/${chargingStationId}?response_required=true`,
      {
        data,
        message_id,
      },
      );
      return res;
  }

  async triggerMessageFirmwareStatusNotification(
    chargingStationId: string,
  ): Promise<ApiResponse> {
    const res = await this.PUT(
      `${ACTIONS_RESOURCE_PATH}/trigger-message/${chargingStationId}?response_required=true`,
      {
        connector_id: 0,
        requested_message: 'FirmwareStatusNotification',
      },
    );
    return res;
  }
}

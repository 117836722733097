import { SnapshotIn, types } from 'mobx-state-tree';

export enum FreeOfChargeRecurrencyKind {
  Daily = 'Daily',
  Weekly = 'Weekly',
}

export const FreeOfChargeSchedule = types.model({
  id: types.maybeNull(types.string),
  charging_station_id: types.string,
  active: types.boolean,
  start_time: types.maybeNull(types.string),
  end_time: types.maybeNull(types.string),
  recurring: types.maybeNull(
    types.enumeration([
      FreeOfChargeRecurrencyKind.Daily,
      FreeOfChargeRecurrencyKind.Weekly,
    ]),
  ),
});

export interface FreeOfChargeSchedule
  extends SnapshotIn<typeof FreeOfChargeSchedule> {}

import styles from './users-table.module.scss';
import globalStyles from '../../../global-styles.module.scss';

import { observer } from 'mobx-react-lite';
import React, { useCallback,  useEffect,  useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'src/store';

import { Loader } from '../../../components';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';

import { Row } from './components/row';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

type Filters = 'email' | 'first_name' | 'last_name';
const prev: string[] = [];

export const UsersTable = observer(() => {

  const { userStore } = useStores();
  const navigate = useNavigate();

  const search = useCallback(
    (next?: string) => {
      userStore.getUsers(
        userStore.searchFilter,
        userStore.searchString,
        userStore.rowsPerPage,
        next
      );
    },[userStore]
  );

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {

      let next = '';

      if (newPage <= userStore.page || userStore.persistedPagination) { 
        next = userStore.previousNext[newPage] ?? '';
      } else {

        userStore.setPersistedPagination(false);

        next = userStore.userSearchItemsNext ?? '';
        prev[newPage] = next;
        userStore.setPreviousNext(prev);
      }

      search(next)
      userStore.setPage(newPage);
    },
    [userStore, search]
  );


    useEffect(() => {
      handleChangePage(null, userStore.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {

    const rowsPerPage = parseInt(event.target.value, 10);

    userStore.getUsers(
      userStore.searchFilter,
      userStore.searchString,
      rowsPerPage,
      userStore.previousNext[userStore.page]
    );

    userStore.setRowsPerPage(rowsPerPage);
    userStore.setPage(0);
  };

  const handleChange = (event: any) => {

    userStore.setSearchFilter(event.target.value as Filters);

    userStore.getUsers(
      event.target.value,
      userStore.searchString,
      userStore.rowsPerPage
    );
  };

  const handleSearch = useCallback(
    (search: string) => {
      userStore.setPage(0);
      userStore.getUsers(
        userStore.searchFilter,
        search,
        userStore.rowsPerPage
      );
      userStore.setPreviousNext([]);
    },
    [userStore]
  );

  const debounceTimer = useRef<any>();
  const handleSearchDebounced = useCallback(
    (search: string) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => handleSearch(search), 600);
    },
    [handleSearch]
  );

  return (
    <div>
      <div className={`${globalStyles.container} ${styles.filterActions}`}>
        <div style={{ position: 'relative', display: 'flex' }}>
          <input
            className={styles.searchField}
            value={userStore.searchString}
            onChange={(e) => {
              userStore.setSearchString(e.target.value);
              handleSearchDebounced(e.target.value);
            }}
          />
          <IconButton
            disabled={userStore.searchString.length === 0}
            onClick={() => {
              userStore.setSearchString('');
              handleSearch('');
            }}
            style={{ position: 'absolute', top: 0, right: 20 }}
            aria-label="close"
          >
            {userStore.searchString.length > 0 && <ClearIcon style={{ marginTop: '5px' }} fontSize="inherit" />}
          </IconButton>
        </div>

        <FormControl variant="standard">
          <InputLabel id="select-helper-label">Search by:</InputLabel>
          <Select
            labelId="select-label"
            label="Search by:"
            id="select"
            value={userStore.searchFilter}
            onChange={handleChange}
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="first_name">First name</MenuItem>
            <MenuItem value="last_name">Last name</MenuItem>
          </Select>
        </FormControl>
      </div>

      {userStore.isLoading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <TableContainer
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          component={Paper}
          className={`${globalStyles.container} ${styles.usersTable}`}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>First Name</TableCell>
                <TableCell align="left">Last Name</TableCell>
                <TableCell align="left">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userStore.userSearchResult.map((row) => (
                <Row
                  key={row.id}
                  user={row}
                  navigate={navigate}
                  persistPagination={() => {
                    userStore.setPersistedPagination(true)
                  }}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={userStore.userSearchItemsCount}
            page={userStore.page}
            onPageChange={handleChangePage}
            rowsPerPage={userStore.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </div>
  );
});

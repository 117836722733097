import styles from './charging-station-audit-log.module.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useStores } from 'src/store';
import * as dateFns from 'date-fns';
import { observer } from 'mobx-react-lite';

import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  FormControl,
  InputLabel,
  MenuItem,
  Select, Card, CardContent
} from '@mui/material';

import globalStyles from '../../../../global-styles.module.scss';
import { AuditLogOperation, AuditLogType, AuditLogOperations, AuditLogTypes } from 'src/store/model';
import { ChargingStationStatusHeader } from './charging-station-status-header';

const prev: string[] = [];

export const ChargingStationAuditLog = observer(() => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [previousNext, setPreviousNext] = useState<string[]>([]);
  const [operationFilter, setOperationFilter] = useState<AuditLogOperation>('All');
  const [typeFilter, setTypeFilter] = useState<AuditLogType>('All');

  const { auditLogStore, chargingStationStore } = useStores();

  const search = useCallback(
    async (
      chargingStationId: string,
      next?: string

    ) => {
      auditLogStore.getChargingStationAuditLog(
        chargingStationId,
        typeFilter,
        operationFilter,
        rowsPerPage,
        next ?? ''
      );
    },
    [auditLogStore, operationFilter, rowsPerPage, typeFilter]
  );

  const handleOperationChange = (event: any) => {
    setOperationFilter(event.target.value as AuditLogOperation);
    setPage(0);
    search(chargingStationStore.currentChargingStationId);
  };

  const handleTypeChange = (event: any) => {
    setTypeFilter(event.target.value as AuditLogType);
    setPage(0);
    search(chargingStationStore.currentChargingStationId);
  };

  const handleChangePage = useCallback((
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let next = '';

    if (newPage > page) {
      next = auditLogStore.searchItemsNext || '';
      prev[newPage] = next;
      setPreviousNext(prev);

    } else {
      next = previousNext[newPage] ?? '';
    }

    setPage(newPage);
    search(chargingStationStore.currentChargingStationId, next);

  }, [auditLogStore.searchItemsNext, chargingStationStore.currentChargingStationId, page, previousNext, search]);

  useEffect(() => {
    handleChangePage(null, page);
  }, [handleChangePage, page]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    search(
      chargingStationStore.currentChargingStationId,
      previousNext[page]
    );
    setPage(0);
  };

  function getChanged(row: any) {
    if(row.value?.configuration_variables) {
      return row.value.configuration_variables.map(
        (v: { key: string; value: string }) => `${v?.key}: ${v?.value}`
      ).join(', ')
    } else {
      if(Array.isArray(row.value)) {
        return <Select
          size='small'
          variant="standard"
          labelId="simple-select-label"
              id="simple-select"
          fullWidth={true}
        >
          {row.value.map((v: { key: string; value: string }) => 
            <MenuItem key={v.key} value={v.value}>{v.key}: {v.value}</MenuItem>)
          }
        </Select>
      } else {
        return `${row?.value?.key}: ${row?.value?.value}`
      }
    }
  }

  return (
    <Card className={globalStyles.container}>
      <ChargingStationStatusHeader />
      <CardContent style={{ minHeight: 650 }}>
        <div className={`${globalStyles.container} ${styles.filterActions}`}>
          <FormControl variant="standard">
            <InputLabel id="simple-select-label">Type:</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={typeFilter}
              onChange={handleTypeChange}
            >
              {Object.values(AuditLogTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="simple-select-label">Operation:</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={operationFilter}
              onChange={handleOperationChange}
            >
              {Object.values(AuditLogOperations).map((operation) => (
                <MenuItem key={operation} value={operation}>
                  {operation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <TableContainer
          className={globalStyles.container}
          style={{ width: 'unset' }}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Operation</TableCell>
                <TableCell>Changed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditLogStore.searchResult.map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ width: 120 }}>
                    {row.timestamp &&
                      dateFns.format(new Date(row.timestamp), 'yyyy-MM-dd HH:mm')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.user.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.operation}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getChanged(row)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={-1}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
});

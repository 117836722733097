

import { ApiResponse } from 'src/core';

import { CloudApi } from './cloud-api';
export const CSSTATUS_RESOURCE_PATH = 'charging-station-status';

export class ChargingStationStatusApi extends CloudApi {
  async getChargingStationStatus(
    chargingStationId?: string,
  ): Promise<ApiResponse> {
    return await this.GET(`${CSSTATUS_RESOURCE_PATH}/${chargingStationId}`);
  }
}

import { SnapshotIn, types } from 'mobx-state-tree';

import { SearchResult } from './SearchResult';

export const OcppPayload = types.model({
  connector_id: types.number,
  error_code: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  timestamp: types.maybeNull(types.string),
  info: types.maybeNull(types.string),
  vendor_id: types.maybeNull(types.string),
  vendor_error_code: types.maybeNull(types.string),
});

export const OcppEvent = types.model({
  id: types.string,
  charging_station_id: types.string,
  connector_id: types.number,
  timestamp: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  ocpp_version: types.maybeNull(types.string),
  payload: types.maybeNull(OcppPayload),
});

export const OccpSearchResult = types.compose(
  SearchResult,
  types.model({ items: types.array(OcppEvent) }),
);

export type OcppEvent = SnapshotIn<typeof OcppEvent>

export type OcppPayload = SnapshotIn<typeof OcppPayload>
export type OccpSearchResult = SnapshotIn<typeof OccpSearchResult>

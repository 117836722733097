
import { ApiResponse } from 'src/core';

import { CloudApi } from './cloud-api';

const CHARGING_UNITS_RESOURCE_PATH = 'charging-units';

export class ChargingUnitsApi extends CloudApi {
  async getChargingUnit(chargingStationId: string): Promise<ApiResponse> {
    return await this.GET(
      `${CHARGING_UNITS_RESOURCE_PATH}/${chargingStationId}`,
    );
  }
}

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

export const AwsConfig = {
  Auth: {
    endpoint: process.env.REACT_APP_AWS_COGNITO_ENDPOINT,
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_ST_APP_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AWS_COGNITO_AUTHENTICATION_FLOW_TYPE,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  },
};
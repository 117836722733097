import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useStores } from 'src/store';



export const EnterMfaCode = () => {
  const [authCode, setAuthCode] = useState('');
  const { userStore } = useStores();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography style={{marginBottom: '1rem', marginTop: '1rem'}}>
        Enter the code provided by your authenticator app.
      </Typography>

      <TextField
        variant="standard"
        label="Code"
        defaultValue=""
        onChange={(e) => setAuthCode(e.target.value)}
      />
      {userStore.authenticationErrorMessage && <span style={{marginTop: '1rem'}}>Invalid code</span>}
      <Button
        disabled={!authCode}
        size="medium"
        style={{ marginTop: '1rem', width: '200px' }}
        variant="contained"
        color="primary"
        onClick={() => userStore.confirmAuth(authCode)
        }>
        Submit
      </Button>
    </div>
  );
};

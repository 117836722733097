import { SnapshotIn, types } from 'mobx-state-tree';

import { SearchResult } from './SearchResult';
import { Transaction } from './Transaction';

export const TransactionSearchResult = types.compose(
  SearchResult,
  types.model({ items: types.array(Transaction) }),
);

export interface TransactionSearchResult
  extends SnapshotIn<typeof TransactionSearchResult> {}

import en from './en';
import fi from './fi';
import se from './se';
import nb from './nb';

const translations = { en: en, fi: fi, se: se, no: nb, nb: nb };

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: translations,
    fallbackLng: "en",
  });

export default i18n;
import { Button, Checkbox, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  NotificationSettings,
  User,
  UserNotificationSettings
} from 'src/store/model';
import styles from './user-general.module.scss';
type Props = {
  user: User | undefined | null;
  notifications: UserNotificationSettings | undefined | null;
  updateUser: (user: User) => void;
  updateNotifications: (
    userNotificationSettings: UserNotificationSettings
  ) => void;
};

const notificationSettingsDefault: NotificationSettings = {
  charging_station_authorization: false,
  charging_station_group_authorization: false,
  organization_authorization: false,
  location_transfer: false,
  firmware_update_available: false,
  installation_instructions: false,
  charging_station_error: false,
  transaction_stopped: false
};
const keysOfNotificationSettings = Object.keys(notificationSettingsDefault);

export const UserGeneral = ({
  user,
  notifications,
  updateUser,
  updateNotifications
}: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty }
  } = useForm<User & UserNotificationSettings>({
    mode: 'onChange',
    defaultValues: { ...user, ...notifications }
  });

  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    const { first_name, last_name } = data;

    if (data.emails !== undefined || data.push_notifications !== undefined) {
      updateNotifications({
        emails: { ...data.emails },
        push_notifications: { ...data.push_notifications }
      });
    }
    updateUser({ ...user, first_name, last_name });
    reset(data);
  };

  return (
    <div className={styles.userDetails}>
      <Typography style={{ marginBottom: '1rem' }} variant="h6">
        User Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.userDetailsRow}>
          <TextField
            color="primary"
            type="firstName"
            className={styles.field}
            label={t('First name')}
            variant="standard"
            {...register('first_name', { required: true })}
          />
        </div>
        <div className={styles.userDetailsRow}>
          <TextField
            color="primary"
            type="lastName"
            className={styles.field}
            label={t('Family name')}
            variant="standard"
            {...register('last_name', { required: true })}
          />
        </div>

        <div className={styles.userDetailsRow}>
          <Typography style={{ marginBottom: '1rem' }} variant="h6">
            Notifications
          </Typography>
          <div
            style={{
              flexDirection: 'row',
              alignSelf: 'flex-end',
              display: 'flex'
            }}
          >
            <pre style={{ marginRight: '2rem' }}>Email</pre>
            <pre style={{ marginRight: '0px' }}>Push</pre>
          </div>
        </div>

        {keysOfNotificationSettings.map((key) => (
          <div key={`emails.${key}`} className={styles.userDetailsRow}>
            <Typography>
              {key
                .replace(/_/g, ' ')
                .replace(
                  /\w\S*/g,
                  (txt) =>
                    txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
                )}
            </Typography>

            <div
              style={{
                flexDirection: 'row',
                alignSelf: 'flex-end',
                display: 'flex'
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: '2rem'
                }}
              >
                <Controller
                  name={`emails.${key}` as any}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      color="primary"
                      defaultChecked={!!field.value}
                      {...field}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Controller
                  name={`push_notifications.${key}` as any}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      color="primary"
                      defaultChecked={!!field.value}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ))}

        <div className={styles.userDetailsRow}>
          <Button
            disabled={!isValid || !isDirty}
            style={{ marginLeft: 'auto' }}
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            {t('Update')}
          </Button>
        </div>
      </form>
    </div>
  );
};

import { Card } from '@mui/material';
import React from 'react';
import { theme } from 'src/theme';
import styles from './not-found.module.scss';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {

  const { t } = useTranslation();

  return (
    <Card
      style={{ borderRadius: 0, backgroundColor: theme.palette.secondary.dark }}
      className={styles.notFoundCard}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fff',
          height: 120,
          paddingLeft: 40,
          paddingRight: 40,
        }}>
          <h1>{t('The requested page could not be found')}</h1>
      </div>
    </Card>
  );
};

import {
    Box, Card, CardContent, Tab, Tabs,
  } from '@mui/material';
import React, { useState } from 'react';  
import globalStyles from '../../../../global-styles.module.scss';
import { TabPanel } from 'src/components';
import { AccessOwners } from './access-owners';
import { AccessInstallers } from './access-installers';
import { AccessDrivers } from './access-drivers';
import { AccessEVDriverGroup } from './access-ev-drivers-group';
import { AccessChargerGroup } from './access-charger-group';

  export const Access = () => {
    
    const handleTabChange = (event: any, newValue: number) => {
      setValue(newValue);
    };

    const a11yProps = (index: number) => {
      return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
      };
    };

    const [value, setValue] = useState(0);

    return (
      <Card className={globalStyles.container}>
      {/* <ChargingStationStatusHeader /> */}
      <CardContent style={{ minHeight: 650 }}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex'
          }}
        >
          <Tabs
            orientation="vertical"
            style={{ width: '15rem' }}
            value={value}
            onChange={handleTabChange}
          >
            <Tab label="Owners" {...a11yProps(0)} />
            <Tab label="Installers" {...a11yProps(1)} />
            <Tab label="EV-drivers" {...a11yProps(2)} />
            <Tab label="EV-driver group" {...a11yProps(3)} />
            <Tab label="Charger group" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <AccessOwners />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AccessInstallers />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AccessDrivers />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AccessEVDriverGroup />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AccessChargerGroup />
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
    );
  };
  
import { Instance } from 'mobx-state-tree';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChargingStationStore } from 'src/store/ChargingStationStore';
import { MeterValue } from 'src/store/model';
export const useCurrentMeterValues = (
  chargingStationId: string,
  connectorId: number | undefined,
  chargingStationStore: Instance<typeof ChargingStationStore>,
  onlyStartedTransactions = true,
): [MeterValue[], () => Promise<void>, boolean] => {
  const hasFocus = true;

  const [loading, setLoading] = useState(true);
  const [latestMeterValues, setLatestMeterValues] = useState<MeterValue[]>([]);
  const getUpdatedStatus = useCallback(async () => {
    if (hasFocus) {
      return chargingStationStore
        .getChargingStationTransactions(chargingStationId)
        .then((items) => {
            const latestTransaction = items?.find((item) =>
              item.connector_id
                ? item.connector_id === connectorId
                : true &&
                  (onlyStartedTransactions ? item.state === 'Started' : true)
            );
            if (latestTransaction?.id) {
              chargingStationStore
                .getChargingStationLatestMeterValues(
                  chargingStationId,
                  connectorId,
                  latestTransaction?.id
                )
                .then((meterValues: MeterValue[]) => {
                  setLatestMeterValues(meterValues);
                });
            }
        })
        .finally(() => setLoading(false));
    } else {
      return Promise.resolve();
    }
  }, [chargingStationId, chargingStationStore, connectorId, hasFocus, onlyStartedTransactions]);

  useEffect(() => {
    getUpdatedStatus();
  }, [getUpdatedStatus]);

  // instead of useInterval(getUpdatedStatus, 5000), no hook in hook;
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = getUpdatedStatus;
  }, [getUpdatedStatus]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    const id = setInterval(tick, 5000);
    return () => {
      clearInterval(id);
    };
  }, []);
  return [latestMeterValues, getUpdatedStatus, loading];
};

import { SnapshotIn, types } from 'mobx-state-tree';

export enum ChargingStationConnectionGroupTypeEnum {
  group_with_internet_and_dlm = ':',
  group_with_only_dlm = '<',
  group_with_only_internet = '>',
}
export const ChargingStationConnectionGroup = types.model({
  charging_station_id: types.identifier,
  charging_station_uid: types.maybeNull(types.string),
  max_current: types.maybeNull(types.number),
  items: types.maybeNull(types.array(types.string)),
  itemsRaw: types.maybeNull(types.array(types.string)),
});

export type ChargingStationConnectionGroup = SnapshotIn<typeof ChargingStationConnectionGroup>

import { Card } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'src/store';
import { theme } from 'src/theme';
import styles from './dashboard.module.scss';
import { DownloadTheApp } from 'src/components';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const getSupportLink = () => {
    switch (sessionStore.language.toLowerCase()) {
      case 'se': return 'https://www.garo.se/sv/support';
      case 'fi': return 'https://garo.fi/yhteystiedot/';
      case 'pl': return 'https://www.garo.com.pl/kontakt/';
      case 'no': return 'https://garo.no/support/';
      case 'uk': case 'ie': return 'https://www.garoelectric.com/support/support-e-mobility';
      default: return 'https://www.garo.se/en/support';
    }
  };

  return (
    <Card
      style={{ borderRadius: 0, backgroundColor: theme.palette.secondary.dark }}
      className={styles.dashboardCard}
    >
      <section className={styles.menuContainer}>
        <button
          className={styles.menuItem}
          onClick={() => navigate('/documentation')}
        >
          <img
            className={styles.menuIcon}
            src={require(`../../../images/Manual.png`)}
          ></img>

          <span>{t('Documentation')}</span>
        </button>
        <a
          href={getSupportLink()}
          rel="noreferrer"
          target="_blank"
          className={styles.menuItem}
        >
          <img
            className={styles.menuIcon}
            src={require(`../../../images/Support.png`)}
          ></img>

          <span>{t('Support')}</span>
        </a>
        <a
          href={`https://www.garo.se/${sessionStore.language.toLowerCase().includes('se') ? 'sv' : 'en'}/garo-entity`}
          rel="noreferrer"
          target="_blank"
          className={styles.menuItem}
        >
          <img
            className={styles.menuIcon}
            src={require(`../../../images/ChargerInfo.png`)}
          ></img>

          <span>{t('Product information')}</span>
        </a>
        <DownloadTheApp
          iosLink="https://apps.apple.com/app/id1534289157"
          androidLink="https://play.google.com/store/apps/details?id=se.garo.nxt"
        />

      </section>
    </Card>
  );
};

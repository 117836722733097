import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

import { ActionStore } from './ActionStore';
import { ChargingStationConfigurationsStore } from './ChargingStationConfigurationsStore';
import { ChargingStationStore } from './ChargingStationStore';
import { InstallationStore } from './InstallationStore';
import { LocationStore } from './LocationStore';
import { OrganizationStore } from './OrganizationStore';
import { SessionStore } from './SessionStore';
import { UserStore } from './UserStore';
import { AuditLogStore } from './AuditLogStore';

export const RootModel = types.model({
  actionStore: ActionStore,
  chargingStationConfigurationsStore: ChargingStationConfigurationsStore,
  chargingStationStore: ChargingStationStore,
  installationStore: InstallationStore,
  locationStore: LocationStore,
  organizationStore: OrganizationStore,
  userStore: UserStore,
  sessionStore: SessionStore,
  auditLogStore: AuditLogStore
});

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<RootInstance | null>(null);

export type RootModel = SnapshotIn<typeof RootModel>

export const RootStoreProvider = RootStoreContext.Provider;
/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useStores()`,
 * or less likely: `const rootStore = useStores()`
 */
export const useStores = () => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null.');
  }
  return store;
};

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { MeterValueType, Transaction } from 'src/store/model';
import { formatDistance } from 'date-fns';
import * as fnsLocale from 'date-fns/locale';
import { useCurrentMeterValues } from 'src/hooks/useCurrentMeterValues';
import { useStores } from 'src/store';
import { useLatestMeterValues } from 'src/hooks/useLatestMeterValues';

type Props = {
  transactions: Transaction[];
};

export const ChargingStationTransactions = ({ transactions }: Props) => {
  const { chargingStationStore } = useStores();
  const [meterValues] = useCurrentMeterValues(
    chargingStationStore.currentChargingStationId,
    1,
    chargingStationStore,
    false
  );

  const [ latestCurrentMeterValues ] = useLatestMeterValues(
    meterValues,
    MeterValueType.EnergyActiveImportRegister
  );
  
  const getMeterStopValue = (row: Transaction) => {
    return row.meter_stop ? row.meter_stop / 1000 : '-';
  }
  
  const getMeterDeltaValue = (row: Transaction) => {
    if (!row.meter_stop && row.meter_start && latestCurrentMeterValues) {
      return (latestCurrentMeterValues.measure_value - row.meter_start) / 1000;
    } else if (row.meter_start && row.meter_stop) {
      return (row.meter_stop - row.meter_start) / 1000;
    }
    return '-';
  }
  
  return (
    <Box>
      <Typography
        style={{ marginBottom: '1rem' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Transactions
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>Connector ID</TableCell>
              <TableCell align="right">Started</TableCell>
              <TableCell align="right">Stopped</TableCell>
              <TableCell align="right">Duration</TableCell>
              <TableCell align="right">Meter start&nbsp;(kWh)</TableCell>
              <TableCell align="right">Meter stop&nbsp;(kWh)</TableCell>
              <TableCell align="right">Meter delta&nbsp;(kWh)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row) => (
              <TableRow
                hover
                key={row.id}
              >
                <TableCell padding='checkbox'>{row.connector_id}</TableCell>
                <TableCell align="right">{row.start_time.substring(0, 16).replace('T', ' ')}</TableCell>
                <TableCell align="right">{(row?.end_time ?? '').substring(0, 16).replace('T', ' ')}</TableCell>
                <TableCell align="right">
                  {`${
                    row.start_time && row.end_time
                      ? formatDistance(new Date(row.start_time), new Date(row.end_time), {
                          locale: fnsLocale.enGB
                        })
                      : row.state
                  }`}
                </TableCell>
                <TableCell align="right">{(row.meter_start ?? 0) / 1000}</TableCell>
                <TableCell align="right">{getMeterStopValue(row)}</TableCell>
                <TableCell align="right">{getMeterDeltaValue(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

import { applySnapshot, onAction, onSnapshot } from 'mobx-state-tree';
import { mst } from 'reactotron-mst';
import Reactotron from 'reactotron-react-js';

import { Environment } from './environment';
import { RootModel } from './RootStore';

const ROOT_STATE_STORAGE_KEY = 'GaroConnectKey';

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  const env = new Environment();
  // await env.setup();
  return env;
}

/**
 * Setup the root state.
 */

export async function setupRootStore() {

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment();

  const sessionStore: RootModel =
    (JSON.parse(localStorage.getItem(ROOT_STATE_STORAGE_KEY) ?? '{}'))?.sessionStore ?? {};

  const emptyStore: RootModel = {
    chargingStationStore: {},
    installationStore: {},
    locationStore: {},
    organizationStore: {},
    userStore: {},
    actionStore: {},
    chargingStationConfigurationsStore: {},
    sessionStore,
    auditLogStore: {}
  };

  const rootStore = RootModel.create(emptyStore, env);

  // reactotron logging
  /*
  if (__DEV__) {
    Reactotron.configure() // we can use plugins here -- more on this later
      .connect(); // let's connect!
    Reactotron.use(mst());
  }
  */
 
  onAction(rootStore, (call) => {
    if (call.name === 'signOutUser') {
      applySnapshot(rootStore, emptyStore);
    }
  });

  //track changes & save to storage
  onSnapshot(rootStore, (snapshot) =>
    localStorage.setItem(ROOT_STATE_STORAGE_KEY, JSON.stringify({
      sessionStore: snapshot.sessionStore
    }))
  );


  return rootStore;
}

import {
  Card,
  Link,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'src/store';
import globalStyles from '../../../../global-styles.module.scss';
import { LocationStatusHeader } from './location-status-header';
import { ChargingStation } from 'src/store/model';

const GARO_WIFI_PASSWORD = 'GaroWifiPassword';
const GARO_ADMIN_PASSWORD = 'GaroAdminPassword';

  type Props = {
    chargingStations: ChargingStation[];
  };

  export const Configurations = ({ chargingStations }: Props) => {
    const { chargingStationStore } = useStores();
    const navigate = useNavigate();

    function isPassword(key: string) {
      return key === GARO_WIFI_PASSWORD || key === GARO_ADMIN_PASSWORD;
    }

    const addValue = useCallback((found: any, parameters: { param: any; val: string[]; }[], index: number) => {
      if (found !== undefined) {
        if (Array.isArray(found.value)) {
          parameters[index].val.push(found.value.join('\n'));
        } else {
          if ((typeof found.value === "boolean")) {
            parameters[index].val.push(found.value ? 'True' : 'False');
          } else {
            parameters[index].val.push(isPassword(found.key) ? '**********' : found.value);
          }
        }
      } else {
        parameters[index].val.push('-');
      }
    }, []);
      
    const data = useMemo(() => {
      const parameters: { param: any; val: string[]; }[] = [];
      // Find all parameters
      chargingStations.forEach((chargingStation: any) => {
        chargingStation.charging_station.configuration.forEach((config: any) => {
          if (!parameters.find((p: any) => p.param === config.key)) {
            parameters.push({
              "param": config.key,
              "val": []
            });
          }
        });
      });
      parameters.sort((a, b) => a.param.localeCompare(b.param));
      // Find values for each parameter
      chargingStations.forEach((chargingStation: any) => {
        parameters.forEach((p: any, index: number) => {
          const found = chargingStation.charging_station.configuration.find((config: any) => {
            return (parameters[index].param === config.key);
          });
          addValue(found, parameters, index);
        });
      });
      
      return parameters;
    }, [addValue, chargingStations]);

    const gotoLocationView = (id: string) => {
      chargingStationStore.setCurrentChargingStation(id)
      navigate(
        `/service-tech/charging-stations/${id}`
      );
    }

    return (
      <Card className={globalStyles.container}>
        <LocationStatusHeader />
        <TableContainer sx={{ }} component={Paper}>
          <Table sx={{ marginBottom: 2 }} stickyHeader size="small">
            <TableHead sx={{  }} >
              <TableRow>
                <TableCell sx={{ width: 100, maxWidth: 100 }} align="left">Parameter</TableCell>
                {chargingStations.map((chargingStation: any) => {
                  return (
                    <TableCell key={chargingStation.charging_station.id} align="center">
                      <Link
                        style={{ marginLeft: 5 }}
                        component="button"
                        underline="none"
                        color="primary"
                        onClick={() => gotoLocationView(chargingStation.charging_station.id)}
                      >
                      {chargingStation.charging_station.name}
                      </Link>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
                {data.map((d: any) => {
                return <>
                  <TableRow hover >
                    <TableCell key={d.param} align="left">{d.param}</TableCell>
                    {d.val.map((d: any, index: number) => {
                      return <TableCell key={index} align="center">{d}</TableCell>;
                    })}
                  </TableRow></>
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    );
  };
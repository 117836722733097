
import { ApiResponse } from 'src/core';
import { Location} from 'src/store/model'

import { CloudApi } from './cloud-api';
import { ORGANIZATIONS_RESOURCE_PATH } from './organizations.api';

export const LOCATION_RESOURCE_PATH = 'locations';

export class LocationsApi extends CloudApi {
  async getLocations(
    context: string,
    organizationId?: string,
  ): Promise<ApiResponse> {
    const queryString = CloudApi.objToQueryString({
      organization_id: organizationId,
      context: context,
    });

    return await this.GET(`${LOCATION_RESOURCE_PATH}${queryString}`);
  }

  async getLocation(id?: string): Promise<ApiResponse> {
    return await this.GET(`${LOCATION_RESOURCE_PATH}/${id}`);
  }

  async addLocation(location: Location): Promise<ApiResponse> {
    return await this.POST(
      `${ORGANIZATIONS_RESOURCE_PATH}/${location.organization_id}/${LOCATION_RESOURCE_PATH}`,
      location,
    );
  }

  async updateLocation(location: Location): Promise<ApiResponse> {
    return await this.PUT(`${LOCATION_RESOURCE_PATH}/${location.id}`, location);
  }

  async removeLocation(id: string): Promise<ApiResponse> {
    return await this.DELETE(`${LOCATION_RESOURCE_PATH}/${id}`);
  }
}

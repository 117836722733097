import { SnapshotIn, types } from 'mobx-state-tree';

import { SearchResult } from './SearchResult';

export enum MeterValueType {
  CurrentExport = 'Current.Export',
  CurrentImport = 'Current.Import',
  CurrentOffered = 'Current.Offered',
  EnergyActiveExportRegister = 'Energy.Active.Export.Register',
  EnergyActiveImportRegister = 'Energy.Active.Import.Register',
  EnergyReactiveExportRegister = 'Energy.Reactive.Export.Register',
  EnergyReactiveImportRegister = 'Energy.Reactive.Import.Register',
  EnergyActiveExportInterval = 'Energy.Active.Export.Interval',
  EnergyActiveImportInterval = 'Energy.Active.Import.Interval',
  EnergyReactiveExportInterval = 'Energy.Reactive.Export.Interval',
  EnergyReactiveImportInterval = 'Energy.Reactive.Import.Interval',
  Frequency = 'Frequency',
  PowerActiveExport = 'Power.Active.Export',
  PowerActiveImport = 'Power.Active.Import',
  PowerFactor = 'Power.Factor',
  PowerOffered = 'Power.Offered',
  PowerReactiveExport = 'Power.Reactive.Export',
  PowerReactiveImport = 'Power.Reactive.Import',
  RPM = 'RPM',
  SoC = 'SoC',
  Temperature = 'Temperature',
  Voltage = 'Voltage',
}

export enum MeterValueTimeWindow {
  WindowLatestHour = '1h',
  WindowLatestDay = '1d',
  WindowLatestWeek = '7d',
  WindowLatest30Days = '30d',
  WindowLatest366Days = '366d',
}

export const getMeterValueSampleLength = (timeWindow: MeterValueTimeWindow) => {
  switch (timeWindow) {
    case MeterValueTimeWindow.WindowLatestDay:
      return 24;
    case MeterValueTimeWindow.WindowLatestWeek:
      return 7;
    case MeterValueTimeWindow.WindowLatest30Days:
      return 30;
    case MeterValueTimeWindow.WindowLatest366Days:
      return 12;
    default:
      return 0;
  }
};

export enum MeterValueFunction {
  AVG = 'AVG',
  MAX = 'MAX',
  MAX_MIN_DIFF = 'MAX_MIN_DIFF',
  // SUM = 'SUM',
}

export const MeterValue = types.model({
  charging_station_id: types.string,
  connector_id: types.number,
  transaction_id: types.string,
  time: types.maybeNull(types.string),
  measure_name: types.maybeNull(types.string),
  measure_value: types.maybeNull(types.string),
  context: types.maybeNull(types.string),
  format: types.maybeNull(types.string),
  phase: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
});

export const MeterValueSearchResult = types.compose(
  SearchResult,
  types.model({ items: types.array(MeterValue) }),
);

export interface MeterValue extends SnapshotIn<typeof MeterValue> {}
export interface MeterValueSearchResult
  extends SnapshotIn<typeof MeterValueSearchResult> {}

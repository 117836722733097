import { SnapshotIn, types } from 'mobx-state-tree';

export const NotificationSettings = types.model({
  charging_station_authorization: types.boolean,
  charging_station_group_authorization: types.boolean,
  organization_authorization: types.boolean,
  location_transfer: types.boolean,
  firmware_update_available: types.boolean,
  installation_instructions: types.boolean,
  charging_station_error: types.boolean,
  transaction_stopped: types.boolean,
});

export const UserNotificationSettings = types.model({
  emails: NotificationSettings,
  push_notifications: NotificationSettings,
});

export interface UserNotificationSettings
  extends SnapshotIn<typeof UserNotificationSettings> {}

export interface NotificationSettings
  extends SnapshotIn<typeof NotificationSettings> {}
